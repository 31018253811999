<template>
  <AppSplash :condition="waitingAuthUser">
    <template v-slot:app-content>
      <v-app>
        <!--<v-navigation-drawer app>
      <AppNavigationDrawer
        v-if="$route.name !== 'LoginView'"
      ></AppNavigationDrawer>
    </v-navigation-drawer>
    -->
        <!-- <AppDialog :dialog="$store.state.dialog" /> -->
        <AppSnackbar :snackbar="$store.state.snackbar" />
        <v-main>
          <transition name="scroll" mode="out-in">
            <span>
              <NewsDialog v-if="urgentNews.length > 0" :news="urgentNews" />
              <AppBar v-if="$route.name !== 'LoginView'" />
            </span>
          </transition>
          <transition :name="transitionName" mode="out-in">
            <NotFoundView v-if="$store.state.notFound" />
            <router-view v-else></router-view>
          </transition>
        </v-main>
      </v-app>
    </template>
  </AppSplash>
</template>

<script>
//import AppNavigationDrawer from '@/components/app/AppNavigationDrawer'
import AppSplash from '@/components/commons/AppSplash'
import AppBar from '@/components/app/AppBar'
import AppSnackbar from '@/components/commons/AppSnackbar'
import NotFoundView from '@/views/NotFoundView'
//import AppFooter from '@/components/app-footer/AppFooter'
import { Auth } from '@/firebase-exports'
import { mapActions, mapMutations } from 'vuex'
import NewsDialog from '@/components/news/visualization/NewsDialog'

export default {
  components: {
    AppBar,
    AppSnackbar,
    AppSplash,
    NotFoundView,
    NewsDialog,
  },
  async beforeCreate() {
    Auth.onAuthStateChanged(Auth.getAuth(), async (user) => {
      if (user) {
        try {
          this.userUnsubscribe = await this.fetchUser()
          //Esperamos a que tenga valor el state antes de continuar
          while (!this.$store.state.user) {
            await new Promise((resolve) => setTimeout(resolve, 100))
          }

          if (
            this.$route.name === 'LoginView' ||
            this.$route.fullPath === '/'
          ) {
            const finalRoute = this.$route.query.redirect || '/dashboard'
            this.$router.replace(finalRoute)
          }

          await this.getBilling()
          await this.getLegal()
        } catch {
          const message = this.$t('genericError')
          this.setSnackbar({ position: 'top', type: 'error', message })

          await new Promise((resolve) => setTimeout(resolve, 3000))
          this.logout()
        }
      } else {
        if (this.userUnsubscribe) this.userUnsubscribe()
        if (this.$route.name !== 'LoginView') {
          const currentPath = this.$route.fullPath
          const redirect = currentPath !== '/' ? currentPath : undefined
          this.$router.push({ name: 'LoginView', query: { redirect } })
        }
      }
      this.waitingAuthUser = false
    })
  },
  async created() {
    this.setTabTitle()
  },
  data() {
    return {
      transitionName: 'scroll',
      waitingAuthUser: true,
    }
  },
  computed: {
    shouldAnimateTransition() {
      return this.$route.path !== '/login'
    },
    urgentNews() {
      var context = this
      return this.$store.state.news.filter(function (el) {
        return (
          el.push && el.usersRead.indexOf(context.$store.state.user.id) == -1
        )
      })
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      const toOrder = to.meta.order
      const fromOrder = from.meta.order
      if (from.path == '/login') {
        this.transitionName = 'scroll'
      } else {
        if (toDepth != fromDepth) {
          this.transitionName = toDepth < fromDepth ? 'fade' : 'fade'
        } else {
          this.transitionName =
            toOrder < fromOrder ? 'slide-right' : 'slide-left'
        }
      }
    },
    waitingAuthUser: function () {
      this.setTabTitle()
    },
    '$i18n.locale': function () {
      this.setTabTitle()
    },
    '$route.fullPath': function () {
      this.setTabTitle()
    },
    '$store.state.notFound': function () {
      this.setTabTitle()
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setDialog']),
    ...mapActions(['logout', 'fetchUser', 'getBilling', 'getLegal']),
    userUnsubscribe() {},
    setTabTitle() {
      var tabTitle = this.$store.state.notFound
        ? this.$t('notFound')
        : this.$t(`tabTitle.${this.$route.meta.tabTitle}`)
      if (tabTitle && !this.waitingAuthUser) tabTitle += ' - ' + 'GoodOK'
      else tabTitle = 'GoodOK'
      document.title = tabTitle
    },
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.scroll-enter-active,
.scroll-leave-active {
  transition: transform 1s;
}

.scroll-enter {
  transform: translateY(100%);
}
.scroll-leave-to {
  transform: translateY(-100%);
}

.scroll-leave-active {
  position: absolute;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s;
}

.slide-right-enter {
  transform: translateX(-100%);
}
.slide-right-leave-to {
  transform: translateX(+100%);
}

.slide-right-leave-active {
  position: absolute;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s;
}

.slide-left-enter {
  transform: translateX(+100%);
}
.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-left-leave-active {
  position: absolute;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-enter-active {
  animation: fadeIn 0.5s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-leave-active {
  animation: fadeOut 0.5s;
}
</style>
