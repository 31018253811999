var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"elevate-on-scroll":"","scroll-target":"#scrolling-techniques-7","color":_vm.$vuetify.theme.dark ? '#121212' : 'white'}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[(!_vm.isMobile)?_c('v-col',{staticClass:"d-flex align-left justify-left d-none d-md-inline-block"},[(_vm.$vuetify.theme.dark)?_c('v-img',{staticClass:"mt-1",staticStyle:{"cursor":"pointer"},attrs:{"contain":"","src":require("@/assets/app-logo-dark.png"),"transition":"fade-transition","max-width":"150"},on:{"click":function($event){_vm.$store.state.user
            ? _vm.$router.push({ name: 'DashboardView' })
            : _vm.$router.push({ name: 'LoginView' })}}}):_c('v-img',{staticClass:"mt-1",staticStyle:{"cursor":"pointer"},attrs:{"contain":"","src":require("@/assets/app-logo.png"),"transition":"fade-transition","max-width":"150"},on:{"click":function($event){_vm.$store.state.user
            ? _vm.$router.push({ name: 'DashboardView' })
            : _vm.$router.push({ name: 'LoginView' })}}})],1):_vm._e(),(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"12","md":"8","lg":"6"}},[_c('transition',{attrs:{"name":"flip","mode":"out-in"}},[_c('v-row',{staticClass:"pa-2",class:{ dark: _vm.$vuetify.theme.dark, light: !_vm.$vuetify.theme.dark },attrs:{"justify":"center"}},[_c('h3',{staticClass:"text-thin",class:{
              highlight: _vm.$route.name == 'DashboardView',
              'primary--text':
                _vm.isHovered == 'DashboardView' && _vm.$route.name != 'Dashboard',
              'text-h5': !_vm.isMobile,
            },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'DashboardView' })},"mouseover":function($event){_vm.isHovered = 'DashboardView'},"mouseleave":function($event){_vm.isHovered = ''}}},[_vm._v(" "+_vm._s(_vm.$t('appBar.dashboard'))+" ")]),_c('v-spacer'),_c('h3',{staticClass:"text-thin",class:{
              highlight:
                _vm.$route.name == 'ClientsView' ||
                _vm.$route.path.split('/')[1] == 'clients',
              'primary--text':
                _vm.isHovered == 'ClientsView' && _vm.$route.name != 'ClientsView',
              'text-h5': !_vm.isMobile,
            },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'ClientsView' })},"mouseover":function($event){_vm.isHovered = 'ClientsView'},"mouseleave":function($event){_vm.isHovered = ''}}},[_vm._v(" "+_vm._s(_vm.$route.path.split('/').length > 2 ? _vm.$t('appBar.clients') + _vm.currentCompanyName : _vm.$t('appBar.clients'))+" ")]),(_vm.allowToPerformAction('createClient'))?_c('v-spacer'):_vm._e(),(_vm.allowToPerformAction('createClient'))?_c('h3',{staticClass:"text-thin",class:{
              highlight: _vm.$route.name == 'CreateClientView',
              'primary--text':
                _vm.isHovered == 'CreateClientView' &&
                _vm.$route.name != 'CreateClientView',
              'text-h5': !_vm.isMobile,
            },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateClientView' })},"mouseover":function($event){_vm.isHovered = 'CreateClientView'},"mouseleave":function($event){_vm.isHovered = ''}}},[_vm._v(" "+_vm._s(_vm.$t('appBar.createClient'))+" ")]):_vm._e(),(_vm.isMarketingUser)?_c('v-spacer'):_vm._e(),(_vm.isMarketingUser)?_c('h3',{staticClass:"text-thin",class:{
              highlight: _vm.$route.name == 'NewsView',
              'primary--text':
                _vm.isHovered == 'NewsView' && _vm.$route.name != 'NewsView',
              'text-h5': !_vm.isMobile,
            },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'NewsView' })},"mouseover":function($event){_vm.isHovered = 'NewsView'},"mouseleave":function($event){_vm.isHovered = ''}}},[_vm._v(" "+_vm._s(_vm.$t('appBar.news'))+" ")]):_vm._e()],1)],1)],1):_vm._e(),(_vm.isMobile)?_c('v-col',{staticClass:"text-right"},[_c('h2',[(_vm.$route.name != 'ClientDetailView')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tabTitle.' + _vm.$route.meta.tabTitle))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$route.path.split('/').length > 2 ? _vm.$t('appBar.clients') + _vm.currentCompanyName : _vm.$t('appBar.clients')))])])]):_vm._e(),(_vm.isMobile)?_c('v-spacer'):_vm._e(),_c('v-col',{staticClass:"text-right"},[_c('AppBarMenu')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }