<template>
  <v-card elevation="1" :class="{ selected: isSelected }">
    <v-container fluid>
      <v-row @click="toggleSelection" align="center" justify="space-between">
        <v-col cols="1">
          <v-icon color="primary" v-if="isSelected"> mdi-check-circle </v-icon>
          <v-icon id="notifications-service" v-else>mdi-circle-outline</v-icon>

          <v-icon x-large> mdi-email </v-icon>
        </v-col>
        <v-col cols="11">
          <h2>{{ $t('notifications') }}</h2>
          <p>{{ $t('notificationsDescription') }}</p>
        </v-col>
      </v-row>

      <v-expand-transition class="blue">
        <v-row v-if="isSelected" justify="center" align="center">
          <v-col cols="12" md="5" lg="5">
            <CuotaLicenses
              :fixedDiscount="fixedDiscount"
              :name="$t('numberOfCifs')"
              :label="$t('numberOfCifs')"
              :cuotaServicio="cuotaServicio"
              resultEventName="notificationsCuota"
              @notificationsCuota="notificationsCuotaEvent"
            />
          </v-col>

          <v-col cols="auto"> <v-icon x-large>mdi-plus</v-icon> </v-col>
          <v-col cols="12" md="5" lg="5">
            <PackagesLicenses
              :fixedDiscount="fixedDiscount"
              :name="$t('numberOfNotifications')"
              :label="$t('numberOfNotifications')"
              :prices="prices"
              :packagesList="packagesList"
              resultEventName="notificationsPackages"
              @notificationsPackages="notificationsPackagesEvent"
            />
          </v-col>
          <v-col cols="12">
            <h2 class="mt-4 mb-4">
              <span class="final-price-label">
                {{ $t('client.productNotificationsCard.finalPrice') }}
              </span>
              <span class="final-price-value">
                {{
                  $n(CIFsCuotaPrice + NotificationsPackagePrice, 'i18nAmount')
                }}
                €
              </span>
            </h2>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>
  </v-card>
</template>

<script>
import PackagesLicenses from '@/components/client/createClient/PackagesLicenses.vue'
import CuotaLicenses from '@/components/client/createClient/CuotaLicenses.vue'

export default {
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
    prices: {
      type: Array,
    },
    packagesList: {
      type: Array,
    },
    cuotaServicio: {
      type: Number,
    },
    fixedDiscount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PackagesLicenses,
    CuotaLicenses,
  },
  data() {
    return {
      selectedPlan: 0,
      CIFsCuotaPrice: 0,
      NotificationsPackagePrice: 0,
    }
  },

  methods: {
    toggleSelection() {
      this.$emit('selectProductNotificationsEvent')
    },
    selectPlan(data) {
      this.selectedPlan = data.id
    },

    notificationsPackagesEvent(newValue) {
      this.NotificationsPackagePrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('notificationsPackages', newValue)
    },
    notificationsCuotaEvent(newValue) {
      this.CIFsCuotaPrice = Number(newValue.price - newValue.discountAmount)
      newValue.packages = [parseInt(newValue?.amount ?? 0)]
      this.$emit('notificationsCuota', newValue)
    },
  },
}
</script>

<style scoped>
.selected {
  border: 1px solid var(--v-primary-base);
}
.v-expand-transition {
  transition: height 0.3s ease;
  overflow: hidden;
}
</style>
