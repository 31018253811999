<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="7">
          <v-card class="pa-6">
            <v-row class="mt-6" justify="center" align="center">
              <h4>{{ $t('basicPackageToPurchase') }}</h4>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="3">
                <v-text-field
                  disabled
                  :value="
                    this.$store.state.user.collectiveData.newClientNotifNumber
                  "
                  :label="$t('newClientNotifNumber')"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  disabled
                  :value="
                    this.$store.state.user.collectiveData.newClientCifsNumber
                  "
                  :label="$t('newClientCifsNumber')"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  disabled
                  :value="
                    this.$store.state.user.collectiveData
                      .newClientRegistrationPrice
                  "
                  :label="$t('basicPackagceToPurchasePrice')"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-6" justify="center">
              <h4>{{ $t('additionalPackage') }}</h4>
            </v-row>
            <v-row justify="center">
              <v-col cols="3">
                <v-text-field
                  v-model="notificationsNumber"
                  :label="$t('numberNotificationstoHire')"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="additionalCifsNumber"
                  :label="$t('numberCIFtoHire')"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  disabled
                  :value="
                    this.$store.state.user.collectiveData.addCifNotifNumber
                  "
                  :label="$t('addCifNotifNumber')"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-6" justify="center">
              <h3>{{ $t('purchaseResume') }}</h3>
            </v-row>
            <v-row class="pl-8 pr-8" align="end">
              <v-col>
                {{ $t('bigReceiverCIF') }}
                <h3 class="grey--text">1</h3>
              </v-col>
              <v-col>
                {{ $t('SSNIF') }}
                <h3 class="grey--text">1</h3>
              </v-col>
              <v-col>
                {{ $t('users') }}
                <h3 class="grey--text">1</h3>
              </v-col>
              <v-col cols="3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ $t('notifications') }}
                      <h3>
                        {{
                          $store.state.user.collectiveData
                            .newClientNotifNumber + totalNotifications
                        }}
                      </h3></span
                    >
                  </template>
                  <span>
                    {{ $t('newClientNotifNumber') }} ({{
                      $store.state.user.collectiveData.newClientNotifNumber
                    }}) + {{ $t('numberNotificationstoHire') }} ({{
                      notificationsNumber
                    }}) +
                    {{ $t('addCifNotifNumber') }}
                    ({{ $store.state.user.collectiveData.addCifNotifNumber }}) *
                    {{ $t('numberCIFtoHire') }} ({{ additionalCifsNumber }})
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                {{ $t('cifs') }}
                <h3>
                  {{
                    $store.state.user.collectiveData.newClientCifsNumber +
                    totalCifs
                  }}
                </h3>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="5">
                <h4>{{ $t('currentMotnhFacturation') }}</h4>
                {{ $t('numberNotificationstoHire') }}
                {{ notificationsCurrentAmount }} /
                <b>{{ notificationsCurrentBill }}€</b> <br />
                {{ $t('numberCIFtoHire') }}
                {{ cifsCurrentAmount }} / <b>{{ cifsCurrentBill }}€</b> <br />
                {{ $t('clientPurchases') }}

                {{ clientCreationsCurrentMonth }} /
                <b>
                  {{
                    clientCreationsCurrentMonth *
                    $store.state.user.collectiveData.newClientRegistrationPrice
                  }}€
                </b>
              </v-col>
              <v-col cols="auto"> <v-icon> mdi-arrow-right </v-icon></v-col>
              <v-col cols="5" class="primary--text">
                <h4>{{ $t('newMonthFacturation') }}</h4>
                <span>
                  {{ $t('numberNotificationstoHire') }}

                  {{
                    parseInt(notificationsCurrentAmount) +
                    parseInt(totalNotifications)
                  }}
                  /
                  <b>{{ newNotificationsBill }} €</b>
                </span>
                <br />
                <span
                  >{{ $t('numberCIFtoHire') }}
                  {{ parseInt(cifsCurrentAmount) + parseInt(totalCifs) }}
                  /
                  <b>{{ newCifsBill }} €</b>
                </span>
                <br />
                {{ $t('clientPurchases') }}

                {{ parseInt(clientCreationsCurrentMonth) + 1 }} /
                <b>
                  {{
                    (parseInt(clientCreationsCurrentMonth) + 1) *
                    parseInt(
                      $store.state.user.collectiveData
                        .newClientRegistrationPrice
                    )
                  }}€
                </b>
              </v-col>
            </v-row>
            <v-row justify="center">
              <h3>
                {{ $t('finalPriceMonth') }} <br />
                <h2>
                  <b
                    >{{
                      parseInt(newNotificationsBill) +
                      parseInt(newCifsBill) +
                      parseInt(
                        (parseInt(clientCreationsCurrentMonth) + 1) *
                          $store.state.user.collectiveData
                            .newClientRegistrationPrice
                      )
                    }}
                    €</b
                  >
                </h2>
              </h3>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-btn
                  v-if="!confirmLoading"
                  text
                  class="primary secondary--text"
                  @click="createClient"
                >
                  {{ $t('client.createClient.createClient') }}</v-btn
                >
                <v-progress-linear v-else indeterminate color="primary">
                </v-progress-linear>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="5"
          ><v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="cliente.empresa"
                :label="$t('client.createClient.companyName')"
                prepend-icon="mdi-account-circle"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="empresa-input"
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-select
                v-model="cliente.tipoDocIdentidad"
                :items="docTypes"
                :label="$t('client.createClient.idNumberType')"
                prepend-icon="mdi-card-account-details"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="tipo-doc-select"
              >
              </v-select>
            </v-col>
            <v-col cols="8" md="8">
              <v-text-field
                v-model="cliente.numeroDocIdentidad"
                @input="cliente.numeroDocIdentidad = $event.toUpperCase()"
                :label="$t('client.createClient.idNumber')"
                prepend-icon="mdi-id-card"
                :rules="[
                  notEmpty,
                  idNumber(
                    cliente.tipoDocIdentidad,
                    cliente.numeroDocIdentidad
                  ),
                ]"
                :disabled="confirmLoading"
                id="doc-identidad-input"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                v-model="cliente.nombre"
                :label="$t('client.createClient.name')"
                prepend-icon="mdi-account"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="name-input"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="8">
              <v-text-field
                v-model="cliente.apellidos"
                :label="$t('client.createClient.surname')"
                prepend-icon="mdi-account"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="surname-input"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="cliente.email"
                :label="$t('client.createClient.email')"
                type="email"
                prepend-icon="mdi-email"
                :rules="emailRules"
                :disabled="confirmLoading"
                id="email-input"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="cliente.telefono"
                :label="$t('client.createClient.phone')"
                :hint="$t('hintPhone')"
                prepend-icon="mdi-cellphone-basic"
                :rules="[phoneNumber]"
                autocomplete="false"
                @keydown.space.prevent
                :disabled="confirmLoading"
                id="phone-input"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import rules from '@/utils/rules'
import { allowToPerformAction } from '@/services/users-service'
import { toLocaleISOString } from '@/utils/date-utils'
import {
  createEmpresaFirestore,
  createEmpresaDMS,
} from '@/services/clients-service'
import getErrorText from '@/utils/get-error-text'
import { mapMutations } from 'vuex'
import {
  calculateRangePrices,
  notificationsHistoryAmount,
  cifsCurrentAmount,
  getCollectivePrices,
} from '@/utils/prices-utils'

export default {
  data() {
    return {
      emailRules: [rules.required, rules.email],
      docTypes: ['DNI', 'NIE', 'CIF', 'NIF-M'],
      confirmLoading: false,
      notificationsNumber: 0,
      additionalCifsNumber: 0,
      collectivePrices: getCollectivePrices(),
      //getCollectivePrices(),
      cliente: {
        tipoEmpresa: 'ASESORIACOLECTIVO',
        empresa: '',
        nombre: '',
        apellidos: '',
        email: '',
        telefono: '',
        tipoDocIdentidad: '',
        numeroDocIdentidad: '',
      },
    }
  },
  computed: {
    //cada cif incluye 20 notificaciones, se suman a las notif adicionales que quiera contratar
    totalNotifications() {
      return (
        parseInt(this.notificationsNumber) +
        parseInt(
          this.additionalCifsNumber *
            this.$store.state.user.collectiveData.addCifNotifNumber
        )
      )
    },
    totalCifs() {
      return parseInt(this.additionalCifsNumber)
    },
    clientCreationsCurrentMonth() {
      const currentMonth = new Date().getMonth()
      const currentYear = new Date().getFullYear()

      return this.$store.state.history.filter((item) => {
        const itemDate = item.createdAt.toDate()
        return (
          itemDate.getMonth() === currentMonth &&
          itemDate.getFullYear() === currentYear &&
          item.actionType == 'clientCreate'
        )
      }).length
    },
    notificationsCurrentAmount() {
      const currentMonth = new Date().getMonth()
      const currentYear = new Date().getFullYear()

      var totalNotifications = notificationsHistoryAmount(
        currentMonth,
        currentYear,
        this.$store.state.history
      )

      return parseInt(
        totalNotifications -
          parseInt(
            this.clientCreationsCurrentMonth *
              this.$store.state.user.collectiveData.newClientNotifNumber
          )
      )
    },
    notificationsCurrentBill() {
      this.notificationsCurrentAmount

      return calculateRangePrices(
        this.notificationsCurrentAmount,
        this.collectivePrices.notificationPrices,
        this.collectivePrices.notificationGaps
      )
    },
    newNotificationsBill() {
      let notificationsTotal =
        parseInt(this.notificationsCurrentAmount) +
        parseInt(this.totalNotifications)

      return calculateRangePrices(
        notificationsTotal,
        this.collectivePrices.notificationPrices,
        this.collectivePrices.notificationGaps
      )
    },
    cifsCurrentAmount() {
      const currentMonth = new Date().getMonth()
      const currentYear = new Date().getFullYear()

      const totalCifs = cifsCurrentAmount(
        currentMonth,
        currentYear,
        this.$store.state.history
      )
      return parseInt(
        totalCifs -
          parseInt(
            this.clientCreationsCurrentMonth *
              this.$store.state.user.collectiveData.newClientCifsNumber
          )
      )
    },
    cifsCurrentBill() {
      return calculateRangePrices(
        this.cifsCurrentAmount,
        this.collectivePrices.cifPrices,
        this.collectivePrices.cifGaps
      )
    },
    newCifsBill() {
      let totalNumberCifsCurrentMonth =
        parseInt(this.cifsCurrentAmount) + parseInt(this.totalCifs)

      return calculateRangePrices(
        totalNumberCifsCurrentMonth,
        this.collectivePrices.cifPrices,
        this.collectivePrices.cifGaps
      )
    },
  },
  methods: {
    ...rules,
    ...mapMutations(['setSnackbar', 'setLoading', 'setNotFound']),

    async createClient() {
      // Comprueba si el usuario tiene permisos para realizar la accion
      if (!allowToPerformAction(this.$store.state.user.rol, 'createClient')) {
        const message = this.$t('insuficientPermissions')
        this.setSnackbar({ position: 'top', type: 'error', message })
        return
      }
      if (!this.$refs.form?.validate()) return
      this.setLoading(true)

      try {
        this.confirmLoading = true

        const dataCreate = {
          tipoEmpresa: 'ASESORIACOLECTIVO',
          empresa: this.cliente.empresa,
          tipoDocIdentidad: this.cliente.tipoDocIdentidad,
          numeroDocIdentidad: this.cliente.numeroDocIdentidad,
          nombre: this.cliente.nombre,
          apellidos: this.cliente.apellidos,
          email: this.cliente.email,
          telefono: this.cliente.telefono,
          notificacionesActivas: true,
          firmasActivas: false,
          channelId:
            this.$store.state.user.parentRef?.id || this.$store.state.user.id,
          allowed2FA: false,
          allowedCustomBrand: true, //siempre activo para que cuando su creador ponga marca personalizada se le aplique a todos sus hijos
        }
        if (this.$store.state.user.customBrand) {
          dataCreate.customBrand = this.$store.state.user.customBrand
        }
        //En caso de que el creador tenga redmine project (caso canal mayorista) sus clientes deben "heredar" ese proyecto para las futuras incidencias, que seran manejadas por su canal creador. Al no ser algo potencialmente modificable se graba en la creacion a pelo
        if (
          this.$store.state.user.redmineProject &&
          this.$store.state.user.redmineKey
        ) {
          dataCreate.redmineProject = this.$store.state.user.redmineProject
          dataCreate.redmineKey = this.$store.state.user.redmineKey
        }

        dataCreate.planBilling = {}

        // Creamos el Objeto de Objetos dataCreate.planBilling
        dataCreate.planBilling = {
          goodOkCuota: {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          },
          notificationsCuota: {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          },
          notificationsPackages: {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          },
          signaturesCertsPackages: {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          },
          signaturesPackages: {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          },
          securityPackage: {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          },
          customBrandPackage: {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          },
        }

        // Añadimos las licencias de cara a las llamadas al DMS
        dataCreate.planBilling.licencia = new Date(
          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            .toISOString()
            .substring(0, 10) +
            'T' +
            toLocaleISOString().slice(11, 19)
        )

        dataCreate.planBilling.licencia_notificaciones = {
          licensed:
            parseInt(
              this.$store.state.user.collectiveData.newClientNotifNumber
            ) + parseInt(this.totalNotifications),
          clients:
            parseInt(
              this.$store.state.user.collectiveData.newClientCifsNumber
            ) + parseInt(this.totalCifs),
        }

        // Indicamos que es usuario de tipo COLECTIVO
        dataCreate.isCollectiveUser = true

        // Llamamos a la funcion de crear EMPRESA
        const userCreated = await createEmpresaFirestore(dataCreate)
        await createEmpresaDMS({
          userId: userCreated.data.uid,
        })

        // Redirigimos al listado de clientes
        this.$router.replace('/clients/' + userCreated.data.uid)

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('client.createClient.createSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.loading = false
      } catch (error) {
        this.confirmLoading = false
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
        this.setLoading(false)
      }
    },
  },
  watch: {
    // Formateo de las variable notificationsNumber
    notificationsNumber: function (newValue) {
      if (newValue === '') this.notificationsNumber = 0
    },
    // Formateo de las variable additionalCifsNumber
    additionalCifsNumber: function (newValue) {
      if (newValue === '') this.additionalCifsNumber = 0
    },
  },
}
</script>

<style lang="scss" scoped></style>
