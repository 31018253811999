import {
  Firestore,
  UsersCollection,
  UsersDoc,
  CIFsCollection,
  UserOperatorsQuery,
  CreateEmpresaFirestore,
  CreateEmpresaDMS,
  InvoicesDoc,
  InvoicesCollection,
  UsersManagementCollection,
  UsersManagementDoc,
  UsersUpdateAdmin,
  UsersUpdateEmpresa,
  UserDisable,
  UsersCompleteDeletion,
  UsersUpdateMultifactor,
  UsersUpdateServices,
  GetDocFromReference,
} from '@/firebase-exports'

import store from '@/store/index'
import { getUsersDict } from '@/services/users-service'

/**
 * Crea una empresa en Firestore.
 *
 * @param {Object} data - Los datos de la empresa a crear.
 * @returns {Promise} - Una promesa que se resuelve con el resultado de la creación de la empresa.
 */
export async function createEmpresaFirestore(data) {
  return await CreateEmpresaFirestore({
    ...data,
  })
}

/**
 * Deshabilita o habilita un usuario en el sistema.
 *
 * @param {string} userId - El ID del usuario que se desea deshabilitar o habilitar.
 * @param {boolean} deactivation - Indica si se debe deshabilitar (true) o habilitar (false) el usuario.
 * @returns {Promise<void>} - Una promesa que se resuelve cuando el estado del usuario ha sido actualizado exitosamente.
 */
export async function userDisable(userId, deactivation) {
  return await UserDisable({
    userId: userId,
    disabled: deactivation,
  })
}

/**
 * Crea una empresa en DMS.
 *
 * @param {Object} data - Los datos de la empresa a crear.
 * @returns {Promise} - Una promesa que se resuelve con el resultado de la creación de la empresa.
 */
export async function createEmpresaDMS(data) {
  return await CreateEmpresaDMS({
    ...data,
  })
}

/**
 * Actualiza el campo "empresa" para un usuario específico en la base de datos.
 *
 * @param {string} userId - El ID del usuario cuyo campo "empresa" se actualizará.
 * @param {Object} data - Información para actualizar el campo "empresa".
 * @returns {Promise<any>} - Una promesa que se resuelve con el resultado de la actualización.
 */
export async function updateEmpresa(userId, data) {
  return await UsersUpdateEmpresa({
    userId: userId,
    ...data,
  })
}

/**
 * Actualiza la configuración de multifactor para un usuario específico.
 *
 * @param {string} userId - El ID del usuario cuyos ajustes de multifactor están siendo actualizados.
 * @param {Object} new2FA - Nueva configuración de multifactor.
 * @returns {Promise} - Una promesa que se resuelve cuando la configuración de multifactor es actualizada.
 */
export async function updateMultifactor(userId, new2FA) {
  return await UsersUpdateMultifactor({
    userId,
    new2FA,
  })
}

/**
 * Actualiza los servicios para un usuario específico.
 *
 * @param {string} userId - El ID del usuario cuyos servicios están siendo actualizados.
 * @param {object} data - Datos que contienen la información actualizada de los servicios.
 * @returns {Promise} - Una promesa que se resuelve cuando los servicios son actualizados.
 */
export async function updateServices(userId, data) {
  return await UsersUpdateServices({
    userId: userId,
    ...data,
  })
}

/**
 * Actualiza los datos de un cliente en Firestore.
 *
 * @param {string} userId - El ID del usuario a actualizar.
 * @param {Object} data - Datos actualizados para el usuario.
 * @returns {Promise<Object>} - Los datos actualizados del usuario.
 */
export async function updateClient(userId, data) {
  return await Firestore.updateDoc(UsersDoc(userId), {
    ...data,
  })
}

/**
 * Actualiza los datos de un usuario con permisos de administrador.
 *
 * @param {string} userId - El ID del usuario que se va a actualizar.
 * @param {Object} data - Datos actualizados del usuario.
 * @returns {Promise} - Una promesa que se resuelve cuando la actualización se ha completado.
 */
export async function updateAdmin(userId, data) {
  return await UsersUpdateAdmin({
    userId: userId,
    ...data,
  })
}
/**
 * Elimina un usuario y todos los datos asociados.
 *
 * @param {string} userId - El ID del usuario que se eliminará por completo.
 * @returns {Promise<void>} - Una promesa que se resuelve cuando el usuario y los datos asociados se eliminan con éxito.
 */
export async function userCompleteDeletion(userId) {
  return await UsersCompleteDeletion({
    userId: userId,
  })
}

/**
 * Suscribe a los clientes en Firestore y guarda los datos en el store.
 * @returns {Function} - Función de cancelación de la suscripción.
 */
export async function clientsSubscription() {
  const users = store.getters.isAddaliaUser ? await getUsersDict() : {}

  const unsub = Firestore.onSnapshot(
    store.getters.isAddaliaUser
      ? Firestore.query(
          UsersCollection,
          Firestore.where('tipo', '==', 'GESTOR')
        )
      : Firestore.query(
          UsersCollection,
          Firestore.or(
            Firestore.where(
              'channelRef',
              '==',
              UsersManagementDoc(store.state.user.id)
            ),
            Firestore.where(
              'commercialRef',
              '==',
              UsersManagementDoc(store.state.user.id)
            )
          )
        ),
    async (querySnapshot) => {
      let clients = (
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const document = doc.data()

            delete document.password
            delete document.passwordEmpresa

            if (document.createdAt)
              document.createdAt = document.createdAt.toDate()

            if (document.commercialRef) {
              document.commercial =
                users[document.commercialRef.id] ??
                (await GetDocFromReference(document.commercialRef))
              if (!users[document.commercialRef.id])
                users[document.commercialRef.id] = document.commercial
            }

            if (document.channelRef) {
              document.channel =
                users[document.channelRef.id] ??
                (await GetDocFromReference(document.channelRef))
              if (!users[document.channelRef.id])
                users[document.channelRef.id] = document.channel
            }

            return {
              id: doc.id,
              ...document,
            }
          })
        )
      ).sort((a, b) => a.empresa.localeCompare(b.empresa))

      // Guardamos en el store
      store.commit('setClients', clients)
      store.commit('setClientsUnsubscribe', unsub)
    }
  )

  return unsub
}

/**
 * Determina el estado de un cliente y devuelve un objeto con el color y el texto del estado.
 * Modifica el campo 'estado' en el objeto cliente para filtrar en la interfaz de usuario.
 *
 * @param {Object} client - El objeto cliente.
 * @returns {Object} - Un objeto que contiene el color del estado y la clave de internacionalización para el texto del estado.
 */
export function getClientStatus(client) {
  if (client.userActivated && client.disabled) {
    client.estado = 'Deshabilitado'
    return {
      color: 'black',
      status: 'clientStatus.disabled',
    }
  } else if (client.estado == 'pendienteFirma') {
    return {
      color: 'grey',
      status: 'clientStatus.pendienteFirma',
    }
  } else if (client.estado == 'pendienteComercial') {
    return {
      color: 'blue-grey',
      status: 'clientStatus.pendienteComercial',
    }
  } else if (client.estado == 'pendienteFacturacion') {
    return {
      color: 'black',
      status: 'clientStatus.pendienteFacturacion',
    }
  } else if (client.estado == 'pendienteActivacion') {
    return {
      color: 'blue',
      status: 'clientStatus.pendienteActivacion',
    }
  } else if (client.estado == 'activo') {
    return {
      color: 'green',
      status: 'clientStatus.activo',
    }
  } else {
    client.estado = 'Indefinido'
    return {
      color: 'orange',
      status: 'clientStatus.indefinido',
    }
  }
}

/**
 * Guarda una marca personalizada para un cliente en Firestore.
 *
 * @param {string} clientId - El ID del cliente.
 * @param {string|null} customBrand - La marca personalizada a guardar. Si es null, se elimina la marca personalizada.
 * @returns {Promise} - Una promesa que se resuelve cuando se completa la operación.
 */
export async function saveCustomBrand(clientId, customBrand) {
  return await Firestore.updateDoc(UsersDoc(clientId), {
    customBrand: customBrand ? customBrand : Firestore.deleteField(),
  })
}

/**
 * Crea una nueva factura en Firestore para un usuario especificado.
 *
 * @param {string} userId - El ID del usuario al que se añadirá la factura.
 * @param {Object} data - Los datos para la nueva factura.
 * @returns {Promise<Object>} - Una promesa que se resuelve con los datos de la nueva factura.
 */
export async function createInvoice(userId, data) {
  data.date = Firestore.serverTimestamp()

  return await Firestore.addDoc(InvoicesCollection(userId), {
    ...data,
    date: Firestore.serverTimestamp(),
  })
}

/**
 * Actualiza una factura existente en Firestore.
 *
 * @param {string} userId - El ID del usuario cuya factura se está actualizando.
 * @param {string} invoiceId - El ID de la factura a actualizar.
 * @param {Object} data - Los datos actualizados para la factura.
 * @returns {Promise<Object>} - Una promesa que se resuelve con los datos actualizados de la factura.
 */
export async function updateInvoice(userId, invoiceId, data) {
  var response = await Firestore.updateDoc(InvoicesDoc(userId, invoiceId), {
    ...data,
  })
  return response
}

/**
 * Elimina una factura de Firestore.
 *
 * @param {string} userId - El ID del usuario cuya factura se está eliminando.
 * @param {string} invoiceId - El ID de la factura a eliminar.
 * @returns {Promise<Object>} - Una promesa que se resuelve cuando se elimina la factura.
 */
export async function deleteInvoice(userId, invoiceId) {
  return await Firestore.deleteDoc(InvoicesDoc(userId, invoiceId))
}

/**
 * Recupera información sobre un usuario basado en su ID.
 *
 * @param {string} userId - El ID del usuario para recuperar información.
 * @param {boolean} [includeCifs=false] - Si se incluyen CIFs en los datos devueltos.
 * @param {boolean} [includeInvoices=false] - Si se incluyen facturas en los datos devueltos.
 * @returns {Object|undefined} - Un objeto con la información del usuario, o undefined si el usuario no existe.
 */
export async function getUserInfo(
  userId,
  includeCifs = false,
  includeInvoices = false
) {
  const docSnap = await Firestore.getDoc(UsersDoc(userId))
  if (!docSnap.exists() || !docSnap.data()) return // Verificar si docSnap es válido antes de acceder a sus propiedades.

  const document = docSnap.data()

  // Load cifs and client data only if specified by flag (to save resources if not needed)
  let cifs = []
  let invoices = []

  if (includeCifs) {
    const cifsDocsSnap = await Firestore.getDocs(
      Firestore.query(CIFsCollection(userId))
    )

    cifs = await Promise.all(
      cifsDocsSnap.docs.map(async (doc) => {
        const document = doc.data()

        // Delete some back variables that could be unsafe to store in front
        delete document.password
        delete document.passwordEmpresa

        return { id: doc.id, ...document }
      })
    )
  }

  if (includeInvoices) {
    const invoicesDocsSnap = await Firestore.getDocs(
      Firestore.query(
        InvoicesCollection(userId),
        Firestore.orderBy('date', 'desc')
      )
    )

    invoices = await Promise.all(
      invoicesDocsSnap.docs.map(async (doc) => {
        const document = doc.data()

        if (document.date) document.date = document.date.toDate()

        return { id: doc.id, ...document }
      })
    )
  }

  // Delete some back variables that could be unsafe to store in front
  delete document.password
  delete document.passwordEmpresa

  let client = { id: docSnap.id, ...document, cifs, invoices }

  if (client.planBilling) {
    delete client.planBilling.licencia
    delete client.planBilling.licencia_notificaciones
    delete client.planBilling.licencia_firmas

    // Genera array del objeto planBilling ordenado de la forma establecida
    const expectedArrayOrder = [
      'goodOkCuota',
      'notificationsCuota',
      'notificationsPackages',
      'signaturesCertsPackages',
      'signaturesPackages',
      'multiFactor',
      'customBrand',
    ]

    client.planBillingArray = Object.entries(client.planBilling)
      .map(([concept, value]) => ({ concept, ...value }))
      .sort(
        (a, b) =>
          expectedArrayOrder.indexOf(a.concept) -
          expectedArrayOrder.indexOf(b.concept)
      )
  }

  return client
}

/**
 * Se suscribe a los cambios en la información de un usuario en Firestore y actualiza el objeto de contexto proporcionado.
 *
 * @param {string} userId - El ID del usuario al que suscribirse.
 * @param {Object} context - El objeto de contexto donde se almacenará la información del usuario.
 * @returns {Function} - Una función para darse de baja de las actualizaciones de Firestore.
 */
export async function getUserInfoSubscription(userId, context) {
  // Comprobamos que el usuario existe en la base de datos
  const docSnap = await Firestore.getDoc(UsersDoc(userId))
  // Verificar si docSnap es válido antes de acceder a sus propiedades.
  if (!docSnap.exists() || !docSnap.data()) return

  // Establece una suscripción a los cambios en el documento del usuario identificado por userId en Firestore.
  var userUnsubscribe = Firestore.onSnapshot(
    UsersDoc(userId),
    async (docSnap) => {
      if (!docSnap.exists() || !docSnap.data()) return // Verificar si docSnap es válido antes de acceder a sus propiedades.

      // Obtiene los datos del documento del usuario.
      const document = docSnap.data()

      // Delete some back variables that could be unsafe to store in front
      delete document.password
      delete document.passwordEmpresa

      if (document.createdAt) document.createdAt = document.createdAt.toDate()

      if (document.commercialRef)
        document.commercial = await GetDocFromReference(document.commercialRef)

      if (document.channelRef)
        document.channel = await GetDocFromReference(document.channelRef)

      // Eliminamos campos auxiliares y se actualiza el objeto de contexto con la información del usuario obtenida
      let client = { id: docSnap.id, ...document }

      if (client.planBilling) {
        delete client.planBilling.licencia
        delete client.planBilling.licencia_notificaciones
        delete client.planBilling.licencia_firmas

        // Genera array del objeto planBilling ordenado de la forma establecida
        const expectedArrayOrder = [
          'goodOkCuota',
          'notificationsCuota',
          'notificationsPackages',
          'signaturesCertsPackages',
          'signaturesPackages',
          'multiFactor',
          'customBrand',
        ]

        client.planBillingArray = Object.entries(client.planBilling)
          .map(([concept, value]) => ({ concept, ...value }))
          .sort(
            (a, b) =>
              expectedArrayOrder.indexOf(a.concept) -
              expectedArrayOrder.indexOf(b.concept)
          )
      }

      context.client = client
    }
  )

  return userUnsubscribe
}

/**
 * Obtiene los posibles comerciales disponibles para un cliente. La función recopila comerciales
 * y canales actuales del cliente, y luego busca otros comerciales relacionados en Firestore.
 *
 * @param {object} client - Objeto que representa al cliente y contiene propiedades como commercial y channel.
 * @returns {Promise<Array>} - Promesa que resuelve en un arreglo de objetos, cada uno representando un comercial.
 */
export async function getPossibleCommercials(client) {
  let commercials = {}

  // Add current commercial and current channel
  if (client.commercial) commercials[client.commercial.id] = client.commercial
  if (client.channel) commercials[client.channel.id] = client.channel
  // Add me as current commercial
  commercials[store.state.user.id] = store.state.user

  // Search for all commercial under my profile
  const snapshot = await Firestore.getDocs(
    store.getters.isAddaliaUser
      ? Firestore.query(
          UsersManagementCollection,
          Firestore.and(
            Firestore.where('type', '==', 'addalia'),
            Firestore.or(
              Firestore.where('rol', '==', 'admin'),
              Firestore.where('rol', '==', 'commercial')
            )
          )
        )
      : Firestore.query(
          UsersManagementCollection,
          Firestore.where(
            'parentRef',
            '==',
            UsersManagementDoc(store.state.user.id)
          )
        )
  )

  snapshot.docs.forEach((doc) => {
    const data = { id: doc.id, ...doc.data() }
    commercials[data.id] = data
  })

  return Object.values(commercials)
}

/**
 * Actualiza el comercial asociado a un usuario en Firestore. Si se proporciona un managerId, se establece
 * ese ID como referencia comercial; de lo contrario, se elimina el campo comercialRef.
 *
 * @param {string} userId - El ID del usuario cuyo comercial se va a actualizar.
 * @param {string} managerId - El ID del comercial (manager) a asignar. Si es null, se elimina el comercial actual.
 * @returns {Promise<void>} - Promesa que indica la finalización de la operación.
 */
export async function updateCommercial(userId, managerId) {
  updateClient(userId, {
    commercialRef: managerId
      ? UsersManagementDoc(managerId)
      : Firestore.deleteField(),
  })
}

/**
 * Obtiene los posibles canales disponibles para un cliente. Incluye el canal actual del cliente y busca
 * otros canales en Firestore si el usuario es un administrador de Addalia.
 *
 * @param {object} client - Objeto que representa al cliente y contiene la propiedad channel.
 * @returns {Promise<Array>} - Promesa que resuelve en un arreglo de objetos, cada uno representando un canal.
 */
export async function getPossibleChannels(client) {
  let channels = {}

  // Add current channel the first one
  if (client.channel) channels[client.channel.id] = client.channel

  // Only make query to get channel list if
  if (store.getters.isAddaliaAdminUser) {
    // Search for all channels on userlist
    const snapshot = await Firestore.getDocs(
      Firestore.query(
        UsersManagementCollection,
        Firestore.orderBy('addaliaRef')
      )
    )

    snapshot.docs.forEach((doc) => {
      const data = { id: doc.id, ...doc.data() }
      channels[data.id] = data
    })
  }

  return Object.values(channels)
}

/**
 * Actualiza el canal asociado a un usuario en Firestore. Si se proporciona un channelId, se establece
 * ese ID como referencia de canal; de lo contrario, se elimina el campo channelRef.
 *
 * @param {string} userId - El ID del usuario cuyo canal se va a actualizar.
 * @param {string} channelId - El ID del canal a asignar. Si es null, se elimina el canal actual.
 * @returns {Promise<void>} - Promesa que indica la finalización de la operación.
 */
export async function updateChannel(userId, channelId) {
  updateClient(userId, {
    channelRef: channelId
      ? UsersManagementDoc(channelId)
      : Firestore.deleteField(),
  })
}

/**
 * Obtiene una suscripción a los cambios en las facturas de un usuario desde Firestore y actualiza el contexto
 * con la nueva información de facturas. Devuelve una función de cancelación de suscripción.
 *
 * @param {string} userId - El ID del usuario para el cual se obtienen las facturas.
 * @param {object} context - Objeto de contexto que se actualizará con la información de las facturas.
 * @returns {Promise<function>} - Una función para cancelar la suscripción a los cambios de las facturas.
 */
export async function getInvoicesSubscription(userId, context) {
  const query = Firestore.query(InvoicesCollection(userId))

  const unsubscribe = Firestore.onSnapshot(
    query,
    (snapShot) => {
      if (snapShot.empty) context.invoices = []

      const invoices = snapShot.docs
        .map((doc) => {
          const document = doc.data()

          if (document.date) document.date = document.date.toDate()

          return { id: doc.id, ...document }
        })
        .sort((a, b) => b.date - a.date)

      context.invoices = invoices
    },
    (error) => {
      throw error
    }
  )

  return unsubscribe
}

/**
 * Obtiene la información de los operadores asociados a un usuario desde Firestore.
 *
 * @param {string} userId - El ID del usuario para el cual se buscan los operadores.
 * @returns {Promise<Array>} - Promesa que resuelve en un array de objetos, cada uno representando un operador.
 */
export async function getOperators(userId) {
  const docsSnap = await Firestore.getDocs(UserOperatorsQuery(userId))
  return docsSnap.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
}

/**
 * Obtiene una suscripción a los cambios en las empresas (CIFs) de un usuario desde Firestore y actualiza el
 * contexto con la nueva información de las empresas. Devuelve una función de cancelación de suscripción.
 *
 * @param {string} userId - El ID del usuario para el cual se obtienen las empresas (CIFs).
 * @param {object} context - Objeto de contexto que se actualizará con la información de las empresas.
 * @returns {Promise<function>} - Una función para cancelar la suscripción a los cambios de las empresas.
 */
export async function getCifsSubscription(userId, context) {
  const query = Firestore.query(CIFsCollection(userId))

  const unsubscribe = Firestore.onSnapshot(
    query,
    (snapShot) => {
      if (snapShot.empty) context.cifs = []

      const cifs = snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))

      context.cifs = cifs
    },
    (error) => {
      throw error
    }
  )

  return unsubscribe
}

export async function getClientWithRef(ref) {
  const docSnap = await GetDocFromReference(ref)
  if (!docSnap.exists()) return
  const document = docSnap.data()
  return { id: docSnap.id, ...document }
}
