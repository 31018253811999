<template>
  <v-app-bar
    elevate-on-scroll
    scroll-target="#scrolling-techniques-7"
    :color="$vuetify.theme.dark ? '#121212' : 'white'"
  >
    <v-row justify="space-between" align="center">
      <v-col
        v-if="!isMobile"
        class="d-flex align-left justify-left d-none d-md-inline-block"
      >
        <v-img
          v-if="$vuetify.theme.dark"
          class="mt-1"
          contain
          src="@/assets/app-logo-dark.png"
          transition="fade-transition"
          max-width="150"
          style="cursor: pointer"
          @click="
            $store.state.user
              ? $router.push({ name: 'DashboardView' })
              : $router.push({ name: 'LoginView' })
          "
        />
        <v-img
          v-else
          class="mt-1"
          contain
          src="@/assets/app-logo.png"
          transition="fade-transition"
          max-width="150"
          style="cursor: pointer"
          @click="
            $store.state.user
              ? $router.push({ name: 'DashboardView' })
              : $router.push({ name: 'LoginView' })
          "
        />
      </v-col>

      <v-col v-if="!isMobile" cols="12" md="8" lg="6">
        <transition name="flip" mode="out-in">
          <v-row
            :class="{ dark: $vuetify.theme.dark, light: !$vuetify.theme.dark }"
            justify="center"
            class="pa-2"
          >
            <h3
              @click="$router.push({ name: 'DashboardView' })"
              style="cursor: pointer"
              class="text-thin"
              :class="{
                highlight: $route.name == 'DashboardView',
                'primary--text':
                  isHovered == 'DashboardView' && $route.name != 'Dashboard',
                'text-h5': !isMobile,
              }"
              @mouseover="isHovered = 'DashboardView'"
              @mouseleave="isHovered = ''"
            >
              {{ $t('appBar.dashboard') }}
            </h3>
            <v-spacer></v-spacer>
            <h3
              @click="$router.push({ name: 'ClientsView' })"
              style="cursor: pointer"
              class="text-thin"
              :class="{
                highlight:
                  $route.name == 'ClientsView' ||
                  $route.path.split('/')[1] == 'clients',
                'primary--text':
                  isHovered == 'ClientsView' && $route.name != 'ClientsView',
                'text-h5': !isMobile,
              }"
              @mouseover="isHovered = 'ClientsView'"
              @mouseleave="isHovered = ''"
            >
              {{
                $route.path.split('/').length > 2
                  ? $t('appBar.clients') + currentCompanyName
                  : $t('appBar.clients')
              }}
            </h3>

            <v-spacer v-if="allowToPerformAction('createClient')"></v-spacer>
            <h3
              v-if="allowToPerformAction('createClient')"
              @click="$router.push({ name: 'CreateClientView' })"
              style="cursor: pointer"
              class="text-thin"
              :class="{
                highlight: $route.name == 'CreateClientView',
                'primary--text':
                  isHovered == 'CreateClientView' &&
                  $route.name != 'CreateClientView',
                'text-h5': !isMobile,
              }"
              @mouseover="isHovered = 'CreateClientView'"
              @mouseleave="isHovered = ''"
            >
              {{ $t('appBar.createClient') }}
            </h3>
            <v-spacer v-if="isMarketingUser"></v-spacer>
            <h3
              v-if="isMarketingUser"
              @click="$router.push({ name: 'NewsView' })"
              style="cursor: pointer"
              class="text-thin"
              :class="{
                highlight: $route.name == 'NewsView',
                'primary--text':
                  isHovered == 'NewsView' && $route.name != 'NewsView',
                'text-h5': !isMobile,
              }"
              @mouseover="isHovered = 'NewsView'"
              @mouseleave="isHovered = ''"
            >
              {{ $t('appBar.news') }}
            </h3>
          </v-row>
        </transition>
      </v-col>
      <v-col v-if="isMobile" class="text-right">
        <h2>
          <span v-if="$route.name != 'ClientDetailView'">
            {{ $t('tabTitle.' + $route.meta.tabTitle) }}
          </span>
          <span v-else>{{
            $route.path.split('/').length > 2
              ? $t('appBar.clients') + currentCompanyName
              : $t('appBar.clients')
          }}</span>
        </h2>
      </v-col>
      <v-spacer v-if="isMobile"></v-spacer>
      <v-col class="text-right">
        <AppBarMenu />
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import { allowToPerformAction } from '@/services/users-service'
import AppBarMenu from '@/components/app/AppBarMenu.vue'
import { mapGetters } from 'vuex'
import { getUserInfo } from '../../services/clients-service'

export default {
  components: {
    AppBarMenu,
  },
  data() {
    return {
      isHovered: '',
      currentCompanyName: '',
    }
  },
  async created() {
    if (this.$route.name == 'ClientDetailView')
      await this.updateCurrentCompanyName(this.$route.params.id)
  },
  watch: {
    $route: async function (newValue) {
      if (newValue?.name == 'ClientDetailView')
        await this.updateCurrentCompanyName(this.$route.params.id)
      else this.updateCurrentCompanyName()
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isMarketingUser']),
  },
  methods: {
    allowToPerformAction(action) {
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
    async updateCurrentCompanyName(userId) {
      this.currentCompanyName = userId
        ? (await getUserInfo(userId)).empresa
        : ''
    },
  },
}
</script>

<style scoped>
.text-h3 {
  font-weight: 300; /* Ajusta el peso de la fuente según tus preferencias */
}
.text-h2 {
  font-weight: 300;
}
.text-thin {
  font-weight: 200;
}

.flip-enter-active {
  animation: flip-enter 0.25s;
}
.flip-leave-active {
  animation: flip-leave 0.25s;
}

@keyframes flip-enter {
  from {
    transform: rotateX(-90deg);
  }
  to {
    transform: rotateX(0deg);
  }
}

@keyframes flip-leave {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(90deg);
  }
}
.highlight {
  background-color: black;
  color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  transition: background-color 0.5s ease;
}

.dark .highlight {
  background-color: white;
  color: black;
}

.light .highlight {
  background-color: black;
  color: white;
}
</style>
