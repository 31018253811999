<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12"> <DashboardCard></DashboardCard> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue'

export default {
  name: 'DashboardView',
  components: {
    DashboardCard,
  },
  data() {
    return {}
  },
}
</script>
