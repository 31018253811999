<template>
  <apexChart
    type="line"
    height="350"
    :options="chartOptionsComputed"
    :series="clientDates.series"
  >
  </apexChart>
</template>

<script>
export default {
  props: {
    clients: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    clientDates() {
      let months = []
      let date = new Date()
      for (let i = 0; i < 12; i++) {
        months.unshift(
          date.toLocaleString(this.$i18n.locale, { month: 'long' })
        )
        date.setMonth(date.getMonth() - 1)
      }
      let series = [
        { name: 'Empresa', data: [] },
        { name: 'Asesoría', data: [] },
      ]

      // Filtramos por clientes creados en el mes actual y los 11 pasados
      // Ya que sino al obtener el mes metería en el mismo mes los de distintos años
      let clients = this.clients.filter((client) => {
        if (!client.createdAt) {
          return false
        }
        let clientDate = new Date(client.createdAt)
        let currentDate = new Date()
        return (
          (clientDate.getFullYear() === currentDate.getFullYear() &&
            clientDate.getMonth() <= currentDate.getMonth()) ||
          (clientDate.getFullYear() === currentDate.getFullYear() - 1 &&
            clientDate.getMonth() > currentDate.getMonth())
        )
      })

      // Contar el número de usuarios creados por mes y tipo
      for (let i = 0; i < 12; i++) {
        let month = months[i]
        let empresaCount = clients.filter(
          (client) =>
            client.tipoEmpresa === 'EMPRESA' &&
            client.createdAt.toLocaleString(this.$i18n.locale, {
              month: 'long',
            }) === month
        ).length
        let asesoriaCount = clients.filter(
          (client) =>
            client.tipoEmpresa === 'ASESORIA' &&
            client.createdAt.toLocaleString(this.$i18n.locale, {
              month: 'long',
            }) === month
        ).length
        series[0].data.push(empresaCount)
        series[1].data.push(asesoriaCount)
      }

      return { series, months }
    },
    chartOptionsComputed() {
      return {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('charts.clientsHistory'),
          align: 'left',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.clientDates.months,
          title: {
            text: this.$t('charts.month'),
          },
        },
        yaxis: {
          title: {
            text: this.$t('charts.sellsNumber'),
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
