var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"700","min-width":"400"}},[_c('v-expansion-panels',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"700px"},attrs:{"accordion":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-form',{ref:"newsUpdateForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}},model:{value:(_vm.validNewUpdateForm),callback:function ($$v) {_vm.validNewUpdateForm=$$v},expression:"validNewUpdateForm"}},_vm._l((_vm.news),function(item,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('strong',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$d(item.releaseDate)))])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.body.slice(0, 100))+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('news.title'),"rules":[_vm.notEmpty],"disabled":_vm.loading},model:{value:(_vm.selectedNew.title),callback:function ($$v) {_vm.$set(_vm.selectedNew, "title", $$v)},expression:"selectedNew.title"}})],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('news.url'),"disabled":_vm.loading,"rules":[
                            /*startByCharacters(['http://', 'https://']),*/
                          ]},model:{value:(_vm.selectedNew.url),callback:function ($$v) {_vm.$set(_vm.selectedNew, "url", $$v)},expression:"selectedNew.url"}})],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('news.mandatory'),"disabled":_vm.loading},model:{value:(_vm.selectedNew.mandatory),callback:function ($$v) {_vm.$set(_vm.selectedNew, "mandatory", $$v)},expression:"selectedNew.mandatory"}})],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('news.push'),"disabled":_vm.loading},model:{value:(_vm.selectedNew.push),callback:function ($$v) {_vm.$set(_vm.selectedNew, "push", $$v)},expression:"selectedNew.push"}})],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"6"}},[_c('v-menu',{ref:'menuReleaseDate_' + item.id,refInFor:true,staticClass:"mr-2",attrs:{"dense":"","v-model":'menu_releaseDate_' + item.id,"close-on-content-click":false,"v-model:propName":_vm.selectedNew.releaseDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('news.releaseDate'),"prepend-icon":"mdi-calendar","readonly":"","rules":[_vm.notEmpty],"disabled":_vm.loading},model:{value:(_vm.selectedNew.releaseDate),callback:function ($$v) {_vm.$set(_vm.selectedNew, "releaseDate", $$v)},expression:"selectedNew.releaseDate"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"locale":_vm.$t('javascriptLocale'),"no-title":"","scrollable":""},on:{"click:date":function($event){_vm.$refs['menuReleaseDate_' + item.id][
                                _vm.$refs['menuReleaseDate_' + item.id].length - 1
                              ].save(_vm.selectedNew.releaseDate)}},model:{value:(_vm.selectedNew.releaseDate),callback:function ($$v) {_vm.$set(_vm.selectedNew, "releaseDate", $$v)},expression:"selectedNew.releaseDate"}})],1)],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"6"}},[_c('v-menu',{ref:'menuEndDate_' + item.id,refInFor:true,staticClass:"mr-2",attrs:{"dense":"","v-model":'menu_endDate_' + item.id,"close-on-content-click":false,"v-model:propName":_vm.selectedNew.endDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('news.endDate'),"prepend-icon":"mdi-calendar","readonly":"","clearable":"","disabled":_vm.loading},model:{value:(_vm.selectedNew.endDate),callback:function ($$v) {_vm.$set(_vm.selectedNew, "endDate", $$v)},expression:"selectedNew.endDate"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"locale":_vm.$t('javascriptLocale'),"min":_vm.minEndDate,"no-title":"","scrollable":""},on:{"click:date":function($event){_vm.$refs['menuEndDate_' + item.id][
                                _vm.$refs['menuEndDate_' + item.id].length - 1
                              ].save(_vm.selectedNew.endDate)}},model:{value:(_vm.selectedNew.endDate),callback:function ($$v) {_vm.$set(_vm.selectedNew, "endDate", $$v)},expression:"selectedNew.endDate"}})],1)],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.userTypes,"item-text":"text","item-value":"id","menu-props":{ maxHeight: '400' },"label":_vm.$t('news.targetUser'),"multiple":"","hint":_vm.$t('news.targetUserHint'),"persistent-hint":"","rules":[_vm.listNotEmpty],"disabled":_vm.loading},model:{value:(_vm.selectedNew.targetUser),callback:function ($$v) {_vm.$set(_vm.selectedNew, "targetUser", $$v)},expression:"selectedNew.targetUser"}})],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.products,"item-text":"text","item-value":"id","menu-props":{ maxHeight: '400' },"label":_vm.$t('news.targetProduct'),"multiple":"","hint":_vm.$t('news.targetProductHint'),"persistent-hint":"","rules":[_vm.listNotEmpty],"disabled":_vm.loading},model:{value:(_vm.selectedNew.targetProduct),callback:function ($$v) {_vm.$set(_vm.selectedNew, "targetProduct", $$v)},expression:"selectedNew.targetProduct"}})],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('news.body'),"rules":[_vm.notEmpty],"disabled":_vm.loading},model:{value:(_vm.selectedNew.body),callback:function ($$v) {_vm.$set(_vm.selectedNew, "body", $$v)},expression:"selectedNew.body"}})],1),_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.updateNew}},[_vm._v(" "+_vm._s(_vm.$t('news.update'))+" ")]),_c('v-btn',{staticClass:"ml-2 error",attrs:{"disabled":_vm.loading},on:{"click":_vm.deleteNew}},[_vm._v(" "+_vm._s(_vm.$t('news.delete'))+" ")])],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }