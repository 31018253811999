<template>
  <v-container fill-height>
    <v-row align="center">
      <v-row v-if="!isMobile" justify="center" class="pa-5">
        <v-img
          max-width="500"
          src="@/assets/app-logo.png"
          transition="fade-transition"
          @click="$router.push({ name: 'LoginView' })"
        />
        <v-progress-linear
          v-if="$store.state.loading"
          indeterminate
          color="black"
        >
        </v-progress-linear>
      </v-row>
      <v-row justify="center" class="pa-3" v-else>
        <v-img
          max-width="250"
          v-if="$vuetify.theme.dark"
          src="@/assets/app-logo.png"
          transition="fade-transition"
          @click="$router.push({ name: 'LoginView' })"
        />
        <v-img
          v-else
          max-width="250"
          src="@/assets/app-logo.png"
          transition="fade-transition"
          @click="$router.push({ name: 'LoginView' })"
        />
      </v-row>
      <v-row no-gutters justify="center" class="pa-5">
        <v-col cols="12" md="7">
          <LoginLanding />
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
import LoginLanding from '@/components/login/LoginLanding.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginView',
  components: {
    LoginLanding,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>
<style scoped>
pre {
  font-family: monospace;
  white-space: pre-wrap;
}
.typewriter-text {
  overflow: hidden; /* Oculta el texto que aún no se ha mostrado */
  animation: typing 0.5s steps(150) forwards; /* Define la animación */
  border-right: 2px solid; /* Simula el cursor de la terminal */
}

@keyframes typing {
  from {
    width: 0; /* Inicia con un ancho de cero */
  }
  to {
    width: 100%; /* Aumenta el ancho hasta llegar al 100% */
    border-right: 0px solid; /* Simula el cursor de la terminal */
  }
}
</style>
