<template>
  <v-card :min-height="height">
    <v-container>
      <v-row justify="space-around" align="center">
        <v-col cols="12" md="4">
          <ProductsChart :clients="clients"></ProductsChart>
        </v-col>
        <v-col cols="12" md="8">
          <LineClientCreations :clients="clients"></LineClientCreations>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import ProductsChart from '@/components/profile/charts/ProductsChart.vue'
import LineClientCreations from '@/components/profile/charts/LineClientCreations.vue'

export default {
  components: {
    ProductsChart,
    LineClientCreations,
  },
  props: {
    height: {
      type: String,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapState(['clients']),
  },
}
</script>

<style lang="scss" scoped></style>
