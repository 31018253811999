<template>
  <v-container fill-height fluid>
    <v-row justify="space-around">
      <v-col cols="12" md="4">
        <NewsList />
      </v-col>
      <v-col cols="12" md="6">
        <CreateNew />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewsList from '@/components/news/creation/NewsList'
import CreateNew from '@/components/news/creation/CreateNew'

export default {
  components: {
    NewsList,
    CreateNew,
  },
}
</script>

<style lang="scss" scoped></style>
