import i18n from '@/i18n'
import { HelpLogFunction } from '@/firebase-exports'

export default function (msg) {
  var error
  if (msg.includes('Not signed in')) {
    error = i18n.t('unathenticatedError')
  } else if (msg.includes('Not granted')) {
    error = i18n.t('permissionDeniedError')
  } else if (msg.includes('invalid-email')) {
    error = i18n.t('invalidEmail')
  } else if (msg.includes('user-not-found') || msg.includes('wrong-password')) {
    error = i18n.t('errorLogin')
  } else if (msg.includes('user-disabled')) {
    error = i18n.t('userDisabled')
  } else if (msg.includes('too-many-requests')) {
    error = i18n.t('tooManyRequests')
  } else if (msg.includes('management-empresa')) {
    error = i18n.t('managementEmpresa')
  } else if (msg.includes('management-gestor')) {
    error = i18n.t('managementGestor')
  } else if (msg.includes('management-notification-license')) {
    error = i18n.t('managementNotificationLicense')
  } else if (msg.includes('management-signatures-license')) {
    error = i18n.t('managementSignatureLicense')
  } else if (msg.includes('management-firestore')) {
    error = i18n.t('managementFirestore')
  } else if (msg.includes('management-auth')) {
    error = i18n.t('genericAuthError')
  } else if (msg.includes('management-docIdentidad-already-exists')) {
    error = i18n.t('docIdentidadExists')
  } else if (msg.includes('management-email-already-exists')) {
    error = i18n.t('emailExists')
  } else if (msg.includes('management-invalid-user-type')) {
    error = i18n.t('invalidUserType')
  } else if (msg.includes('auth-email')) {
    error = i18n.t('authEmailError')
  } else if (msg.includes('management-email')) {
    error = i18n.t('sendEmailError')
  } else if (msg.includes('lower-renewal-error')) {
    error = i18n.t('lowerRenewalError')
  } else if (msg.includes('exceeds-max-cifs')) {
    error = i18n.t('exceedsMaxCIFs')
  } else {
    error = i18n.t('genericError')
  }

  if (error == i18n.t('genericError')) {
    console.log(msg)
    HelpLogFunction({ log: msg }).catch((e) => void e) // Catch to ignore errors in front
  }

  return error
}
