<template>
  <v-container class="pa-0" fluid no-glutters>
    <v-row justify="space-around">
      <v-col
        v-for="item in services"
        v-bind:key="item.concept"
        cols="12"
        md="6"
        :lg="item.selected ? 6 : isConfiguration ? 4 : 6"
      >
        <!-- :md="isConfiguration ? 9 : 5"
        :sm="isConfiguration ? 9 : 5" -->
        <v-card class="pa-2">
          <v-container>
            <v-row
              @click="item.selected = !item.selected"
              justify="space-between"
            >
              <v-col cols="auto">
                <v-icon
                  v-if="!isConfiguration && item.selected"
                  color="primary"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else-if="!isConfiguration"
                  :id="
                    item.concept == 'multiFactor'
                      ? 'select-2FA'
                      : 'select-custom-brand'
                  "
                >
                  mdi-circle-outline
                </v-icon>

                <v-icon x-large>
                  {{
                    item.concept == 'multiFactor'
                      ? 'mdi-two-factor-authentication'
                      : 'mdi-palette'
                  }}
                </v-icon>
              </v-col>
              <v-col cols="auto">
                <h2>{{ $t('client.additionalServices.' + item.concept) }}</h2>
              </v-col>
            </v-row>

            <v-row v-if="isConfiguration" justify="center">
              <v-col cols="6">
                <v-text-field
                  v-if="item.concept === 'multiFactor'"
                  v-model="multiFactorPriceModel"
                  :rules="[notEmptyNumber]"
                  :placeholder="$t('price') + '(€)'"
                >
                  €
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="customBrandPriceModel"
                  :rules="[notEmptyNumber]"
                  :placeholder="$t('price') + '(€)'"
                >
                  €
                </v-text-field>
                <v-btn
                  v-if="
                    item.concept === 'multiFactor'
                      ? checkButton2FARulesComputed
                      : checkButtonCustomBrandRulesComputed
                  "
                  @click.stop="changeCuotaPrice(item.concept)"
                  class="ma-0 pa-0"
                  plain
                >
                  {{ $t('client.customBrand.save') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-expand-transition v-else>
              <v-row
                v-if="item.selected"
                align="center"
                justify="space-between"
              >
                <v-col cols="7">
                  <v-slider
                    :disabled="isChannelUser"
                    class="mt-5"
                    v-model="item.discountPercentage"
                    :label="$t('client.cuotaCard.discount')"
                    :thumb-color="$vuetify.theme.dark ? 'white' : 'black'"
                  >
                    <template v-slot:thumb-label="{ value }">
                      {{ value }}%
                    </template>
                  </v-slider>
                </v-col>
                <v-col cols="auto">
                  <h3>
                    {{ $n(item.price, 'i18nAmount') }}€ -
                    {{ item.discountPercentage }}% =
                    {{
                      $n(
                        item.price -
                          (item.price * item.discountPercentage) / 100,
                        'i18nAmount'
                      )
                    }}
                    €
                  </h3>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import { updateBilling } from '@/services/billing-service'

export default {
  props: {
    multiFactorPrice: {
      type: Number,
    },
    customBrandPrice: {
      type: Number,
    },
    selected2FA: {
      type: Boolean,
    },
    selectedCustomBrand: {
      type: Boolean,
    },
    fixedDiscount: {
      type: Number,
      default: 0,
    },
    isConfiguration: {
      type: Boolean,
      default: false,
    },
    companyType: {
      type: String,
    },
  },
  created() {
    this.multiFactorPriceModel = this.multiFactorPrice
    this.customBrandPriceModel = this.customBrandPrice
  },
  data() {
    return {
      multiFactorPriceModel: this.multiFactorPrice,
      customBrandPriceModel: this.customBrandPrice,
      services: [
        {
          selected: this.selected2FA ? this.selected2FA : false,
          concept: 'multiFactor',
          price: this.multiFactorPrice,
          discountAmount: 0,
          discountPercentage: this.fixedDiscount,
        },
        {
          selected: this.selectedCustomBrand ? this.selectedCustomBrand : false,
          concept: 'customBrand',
          price: this.customBrandPrice,
          discountAmount: 0,
          discountPercentage: this.fixedDiscount,
        },
      ],
    }
  },

  methods: {
    ...rules,
    ...mapMutations(['setSnackbar']),
    async changeCuotaPrice(concept) {
      try {
        if (concept === 'multiFactor') {
          if (this.companyType == 'EMPRESA')
            await updateBilling({
              empresa2FACuota: parseInt(this.multiFactorPriceModel),
            })
          if (this.companyType == 'ASESORIA')
            await updateBilling({
              despacho2FACuota: parseInt(this.multiFactorPriceModel),
            })
        } else {
          if (this.companyType == 'EMPRESA')
            await updateBilling({
              empresaBrandCuota: parseInt(this.customBrandPriceModel),
            })
          if (this.companyType == 'ASESORIA')
            await updateBilling({
              despachoBrandCuota: parseInt(this.customBrandPriceModel),
            })
        }
        const message = this.$t('updatePriceSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = this.$t('updatePriceError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        // Limpiamos el precio
        this.multiFactorPriceModel = this.multiFactorPrice
        this.customBrandPriceModel = this.customBrandPrice
      }
    },
  },
  computed: {
    ...mapGetters(['isChannelUser']),
    checkButton2FARulesComputed() {
      return (
        this.multiFactorPriceModel !== '' &&
        this.multiFactorPriceModel !== undefined &&
        this.multiFactorPriceModel !== null &&
        this.multiFactorPriceModel != this.multiFactorPrice &&
        this.multiFactorPriceModel > 0
      )
    },
    checkButtonCustomBrandRulesComputed() {
      return (
        this.customBrandPriceModel !== '' &&
        this.customBrandPriceModel !== undefined &&
        this.customBrandPriceModel !== null &&
        this.customBrandPriceModel != this.customBrandPrice &&
        this.customBrandPriceModel > 0
      )
    },
    servicesComputed() {
      this.services.forEach((service) => {
        service.discountAmount =
          (service.price * service.discountPercentage) / 100
      })
      return this.services
    },
  },
  mounted() {
    this.$emit('customServices', this.services)
  },
  watch: {
    servicesComputed: {
      handler(newValue) {
        this.$emit('customServices', newValue)
      },
      deep: true, // Activa la vigilancia profunda
    },
    multiFactorPrice: function (newValue) {
      this.services[0].price = newValue
    },
    customBrandPrice: function (newValue) {
      this.services[1].price = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
.selected {
  border: 1px solid var(--v-primary-base);
}
</style>
