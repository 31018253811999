<template>
  <v-fade-transition mode="out-in">
    <v-card
      v-if="condition"
      height="100vh"
      :color="primary"
      style="border-radius: 0"
    >
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" align="center" justify="center">
            <v-img src="@/assets/app-logo.png" width="300" />
          </v-col>
          <v-col cols="12" align="center" justify="center">
            <v-progress-circular indeterminate :color="secondary" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <slot name="app-content" v-else />
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    condition: Boolean,
  },
  data() {
    return {
      primary: 'white',
      secondary: 'black',
    }
  },
}
</script>

<style lang="scss" scoped></style>
