<template>
  <v-timeline over dense max-height="300">
    <v-virtual-scroll :items="trace" :item-height="80" height="600">
      <template v-slot:default="{ item }">
        <v-timeline-item
          class="text-left"
          :color="item.notifications < 0 || item.tokens < 0 ? 'red' : 'green'"
        >
          <h3>
            {{ $t('wholesalerBalance.actions.' + item.type) }} -
            {{ $d(item.createdAt) }}
          </h3>

          <div v-if="item.notifications">
            <span class="green--text" v-if="item.notifications > 0">+</span>
            <b :class="item.notifications > 0 ? 'green--text' : 'red--text'">{{
              item.notifications
            }}</b>
            =
            <b>{{ item.notificationsBalanceResult }}</b>
            {{ $t('notifications') }}
          </div>
          <div v-if="item.tokens">
            <span class="green--text" v-if="item.notifications > 0">+</span>

            <b :class="item.notifications > 0 ? 'green--text' : 'red--text'">{{
              item.tokens
            }}</b>
            =
            <b>{{ item.tokensBalanceResult }}</b>
            {{ $t('wholesalerBalance.tokens') }}
          </div>
        </v-timeline-item></template
      >
    </v-virtual-scroll>
  </v-timeline>
</template>

<script>
export default {
  props: {
    trace: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
