<template>
  <v-col style="max-width: 900px">
    <br />
    <h1 class="left-align">
      {{ $t('documentation.titles.home.subsections.delete') }}
    </h1>
    <p class="left-align">
      {{ $t('documentation.texts.delete.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1Name" />
    <p class="left-align">
      {{ $t('documentation.texts.delete.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic2Name" />
    <br />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAddaliaAdminUser']),
    pic1Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.delete.picWarning') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.delete.picSureDeletion') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '') {
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      } else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}

.custom-link:hover {
  text-decoration: underline;
}

.left-align {
  text-align: left;
}
</style>
