<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        id="upgrade-cuota-button"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>

    <v-card v-if="dialog" id="upgrade-card">
      <v-card-title class="text-h5 grey lighten-2" id="upgrade-title">
        {{ $t('client.cuotaCard.upgradeCuota') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false" id="close-button"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <CuotaLicenses
            :label="$t('numberOfCifs')"
            :cuotaServicio="cuotaServicio"
            :fixedDiscount="isDistributorUser ? $store.state.user.percent : 0"
            resultEventName="upgradeCuotaCIFs"
            @upgradeCuotaCIFs="upgradeCuotaCIFsEvent"
          />
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="confirmPurchase"
          id="confirm-purchase-button"
          :disabled="!eventObject?.amount || parseInt(eventObject.amount) <= 0"
        >
          {{ $t('confirmPurchase') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CuotaLicenses from '@/components/client/createClient/CuotaLicenses.vue'
import { createInvoice } from '@/services/clients-service'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    disabled: {
      type: Boolean,
    },
    cuotaServicio: {
      type: Number,
    },
    client: {
      type: Object,
    },
    typeProp: {
      type: String,
    },
  },
  components: {
    CuotaLicenses,
  },
  data() {
    return {
      dialog: false,
      eventObject: {},
    }
  },
  computed: {
    ...mapGetters(['isDistributorUser']),
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setLoading']),
    upgradeCuotaCIFsEvent(newValue) {
      this.eventObject = newValue
    },
    async confirmPurchase() {
      try {
        this.loading = true

        const newData = {
          ...this.eventObject,
          status: 'pendingBilling',
          date: new Date(),
          concept: 'notificationsCuota',
          type: this.typeProp,
          // packages: [parseInt(this.eventObject.amount)],
        }

        await createInvoice(this.client.id, newData)
        this.dialog = false
        this.loading = false

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('invoiceCreateSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        this.loading = false
        const message = this.$t('invoiceCreateError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
