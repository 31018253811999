<template>
  <v-card :min-height="height" class="mt-4">
    <apexChart
      max-width="530"
      type="donut"
      :options="charOptionsComputed"
      :series="commercialsSells.quantities"
    >
    </apexChart>
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
    },
    clients: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    commercialsSells() {
      const commercialsCount = this.clients.reduce((result, client) => {
        const commercial = client.commercial?.name
        // Avoid undefined results
        if (!commercial) return result

        if (result[commercial]) {
          result[commercial]++
        } else {
          result[commercial] = 1
        }
        return result
      }, {})

      const names = Object.keys(commercialsCount)
      const quantities = Object.values(commercialsCount)

      return {
        names,
        quantities,
      }
    },
    charOptionsComputed() {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.commercialsSells.names,

        title: {
          text: this.$t('charts.sellsCommercial'),
          align: 'left',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
