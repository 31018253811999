<template style="position: sticky">
  <v-row>
    <CreateClientDoc v-if="!$route.query.section" />

    <CreateClientDoc
      v-if="
        $route.query.section === 'home' && $route.query.subsection === 'create'
      "
    />

    <UpdateClientDoc
      v-if="
        $route.query.section === 'home' && $route.query.subsection === 'update'
      "
    />

    <DeleteClientDoc
      v-if="
        $route.query.section === 'home' && $route.query.subsection === 'delete'
      "
    />

    <ActivateServicesDoc
      v-if="$route.query.section === 'services' && !$route.query.subsection"
    />

    <ActivateServicesDoc
      v-if="
        $route.query.section === 'services' &&
        $route.query.subsection === 'activation'
      "
    />

    <RenewServicesDoc
      v-if="
        $route.query.section === 'services' &&
        $route.query.subsection === 'renewal'
      "
    />

    <UpgradeServicesDoc
      v-if="
        $route.query.section === 'services' &&
        $route.query.subsection === 'upgrade'
      "
    />
  </v-row>
</template>

<script>
import CreateClientDoc from '@/components/documentation/sections/create/CreateClientDoc'
import UpdateClientDoc from '@/components/documentation/sections/update/UpdateClientDoc'
import DeleteClientDoc from '@/components/documentation/sections/delete/DeleteClientDoc'
import ActivateServicesDoc from '@/components/documentation/sections/activation/ActivateServicesDoc'
import RenewServicesDoc from '@/components/documentation/sections/renewal/RenewServicesDoc'
import UpgradeServicesDoc from '@/components/documentation/sections/upgrade/UpgradeServicesDoc'

import { mapGetters } from 'vuex'

export default {
  components: {
    CreateClientDoc,
    UpdateClientDoc,
    DeleteClientDoc,
    ActivateServicesDoc,
    RenewServicesDoc,
    UpgradeServicesDoc,
  },
  computed: {
    ...mapGetters(['isAddaliaAdminUser']),
  },
}
</script>
