<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="10">
        <ClientList :clients="clients" :loading="loading"></ClientList>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClientList from '@/components/client/ClientList.vue'

export default {
  name: 'ClientListView',
  components: {
    ClientList,
  },
  async created() {
    this.clients = this.$store.state.clients
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  data() {
    return {
      clients: [],
      loading: true,
    }
  },
}
</script>
