<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="ma-1" plain>
          {{ $t('user.profile.editUser') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('user.profile.editUser') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="editUserForm" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    :label="$t('user.profile.name')"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    :label="$t('user.profile.email')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="(name == nameProp && email == emailProp) || !valid"
            color="primary"
            text
            @click="confirmChanges()"
          >
            {{ $t('user.profile.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rules from '@/utils/rules'
import { updateUser, updateEmail } from '@/services/users-service'
import getErrorText from '@/utils/get-error-text'
import { mapMutations } from 'vuex'

export default {
  props: {
    nameProp: {
      type: String,
    },
    emailProp: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      name: this.nameProp,
      email: this.emailProp,
      dialog: false,
      emailRules: [rules.required, rules.email],
      nameRules: [rules.required],
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async confirmChanges() {
      if (!this.$refs.editUserForm?.validate()) return

      try {
        if (this.name !== this.nameProp) {
          await updateUser(this.id, { name: this.name })
        }
        if (this.email !== this.emailProp) {
          await updateEmail(this.email)
        }

        const message = this.$t('user.profile.updatingUserSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
        return
      }
      this.dialog = false
      return
    },
  },
}
</script>

<style lang="scss" scoped></style>
