import { Firestore, TraceManagementCollection } from '@/firebase-exports'

/**
 * Obtiene las trazas de actividad de un usuario.
 *
 * Esta función recupera todas las trazas de actividad de un usuario, ordenadas por fecha de creación de forma descendente.
 *
 * @param {string} userId - El ID del usuario cuyas trazas se van a recuperar.
 * @returns {Promise<Array<Object>>} Una promesa que se resuelve con un array de objetos de trazas de actividad.
 */
export async function getUserTrace(userId) {
  const q = Firestore.query(
    TraceManagementCollection(userId),
    Firestore.orderBy('createdAt', 'desc')
  )
  const querySnapshot = await Firestore.getDocs(q)
  return querySnapshot.docs.map((doc) => {
    const document = doc.data()

    if (document.createdAt) document.createdAt = document.createdAt.toDate()

    return { id: doc.id, ...document }
  })
}

/**
 * Crea una subscripción a las trazas de actividad de un usuario, actualizando el contexto con los cambios en tiempo real.
 *
 * @param {string} userId - El ID del usuario cuyas trazas se están suscribiendo.
 * @param {Object} context - Contexto del componente donde se realiza la subscripción.
 * @param {number} [days=365] - Número de días hacia atrás desde hoy para incluir en la subscripción.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function getUserTraceSubscription(userId, context, days = 365) {
  const todaysDate = new Date()

  const from = new Date().setDate(todaysDate.getDate() - days)
  const fromDate = new Date(from)

  const to = new Date().setDate(todaysDate.getDate() + 1) // Add + 1 day to get new docs after initializing subscription
  const toDate = new Date(to)

  const traceUnsubscribe = Firestore.onSnapshot(
    days != 0
      ? // Taking the last X days
        Firestore.query(
          TraceManagementCollection(userId),
          Firestore.orderBy('createdAt', 'desc'),
          Firestore.startAt(toDate),
          Firestore.endAt(fromDate)
        )
      : // Taking all
        Firestore.query(
          TraceManagementCollection(userId),
          Firestore.orderBy('createdAt', 'desc')
        ),
    (snapShot) => {
      context.operatorTrace = snapShot.docs.map((doc) => {
        const document = doc.data()

        if (document.createdAt) document.createdAt = document.createdAt.toDate()

        return { id: doc.id, ...document }
      })
    },
    (error) => {
      throw error
    }
  )

  return traceUnsubscribe
}

/**
 * Crea una nueva traza de actividad para un usuario.
 *
 * @param {string} userId - El ID del usuario para el cual se crea la traza.
 * @param {Object} data - Datos de la traza a crear.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la traza ha sido creada.
 */
export async function createTrace(userId, data) {
  await Firestore.addDoc(TraceManagementCollection(userId), data)
}
