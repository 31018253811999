<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <CollectiveBilling :history="this.$store.state.history" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CollectiveBilling from '@/components/collective/CollectiveBilling.vue'

export default {
  name: 'BalanceView',
  components: {
    CollectiveBilling,
  },

  data() {
    return {}
  },
}
</script>
