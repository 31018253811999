<template>
  <v-container>
    <v-row class="dashboard-content">
      <v-col cols="12">
        <v-card class="widget">
          <h2>{{ $t('dashboard.clientListTitle') }}</h2>
          <p>{{ $t('dashboard.clientListText') }}</p>
          <div>
            <ClientList :clients="clients" :loading="loading"></ClientList>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClientList from '@/components/client/ClientList.vue'
export default {
  name: 'DashboardCard',
  components: {
    ClientList,
  },
  async created() {
    this.clients = this.$store.state.clients
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  data() {
    return {
      clients: [],
      loading: true,
    }
  },
}
</script>

<style>
.widget {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}
@import '@/styles/global/global-styles.css';
</style>
