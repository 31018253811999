<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-container>
          <v-row align="center" justify="center">
            <h1>{{ $t('customization.title') }}</h1>
          </v-row>
          <v-row align="center" justify="center">
            <h3 class="headline mb-1">{{ $t('customization.subtitle') }}</h3>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="10">
              <ChannelCustomBrand
                :customBrand="$store.state.user.customBrand"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row></v-row>
  </v-container>
</template>

<script>
import ChannelCustomBrand from '@/components/customization/ChannelCustomBrand.vue'

export default {
  components: {
    ChannelCustomBrand,
  },

  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
