<template>
  <v-card class="pa-5">
    <v-container>
      <v-form
        ref="newsCreateForm"
        v-model="validNewsCreateForm"
        @submit.prevent="() => {}"
        lazy-validation
      >
        <v-row justify="space-between">
          <v-col class="ma-0 pa-2" cols="12">
            <v-text-field
              :label="$t('news.title')"
              v-model="newObject.title"
              :rules="[notEmpty]"
              :disabled="loading"
            >
            </v-text-field>
          </v-col>
          <v-col class="ma-0 pa-2" cols="12">
            <v-text-field
              :label="$t('news.url')"
              v-model="newObject.url"
              :disabled="loading"
              :rules="[
                /*startByCharacters(['http://', 'https://'])*/
              ]"
            >
            </v-text-field>
          </v-col>
          <v-col class="ma-0 pa-2" cols="6">
            <v-checkbox
              :label="$t('news.mandatory')"
              v-model="newObject.mandatory"
            >
            </v-checkbox>
          </v-col>
          <v-col class="ma-0 pa-2" cols="6">
            <v-checkbox
              :label="$t('news.push')"
              v-model="newObject.push"
              :disabled="loading"
            >
            </v-checkbox>
          </v-col>

          <v-col class="ma-0 pa-2" cols="6">
            <v-menu
              dense
              ref="menuReleaseDate"
              v-model="menu_releaseDate"
              :close-on-content-click="false"
              :v-model:propName="newObject.releaseDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="mr-2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newObject.releaseDate"
                  :label="$t('news.releaseDate')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[notEmpty]"
                  :disabled="loading"
                >
                </v-text-field>
              </template>
              <v-date-picker
                @click:date="$refs.menuReleaseDate.save(newObject.releaseDate)"
                v-model="newObject.releaseDate"
                :locale="$t('javascriptLocale')"
                :min="new Date().toISOString().substring(0, 10)"
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="ma-0 pa-2" cols="6">
            <v-menu
              dense
              ref="menuEndDate"
              v-model="menu_endDate"
              :close-on-content-click="false"
              :v-model:propName="newObject.endDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="mr-2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newObject.endDate"
                  :label="$t('news.endDate')"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loading"
                >
                </v-text-field>
              </template>
              <v-date-picker
                @click:date="$refs.menuEndDate.save(newObject.endDate)"
                v-model="newObject.endDate"
                :locale="$t('javascriptLocale')"
                :min="minEndDate"
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col class="ma-0 pa-2" cols="6">
            <v-select
              v-model="newObject.targetUser"
              :items="userTypes"
              item-text="text"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('news.targetUser')"
              multiple
              :hint="$t('news.targetUserHint')"
              persistent-hint
              :rules="[listNotEmpty]"
              :disabled="loading"
            >
            </v-select>
          </v-col>
          <v-col class="ma-0 pa-2" cols="6">
            <v-select
              v-model="newObject.targetProduct"
              :items="products"
              item-text="text"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('news.targetProduct')"
              multiple
              :hint="$t('news.targetProductHint')"
              persistent-hint
              :rules="[listNotEmpty]"
              :disabled="loading"
            >
            </v-select>
          </v-col>
          <v-col class="ma-0 pa-2" cols="12">
            <v-textarea
              :label="$t('news.body')"
              v-model="newObject.body"
              :rules="[notEmpty]"
              :disabled="loading"
            >
            </v-textarea>
          </v-col>
          <v-col class="ma-0 pa-2" cols="12">
            <v-btn @click="createNew" class="ml-2 primary" :disabled="loading">
              {{ $t('news.create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import {
  createNew,
  getTargetUsers,
  getTargetProducts,
} from '@/services/news-service'
import { mapMutations } from 'vuex'
import rules from '@/utils/rules'

export default {
  data() {
    return {
      newObject: {
        title: '',
        body: '',
        url: '',
        releaseDate: '',
        endDate: '',
        targetUser: [],
        targetProduct: [],
        mandatory: false,
        push: false,
      },
      userTypes: getTargetUsers(),
      products: getTargetProducts(),
      loading: false,
      validNewsCreateForm: undefined,
      menu_releaseDate: false,
      menu_endDate: false,
    }
  },
  computed: {
    minEndDate() {
      return !this.newObject.releaseDate
        ? new Date().toISOString().substring(0, 10)
        : new Date(
            new Date(this.newObject.releaseDate).setDate(
              new Date(this.newObject.releaseDate).getDate() + 1
            )
          )
            .toISOString()
            .substring(0, 10)
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    async createNew() {
      if (!this.$refs.newsCreateForm?.validate()) return

      try {
        this.loading = true

        await createNew(this.newObject)

        const message = this.$t('news.actionsMessages.createSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })

        this.$refs.newsCreateForm?.reset()
      } catch {
        const message = this.$t('news.actionsMessages.createError')
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
