<template>
  <v-card>
    <v-card-title>
      <v-row class="d-flex justify-center" no-gutters>
        <h2 id="invoices-title">{{ $t('invoices.title') }}</h2>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredConcepts"
      :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-row class="pl-3 pr-3">
          <v-btn
            v-if="
              isAddaliaUser &&
              !selectedItemsToRenew.length &&
              selectedItemsToActivate.length
            "
            color="primary"
            :disabled="
              !allowToPerformAction('activateInvoice') ||
              !selectedItemsToActivate.length ||
              $store.state.loading ||
              !!selectedItemsToRenew.length
            "
            plain
            @click="activateUpgrade(selectedItemsToActivate)"
            id="confirm-upgrade-btn"
          >
            <span v-if="!$store.state.loading">
              {{ $t('invoices.actions.goToActivate') }}
            </span>
            <v-progress-circular v-else indeterminate color="primary">
            </v-progress-circular>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  v-if="
                    isAddaliaUser &&
                    selectedItemsToRenew.length &&
                    !selectedItemsToActivate.length
                  "
                  color="primary"
                  :disabled="
                    !allowToPerformAction('activateRenew') ||
                    !selectedItemsToRenew.length ||
                    $store.state.loading ||
                    !!selectedItemsToActivate.length ||
                    !!pendingActivationItems.length ||
                    pendingRenovationItems.length != selectedItemsToRenew.length
                  "
                  plain
                  @click="activateRenewal(selectedItemsToRenew)"
                  id="confirm-renewal-btn"
                >
                  <span v-if="!$store.state.loading">
                    {{ $t('invoices.actions.goToRenew') }}
                  </span>
                  <v-progress-circular v-else indeterminate color="primary">
                  </v-progress-circular>
                </v-btn>
              </div>
            </template>
            <span
              v-if="
                !selectedItemsToRenew.length && selectedItemsToActivate.length
              "
              color="primary"
              :disabled="
                !selectedItemsToActivate.length ||
                !selectedItemsToActivate.length ||
                $store.state.loading ||
                !!selectedItemsToRenew.length
              "
              plain
              @click="activateUpgrade(selectedItemsToActivate)"
            >
              {{ $t('invoices.actions.renewNotAvailable') }}
            </span>
            <span v-else>
              {{ $t('invoices.actions.renewAllItems') }}
            </span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-switch
            v-if="isAddaliaUser && allowToPerformAction('restoreInvoice')"
            v-model="showArchived"
            :label="$t('invoices.showArchived')"
            class="ma-3"
            id="show-archived-switch"
          >
          </v-switch>
          <div class="ma-3" v-else />
        </v-row>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ $d(item.date) }}
      </template>
      <template v-slot:[`item.concept`]="{ item }">
        {{ $t('invoices.' + item.concept) }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ $t('invoices.types.' + item.type) }}
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ $n(item.price, 'i18nAmount') }} €
      </template>
      <template v-slot:[`item.packages`]="{ item }">
        {{ formattedPackages(item) }}
      </template>
      <template v-slot:[`item.discountAmount`]="{ item }">
        {{ $n(item.discountAmount, 'i18nAmount') }} €
      </template>
      <template v-slot:[`item.discountPercentage`]="{ item }">
        {{ $n(item.discountPercentage, 'i18nAmount') }} %
      </template>
      <template v-slot:[`item.finalPrice`]="{ item }">
        {{ $n(item.price - item.discountAmount, 'i18nAmount') }} €
      </template>
      <template v-slot:[`item.comment`]="{ item }">
        <v-tooltip v-if="item.comment" max-width="400" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{
                item.comment.substring(0, 30) +
                (item.comment.length > 30 ? '...' : '')
              }}
            </span>
          </template>
          <span> {{ item.comment }} </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.archived`]="{ item }">
        <v-btn
          v-if="allowToPerformAction('changeInvoiceStatus')"
          @click="openCommentDialog(item)"
          icon
        >
          <v-icon> mdi-comment </v-icon>
        </v-btn>
        <span v-if="item.status !== 'active'">
          <v-icon
            v-if="item.archived && allowToPerformAction('restoreInvoice')"
            @click="restoreInvoice(item)"
          >
            mdi-delete-restore
          </v-icon>
          <v-icon
            v-else-if="allowToPerformAction('deleteInvoice')"
            @click="deleteInvoice(item)"
          >
            mdi-delete
          </v-icon>
        </span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip
          :disabled="
            isAddaliaUser &&
            getNextActionButton(item).allowedRoles.includes(
              $store.state.user.rol
            )
          "
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                :disabled="
                  !isAddaliaUser ||
                  (isAddaliaUser &&
                    !getNextActionButton(item).allowedRoles.includes(
                      $store.state.user.rol
                    ))
                "
                :loading="confirmLoading"
                @click="changeInvoiceStatus(item)"
                v-if="
                  item.status &&
                  item.status != 'pendingActivation' &&
                  item.status != 'active'
                "
                x-small
                outlined
                :class="
                  item.status == 'active' ? 'primary--text' : 'grey--text'
                "
                id="change-status-btn"
              >
                {{ $t('invoices.actions.' + getNextActionButton(item).title) }}
              </v-btn>
            </div>
          </template>
          <span>
            {{ $t('insuficientPermissions') }}
            <!-- {{ getNextActionButton(item).allowedRoles }} -->
          </span>
        </v-tooltip>

        <v-checkbox
          :disabled="
            !isAddaliaUser ||
            (isAddaliaUser &&
              !allowToPerformAction('activateInvoice') &&
              !allowToPerformAction('activateRenew'))
          "
          v-if="item.status && item.status == 'pendingActivation'"
          v-model="item.selected"
          dense
          :label="
            $t(
              item.type == 'renewal'
                ? 'invoices.actions.markToRenew'
                : 'invoices.actions.markToActivate'
            )
          "
          :id="!item.selected ? 'mark-to-renew-or-activate-checkbox' : ''"
        >
        </v-checkbox>
        <v-chip
          color="green"
          text-color="white"
          small
          v-if="item.status && item.status == 'active'"
        >
          {{ $t('invoices.statuses.active') }}
          <v-icon>mdi-check</v-icon>
        </v-chip>
      </template>
    </v-data-table>
    <v-dialog v-model="commentDialog" max-width="800" max-height="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('invoices.actions.addComment') }}
        </v-card-title>

        <v-card-text>
          <v-textarea
            auto-grow
            :label="$t('invoices.comment')"
            v-model="commentInvoiceText"
            id="comment-textarea"
          >
          </v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="saveInvoiceComment()"
            id="save-comment-btn"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { updateInvoice /*deleteInvoice*/ } from '@/services/clients-service'
import { allowToPerformAction } from '@/services/users-service'
import { updateServices } from '@/services/clients-service'
import getErrorText from '@/utils/get-error-text'
import { mapGetters, mapMutations } from 'vuex'
import { toLocaleISOString } from '@/utils/date-utils'

export default {
  props: {
    concepts: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
    },
  },
  data() {
    return {
      confirmLoading: false,
      showArchived: false,
      commentDialog: false,
      selectedInvoiceToCommentId: '',
      commentInvoiceText: '',
    }
  },
  computed: {
    ...mapGetters(['isAddaliaUser']),
    headers() {
      return [
        {
          text: this.$t('invoices.status'),
          align: 'start',
          sortable: false,
          value: 'action',
        },
        { text: this.$t('invoices.type'), value: 'type' },
        { text: this.$t('invoices.concept'), value: 'concept' },
        { text: this.$t('invoices.date'), value: 'date' },
        { text: this.$t('invoices.price'), value: 'price' },
        { text: this.$t('invoices.amount'), value: 'packages' },
        { text: this.$t('invoices.discount'), value: 'discountPercentage' },
        { text: this.$t('invoices.discountAmount'), value: 'discountAmount' },
        { text: this.$t('invoices.finalPrice'), value: 'finalPrice' },
        { text: this.$t('invoices.comment'), value: 'comment' },
        { text: this.$t('invoices.action'), value: 'archived' },
      ]
    },
    selectedItemsToActivate() {
      return this.concepts.filter(
        (item) =>
          item.selected === true &&
          item.status == 'pendingActivation' &&
          (item.type == 'upgrade' || item.type == 'activation')
      )
    },
    pendingActivationItems() {
      return this.computedConcepts.filter(
        (item) => item.status != 'active' && item.type == 'upgrade'
      )
    },
    selectedItemsToRenew() {
      return this.computedConcepts.filter(
        (item) =>
          item.selected === true &&
          item.status == 'pendingActivation' &&
          item.type == 'renewal'
      )
    },
    pendingRenovationItems() {
      return this.computedConcepts.filter(
        (item) => item.status != 'active' && item.type == 'renewal'
      )
    },
    filteredConcepts() {
      // Filtrar los concepts que no tienen archived a true
      if (this.showArchived) {
        return this.concepts.filter((concept) => concept.archived)
      } else {
        return this.concepts.filter((concept) => !concept.archived)
      }
    },
    computedConcepts() {
      // Concepts no archivados
      return this.concepts.filter((concept) => !concept.archived)
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setLoading']),
    allowToPerformAction(action) {
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
    formattedPackages(item) {
      if (item.packages)
        return item.packages
          .map((value) => this.$n(value, 'i18nCount'))
          .join(', ')
      else return '-'
    },
    async changeInvoiceStatus(item) {
      if (item.status == 'pendingActivation') {
        this.activateUpgrade(item)
      } else {
        updateInvoice(this.client.id, item.id, {
          status: this.getNextActionButton(item).nextStatus,
        })
      }
    },
    hasConceptAndStatus(concept, status, items) {
      return items.some(
        (item) => item.concept === concept && item.status === status
      )
    },
    async activateRenewal(items) {
      if (!this.allowToPerformAction('activateRenew')) {
        const message = this.$t('insuficientPermissions')
        this.setSnackbar({ position: 'top', type: 'error', message })
        return
      }
      if (!this.checkIfActivationAvailable(items)) return
      this.setLoading(true)

      // Estructura de datos para enviar a la function
      const dataRenewal = {
        renewServices: true,
        invoiceIds: [],
        services: {
          newGoodOkCuotaToAdd: [],
          newPackagesOfCifsToAdd: [],
          newPackagesOfCertificatesToAdd: [],
          newCustomService2FAToAdd: [],
          newCustomServiceBrandToAdd: [],
        },
      }
      var conceptMapping = {
        goodOkCuota: 'newGoodOkCuotaToAdd',
        notificationsCuota: 'newPackagesOfCifsToAdd',
        signaturesCertsPackages: 'newPackagesOfCertificatesToAdd',
        multiFactor: 'newCustomService2FAToAdd',
        customBrand: 'newCustomServiceBrandToAdd',
      }

      for (const item of items) {
        // Creamos un array de ids
        dataRenewal.invoiceIds.push(item.id)
        // En caso de incurrir en nuevas notificaciones, CIFs, firmas o certificados
        dataRenewal.services[conceptMapping[item.concept]].push({
          ...item,
          amount: item.packages?.reduce((i, elem) => i + elem, 0),
        })
      }

      try {
        dataRenewal.expirationDate = new Date(
          dataRenewal.services.newGoodOkCuotaToAdd[0].endDate +
            'T' +
            toLocaleISOString().slice(11, 19)
        )

        // Id del invoice para su actualización en la function
        // Llamamos a la function update-services.js a través de clients-service
        await updateServices(this.client.id, dataRenewal)
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('renewSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
        this.setLoading(false)
      }
    },
    async activateUpgrade(items) {
      if (!this.allowToPerformAction('activateInvoice')) {
        const message = this.$t('insuficientPermissions')
        this.setSnackbar({ position: 'top', type: 'error', message })
        return
      }
      if (!this.checkIfActivationAvailable(items)) return
      this.setLoading(true)
      // Estructura de datos para enviar a la function
      const dataUpdate = {
        invoiceIds: [],
        services: {
          newPackagesOfCifsToAdd: [],
          newPackagesOfNotificationsToAdd: [],
          newPackagesOfSignaturesToAdd: [],
          newPackagesOfCertificatesToAdd: [],
        },
      }

      var conceptMapping = {
        notificationsCuota: 'newPackagesOfCifsToAdd',
        notificationsPackages: 'newPackagesOfNotificationsToAdd',
        signaturesPackages: 'newPackagesOfSignaturesToAdd',
        signaturesCertsPackages: 'newPackagesOfCertificatesToAdd',
      }

      for (const item of items) {
        // Creamos un array de ids
        dataUpdate.invoiceIds.push(item.id)
        // En caso de incurrir en nuevas notificaciones, CIFs, firmas o certificados
        dataUpdate.services[conceptMapping[item.concept]].push({
          ...item,
          amount: item.packages?.reduce((i, elem) => i + elem, 0),
        })

        if (item.type === 'activation') {
          if (item.concept.includes('notifications'))
            dataUpdate.activateNotifications = true
          if (item.concept.includes('signatures'))
            dataUpdate.activateSignatures = true
          dataUpdate.upgradeServices = false
        } else dataUpdate.upgradeServices = true
      }
      try {
        // Id del invoice para su actualización en la function
        // Llamamos a la function update-services.js a través de clients-service
        await updateServices(this.client.id, dataUpdate)

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('upgradeSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
        this.setLoading(false)
      }
    },
    getNextActionButton(item) {
      switch (item.status) {
        case 'pendingBilling':
          return {
            title: 'goToPendingActivation',
            nextStatus: 'pendingActivation',
            allowedRoles: ['admin', 'billing'],
          }
        case 'pendingActivation':
          return {
            title: 'goToActive',
            nextStatus: 'active',
            allowedRoles: ['admin', 'support'],
          }
        case 'active':
          return {
            title: 'active',
            nextStatus: '',
            allowedRoles: ['admin', 'commercial', 'billing', 'support'],
          }
        default:
          return {
            title: 'goToPendingBilling',
            nextStatus: 'pendingBilling',
            allowedRoles: ['admin', 'commercial'],
          }
      }
    },
    checkIfActivationAvailable(items) {
      //comprueba en caso de necesitarse activar un nuevo servicio que se cumple que hay facturados los dos paquetes necesarios
      if (this.client.firmasActivas && this.client.notificacionesActivas) {
        return true
      } else {
        if (!this.client.firmasActivas) {
          const hasSignaturesCertsPendingActivation = this.hasConceptAndStatus(
            'signaturesCertsPackages',
            'pendingActivation',
            items
          )
          const hasSignaturesPackagesPendingActivation =
            this.hasConceptAndStatus(
              'signaturesPackages',
              'pendingActivation',
              items
            )
          if (
            hasSignaturesCertsPendingActivation ^
            hasSignaturesPackagesPendingActivation
          ) {
            this.setSnackbar({
              position: 'top',
              type: 'error',
              timeout: '15000',
              message:
                this.$t('signaturesUpgradeNotAvailable') +
                (!hasSignaturesCertsPendingActivation
                  ? this.$t('invoices.signaturesCertsPackages') + ', '
                  : '') +
                ' ' +
                (!hasSignaturesPackagesPendingActivation
                  ? this.$t('invoices.signaturesPackages')
                  : ''),
            })
            return false
          }
        }
        if (!this.client.notificacionesActivas) {
          const hasNotificationsCuotaPendingActivation =
            this.hasConceptAndStatus(
              'notificationsCuota',
              'pendingActivation',
              items
            )
          const hasNotificationsPackagesPendingActivation =
            this.hasConceptAndStatus(
              'notificationsPackages',
              'pendingActivation',
              items
            )
          if (
            hasNotificationsCuotaPendingActivation ^
            hasNotificationsPackagesPendingActivation
          ) {
            this.setSnackbar({
              position: 'top',
              type: 'error',
              timeout: '15000',
              message:
                this.$t('notificationsUpgradeNotAvailable') +
                (!hasNotificationsCuotaPendingActivation
                  ? this.$t('invoices.notificationsCuota') + ', '
                  : '') +
                ' ' +
                (!hasNotificationsPackagesPendingActivation
                  ? this.$t('invoices.notificationsPackages')
                  : ''),
            })
            return false
          }
        }
      }
      return true
    },
    deleteInvoice(item) {
      if (!this.allowToPerformAction('deleteInvoice')) {
        const message = this.$t('insuficientPermissions')
        this.setSnackbar({ position: 'top', type: 'error', message })
        return
      }
      updateInvoice(this.client.id, item.id, { archived: true })
    },
    restoreInvoice(item) {
      if (!this.allowToPerformAction('restoreInvoice')) {
        const message = this.$t('insuficientPermissions')
        this.setSnackbar({ position: 'top', type: 'error', message })
        return
      }
      updateInvoice(this.client.id, item.id, { archived: false })
    },
    openCommentDialog(item) {
      this.selectedInvoiceToCommentId = item.id
      this.commentInvoiceText = item.comment
      this.commentDialog = true
    },
    saveInvoiceComment() {
      updateInvoice(this.client.id, this.selectedInvoiceToCommentId, {
        comment: this.commentInvoiceText,
      })
      this.commentInvoiceText = ''
      this.commentDialog = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
