<template>
  <v-card
    v-if="isAddaliaUser"
    elevation="1"
    :class="{ selected: isSelected }"
    class="ma-4"
  >
    <v-container fluid>
      <v-row @click="toggleSelection" align="center" justify="space-between">
        <v-col cols="1">
          <v-icon color="primary" v-if="isSelected"> mdi-check-circle </v-icon>
          <v-icon id="termsHaveAccept" v-else>mdi-circle-outline</v-icon>

          <v-icon x-large> mdi-file-document-edit-outline </v-icon>
        </v-col>
        <v-col cols="11">
          <h2>{{ $t('legal.termsToAcceptTitle') }}</h2>
          <p>{{ $t('legal.termsToAcceptDescription') }}</p>
        </v-col>
      </v-row>

      <v-expand-transition class="blue">
        <v-row v-if="isSelected" justify="center" align="center">
          <v-col cols="12" md="6" lg="6">
            <v-select
              v-model="selectedContractId"
              :items="contractModelList"
              item-text="name"
              item-value="id"
              @change="contractModelEvent"
              :label="$t('legal.termsOfServiceModel')"
              prepend-icon="mdi-file-document-edit-outline"
              :rules="[notEmpty]"
              id="select-contractModel"
            ></v-select>
          </v-col>
          <v-btn
            :disabled="!selectedContract"
            @click="showPdf()"
            color="primary"
            class="ma-4"
          >
            {{ $t('legal.termsPdfView') }}
          </v-btn>
        </v-row>
      </v-expand-transition>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import rules from '@/utils/rules'
export default {
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
    contractModelMap: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      selectedContractId: '',
      selectedContract: '',
      contractModelList: [],
    }
  },
  computed: {
    ...mapGetters([
      'isAddaliaUser',
      'isCommercialUser',
      'isSupportUser',
      'isBillingUser',
      'isAddaliaAdminUser',
    ]),
  },
  created() {
    // Convertir el mapa a una lista de objetos y presentarla ordenada por nombre
    this.contractModelList = Object.keys(this.contractModelMap)
      .map((key) => {
        return {
          id: key,
          name: this.$t('legal.' + key),
          ...this.contractModelMap[key],
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    toggleSelection() {
      if (!this.isSelected) {
        this.selectedContractId = ''
        this.selectedContract = ''
      }
      this.$emit('selectTermsHaveAcceptEvent')
    },
    contractModelEvent() {
      this.selectedContract = this.contractModelList.find(
        (item) => item.id === this.selectedContractId
      )
      this.$emit('contractModel', this.selectedContract.id)
    },
    async showPdf() {
      if (this.selectedContract?.contractPdfURL) {
        window.open(this.selectedContract?.contractPdfURL, '_blank')
      } else {
        const message = this.$t('legal.termsPdfNotFound')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    updateContractNamei18n() {
      // Actualiza los elementos internacionalizados del select de modelos de contratos
      this.contractModelList.forEach((item) => {
        item.name = this.$t('legal.' + item.id)
      })
    },
  },
  watch: {
    '$i18n.locale'() {
      this.updateContractNamei18n()
    },
  },
}
</script>

<style scoped>
.selected {
  border: 1px solid var(--v-primary-base);
}
.v-expand-transition {
  transition: height 0.3s ease;
  overflow: hidden;
}
</style>
