import vuetify from '@/plugins/vuetify'
const getters = {
  //app getters
  isLoading(state) {
    return state.loading
  },
  isMobile() {
    return !vuetify.framework.breakpoint.mdAndUp
  },

  //user getters
  isUserSignedIn(state) {
    return !!state.user
  },
  isAddaliaUser(state) {
    return getters.isUserSignedIn(state) && state.user.type == 'addalia'
  },
  isAddaliaAdminUser(state) {
    return (
      getters.isUserSignedIn(state) &&
      getters.isAddaliaUser(state) &&
      state.user.rol == 'admin'
    )
  },
  isAddaliaCommercialUser(state) {
    return (
      getters.isUserSignedIn(state) &&
      getters.isAddaliaUser(state) &&
      state.user.rol == 'commercial'
    )
  },
  isMainUser(state) {
    return getters.isUserSignedIn(state) && !state.user.parentRef
  },
  isAdminUser(state) {
    return getters.isUserSignedIn(state) && state.user.rol == 'admin'
  },
  isCommercialUser(state) {
    return getters.isUserSignedIn(state) && state.user.rol == 'commercial'
  },
  isSupportUser(state) {
    return getters.isUserSignedIn(state) && state.user.rol == 'support'
  },
  isBillingUser(state) {
    return getters.isUserSignedIn(state) && state.user.rol == 'billing'
  },
  isMarketingUser(state) {
    return getters.isUserSignedIn(state) && state.user.rol == 'marketing'
  },
  isChannelUser(state) {
    return getters.isUserSignedIn(state) && !!state.user.addaliaRef
  },
  isAgentUser(state) {
    return getters.isUserSignedIn(state) && state.user.type == 'agent'
  },
  isDistributorUser(state) {
    return getters.isUserSignedIn(state) && state.user.type == 'distributor'
  },
  isWholesalerUser(state) {
    return getters.isUserSignedIn(state) && state.user.type == 'wholesaler'
  },
  isCollectiveUser(state) {
    return getters.isUserSignedIn(state) && state.user.type == 'collective'
  },
}
export default getters
