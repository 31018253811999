<template>
  <v-dialog :value="true" persistent :max-width="isCustomBrand ? 1500 : 600">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t('client.clientDetail.' + action) }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="closeDialog()"
          :disabled="loadingSave || loadingDelete"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-form
            v-if="is2FA"
            ref="multifactorForm"
            v-model="validMultifactorForm"
            lazy-validation
          >
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-switch
                          v-model="enable2FA"
                          inset
                          :label="$t('client.multiFactor.enable2FA')"
                        >
                        </v-switch>
                      </v-col>
                      <template v-if="enable2FA">
                        <v-row v-for="(item, i) in multiFactor" :key="i">
                          <v-col cols="10">
                            <v-text-field
                              id="phone-input"
                              :disabled="loadingSave"
                              v-model="multiFactor[i]"
                              :label="$t('client.multiFactor.phone')"
                              :hint="$t('hintPhone')"
                              prepend-icon="mdi-cellphone-basic"
                              :rules="mobilePhoneRules"
                              dense
                              autocomplete="false"
                              @keydown.space.prevent
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-btn
                              elevation="0"
                              icon
                              rounded
                              @click="removeMultifactor(i)"
                              v-if="multiFactor.length > 1"
                            >
                              <v-icon>mdi-minus-circle-outline</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-col
                          cols="12"
                          v-if="enable2FA && multiFactor.length < 5"
                        >
                          <v-btn
                            elevation="2"
                            outlined
                            rounded
                            small
                            @click="addAnotherMultifactor()"
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('client.multiFactor.addAnotherPhone') }}
                          </v-btn>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-container v-else-if="isCustomBrand">
            <v-row justify="center">
              <v-col cols="12" md="3">
                <v-file-input
                  accept="image/*"
                  :label="$t('client.customBrand.appLogo')"
                  @change="onAppLogoFileSelected"
                  :clearable="false"
                >
                </v-file-input>
                <v-img
                  contain
                  max-height="175px"
                  class="uploaded-image pa-2"
                  :src="getImageSource('app-logo')"
                  alt="Logo de aplicación"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-file-input
                  accept="image/*"
                  :label="$t('client.customBrand.loginLogo')"
                  @change="onLoginLogoFileSelected"
                  :clearable="false"
                >
                </v-file-input>
                <v-img
                  contain
                  max-height="300px"
                  :src="getImageSource('login-logo')"
                  alt="Logo de inicio de sesión"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-file-input
                  accept="image/*"
                  :label="$t('client.customBrand.logoTour')"
                  @change="onTourLogoFileSelected"
                  :clearable="false"
                >
                </v-file-input>
                <v-img
                  contain
                  max-height="150px"
                  :src="getImageSource('tour-logo')"
                  alt="Logo de tour"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-file-input
                  accept="image/*"
                  :label="$t('client.customBrand.logoEmail')"
                  @change="onMailLogoFileSelected"
                  :clearable="false"
                >
                </v-file-input>
                <v-img
                  contain
                  max-height="400px"
                  :src="getImageSource('mail-logo')"
                  alt="Logo de email"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="auto">
                {{ $t('client.customBrand.mainColor') }}
                <v-color-picker
                  v-model="colorPickerPrimary"
                  mode="hexa"
                  class="ma-2"
                  hide-canvas
                  show-swatches
                  swatches-max-height="75px"
                >
                </v-color-picker>
              </v-col>
              <v-col cols="auto">
                {{ $t('client.customBrand.secondaryColor') }}
                <v-color-picker
                  v-model="colorPickerSecondary"
                  mode="hexa"
                  class="ma-2"
                  hide-canvas
                  show-swatches
                  swatches-max-height="75px"
                >
                </v-color-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="isCustomBrand"
          text
          color="error"
          @click="performDelete"
          :loading="loadingDelete"
          :disabled="loadingSave || loadingDelete"
        >
          {{ $t('client.customBrand.delete') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="performAction"
          :loading="loadingSave"
          :disabled="
            loadingSave ||
            loadingDelete ||
            (isCustomBrand &&
              (!selectedImageAppLogoURL ||
                !selectedImageLoginLogoURL ||
                !selectedImageMailLogoURL ||
                !selectedImageTourLogoURL))
          "
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapMutations } from 'vuex'
import { updateMultifactor, saveCustomBrand } from '@/services/clients-service'
import {
  uploadBrandLogo,
  getFileURL,
  removeBrand,
} from '@/services/storage-service'
import getErrorText from '@/utils/get-error-text'

export default {
  props: {
    userId: String,
    action: String,
    args: [Object, Array],
  },
  mounted() {
    if (this.is2FA) {
      this.enable2FA = this.args ? true : false
      this.multiFactor = this.enable2FA ? [...this.args] : ['']
    } else if (this.isCustomBrand && this.args) {
      this.selectedImageAppLogoURL = this.args.appLogo
      this.selectedImageLoginLogoURL = this.args.loginLogo
      this.selectedImageMailLogoURL = this.args.mailLogo
      this.selectedImageTourLogoURL = this.args.tourLogo
      this.colorPickerPrimary = this.args.primaryColor
      this.colorPickerSecondary = this.args.secondaryColor
    }
  },
  data() {
    return {
      loadingSave: false,
      loadingDelete: false,
      // 2FA
      enable2FA: false,
      multiFactor: [],
      mobilePhoneRules: [rules.required, rules.mobilePhoneNumber],
      validMultifactorForm: false,
      // Custom Brand
      selectedImageAppLogo: null,
      selectedImageAppLogoURL: null,
      selectedImageLoginLogo: null,
      selectedImageLoginLogoURL: null,
      selectedImageMailLogo: null,
      selectedImageMailLogoURL: null,
      selectedImageTourLogo: null,
      selectedImageTourLogoURL: null,
      colorPickerPrimary: '#1D9EA4',
      colorPickerSecondary: '#FFFFFF',
    }
  },
  computed: {
    isCustomBrand() {
      return this.action === 'setupCustomBrand'
    },
    is2FA() {
      return this.action === 'setup2FA'
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    addAnotherMultifactor() {
      this.multiFactor.push('')
    },
    removeMultifactor(index) {
      this.multiFactor.splice(index, 1)
    },
    getImageSource(type) {
      switch (type) {
        case 'app-logo':
          return (
            this.selectedImageAppLogoURL ||
            require('@/assets/client-app-logos/app-logo.png')
          )
        case 'login-logo':
          return (
            this.selectedImageLoginLogoURL ||
            require('@/assets/client-app-logos/login-logo.png')
          )
        case 'mail-logo':
          return (
            this.selectedImageMailLogoURL ||
            require('@/assets/client-app-logos/mail-logo.png')
          )
        case 'tour-logo':
          return (
            this.selectedImageTourLogoURL ||
            require('@/assets/client-app-logos/tour-logo.png')
          )
      }
    },
    onAppLogoFileSelected(file) {
      this.selectedImageAppLogo = file
      this.selectedImageAppLogoURL = URL.createObjectURL(file)
    },
    onLoginLogoFileSelected(file) {
      this.selectedImageLoginLogo = file
      this.selectedImageLoginLogoURL = URL.createObjectURL(file)
    },
    onMailLogoFileSelected(file) {
      this.selectedImageMailLogo = file
      this.selectedImageMailLogoURL = URL.createObjectURL(file)
    },
    onTourLogoFileSelected(file) {
      this.selectedImageTourLogo = file
      this.selectedImageTourLogoURL = URL.createObjectURL(file)
    },

    async getUploadLogoURL(type) {
      const uploadRef = (
        await uploadBrandLogo(
          this.userId,
          type,
          type === 'app-logo'
            ? this.selectedImageAppLogo
            : type === 'login-logo'
            ? this.selectedImageLoginLogo
            : type === 'mail-logo'
            ? this.selectedImageMailLogo
            : this.selectedImageTourLogo
        )
      ).metadata.fullPath

      return await getFileURL(uploadRef)
    },
    async performAction() {
      if (this.is2FA) {
        if (!this.$refs.multifactorForm?.validate()) return
        try {
          this.loadingSave = true

          await updateMultifactor(
            this.userId,
            this.enable2FA ? this.multiFactor : null
          )

          const message = this.$t('client.multiFactor.updateSuccess')
          this.setSnackbar({ position: 'top', type: 'success', message })

          this.closeDialog()
          this.loadingSave = false
        } catch (error) {
          this.loadingSave = false
          const message = getErrorText(error.message)
          this.setSnackbar({ position: 'top', type: 'error', message })
        }
      } else if (this.isCustomBrand) {
        if (
          !this.selectedImageAppLogoURL ||
          !this.selectedImageLoginLogoURL ||
          !this.selectedImageMailLogoURL ||
          !this.selectedImageTourLogoURL
        )
          return

        try {
          this.loadingSave = true

          const customBrand = {
            primaryColor: this.colorPickerPrimary,
            secondaryColor: this.colorPickerSecondary,
            appLogo:
              this.args?.appLogo !== this.selectedImageAppLogoURL
                ? await this.getUploadLogoURL('app-logo')
                : this.selectedImageAppLogoURL,
            loginLogo:
              this.args?.loginLogo !== this.selectedImageLoginLogoURL
                ? await this.getUploadLogoURL('login-logo')
                : this.selectedImageLoginLogoURL,
            mailLogo:
              this.args?.mailLogo !== this.selectedImageMailLogoURL
                ? await this.getUploadLogoURL('mail-logo')
                : this.selectedImageMailLogoURL,
            tourLogo:
              this.args?.tourLogo !== this.selectedImageTourLogoURL
                ? await this.getUploadLogoURL('tour-logo')
                : this.selectedImageTourLogoURL,
          }

          await saveCustomBrand(this.userId, customBrand)

          const message = this.$t('client.customBrand.saveSuccess')
          this.setSnackbar({
            position: 'top',
            type: 'success',
            message,
          })

          this.closeDialog()
          this.loadingSave = false
        } catch (err) {
          this.loadingSave = false
          const message = this.$t('client.customBrand.saveError')
          this.setSnackbar({
            position: 'top',
            type: 'error',
            message,
          })
        }
      }
    },
    async performDelete() {
      if (this.isCustomBrand) {
        try {
          this.loadingDelete = true
          await saveCustomBrand(this.userId, null)
          await removeBrand(this.userId)

          const message = this.$t('client.customBrand.deleteSuccess')
          this.setSnackbar({
            position: 'top',
            type: 'success',
            message,
          })

          this.closeDialog()
        } catch (err) {
          this.loadingDelete = false
          const message = this.$t('client.customBrand.deleteError')
          this.setSnackbar({
            position: 'top',
            type: 'error',
            message,
          })
        }
      }
    },
    closeDialog() {
      this.$emit('closeSetupDialog')
    },
  },
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}

.uploaded-image {
  background-color: #cfd8dcff;
}
</style>
