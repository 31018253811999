<template>
  <v-card class="mx-auto" outlined style="overflow-y: auto">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('customization.customBrand.title') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('customization.customBrand.subtitle') }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="primary secondary--text"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="clickEditUserInfo"
            :disabled="loadingSave || loadingDelete"
          >
            <v-icon v-if="isEditingUserInfo"> mdi-close </v-icon>
            <v-icon v-else> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span v-if="isEditingUserInfo">
          {{ $t('customization.editCancel') }}
        </span>
        <span v-else>{{ $t('customization.editInformation') }}</span>
      </v-tooltip>
    </v-list-item>
    <v-card-text v-if="isEditingUserInfo">
      <v-col cols="12">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="3">
              <v-file-input
                accept="image/*"
                :label="$t('customization.customBrand.appLogo')"
                @change="onAppLogoFileSelected"
                :clearable="false"
              >
              </v-file-input>
              <v-img
                contain
                max-height="175px"
                class="uploaded-image pa-2"
                :src="getImageSource('app-logo')"
                alt="Logo de aplicación"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-file-input
                accept="image/*"
                :label="$t('customization.customBrand.loginLogo')"
                @change="onLoginLogoFileSelected"
                :clearable="false"
              >
              </v-file-input>
              <v-img
                contain
                max-height="300px"
                :src="getImageSource('login-logo')"
                alt="Logo de inicio de sesión"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-file-input
                accept="image/*"
                :label="$t('customization.customBrand.logoTour')"
                @change="onTourLogoFileSelected"
                :clearable="false"
              >
              </v-file-input>
              <v-img
                contain
                max-height="150px"
                :src="getImageSource('tour-logo')"
                alt="Logo de tour"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-file-input
                accept="image/*"
                :label="$t('customization.customBrand.logoEmail')"
                @change="onMailLogoFileSelected"
                :clearable="false"
              >
              </v-file-input>
              <v-img
                contain
                max-height="400px"
                :src="getImageSource('mail-logo')"
                alt="Logo de email"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              {{ $t('customization.customBrand.mainColor') }}
              <v-color-picker
                v-model="colorPickerPrimary"
                mode="hexa"
                class="ma-2"
                hide-canvas
                show-swatches
                swatches-max-height="75px"
              >
              </v-color-picker>
            </v-col>
            <v-col cols="auto">
              {{ $t('customization.customBrand.secondaryColor') }}
              <v-color-picker
                v-model="colorPickerSecondary"
                mode="hexa"
                class="ma-2"
                hide-canvas
                show-swatches
                swatches-max-height="75px"
              >
              </v-color-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="isEditingUserInfo">
      <v-btn
        v-if="this.customBrand"
        text
        color="error"
        @click="performDelete"
        :loading="loadingDelete"
        :disabled="loadingSave || loadingDelete"
      >
        {{ $t('customization.customBrand.delete') }}
        <template v-slot:loader>
          <v-progress-circular indeterminate size="20" width="2" />
        </template>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="performAction"
        :loading="loadingSave"
        :disabled="
          loadingSave ||
          loadingDelete ||
          !selectedImageAppLogoURL ||
          !selectedImageLoginLogoURL ||
          !selectedImageMailLogoURL ||
          !selectedImageTourLogoURL
        "
      >
        {{ $t('confirm') }}
        <template v-slot:loader>
          <v-progress-circular indeterminate size="20" width="2" />
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { saveCustomBrand } from '@/services/users-service'
import {
  uploadBrandLogo,
  getFileURL,
  removeBrand,
} from '@/services/storage-service'

export default {
  props: {
    customBrand: Object,
  },
  mounted() {
    this.loadCustomBrand()
  },
  data() {
    return {
      isEditingUserInfo: false,
      loadingSave: false,
      loadingDelete: false,
      selectedImageAppLogo: null,
      selectedImageAppLogoURL: null,
      selectedImageLoginLogo: null,
      selectedImageLoginLogoURL: null,
      selectedImageMailLogo: null,
      selectedImageMailLogoURL: null,
      selectedImageTourLogo: null,
      selectedImageTourLogoURL: null,
      colorPickerPrimary: '#1D9EA4',
      colorPickerSecondary: '#FFFFFF',
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    loadCustomBrand() {
      if (this.customBrand) {
        this.selectedImageAppLogoURL = this.customBrand.appLogo
        this.selectedImageLoginLogoURL = this.customBrand.loginLogo
        this.selectedImageMailLogoURL = this.customBrand.mailLogo
        this.selectedImageTourLogoURL = this.customBrand.tourLogo
        this.colorPickerPrimary = this.customBrand.primaryColor
        this.colorPickerSecondary = this.customBrand.secondaryColor
      } else {
        this.selectedImageAppLogo = null
        this.selectedImageAppLogoURL = null
        this.selectedImageLoginLogo = null
        this.selectedImageLoginLogoURL = null
        this.selectedImageMailLogo = null
        this.selectedImageMailLogoURL = null
        this.selectedImageTourLogo = null
        this.selectedImageTourLogoURL = null
        this.colorPickerPrimary = '#1D9EA4'
        this.colorPickerSecondary = '#FFFFFF'
      }
    },
    clickEditUserInfo() {
      this.isEditingUserInfo = !this.isEditingUserInfo
      this.loadCustomBrand()
    },
    getImageSource(type) {
      switch (type) {
        case 'app-logo':
          return (
            this.selectedImageAppLogoURL ||
            require('@/assets/client-app-logos/app-logo.png')
          )
        case 'login-logo':
          return (
            this.selectedImageLoginLogoURL ||
            require('@/assets/client-app-logos/login-logo.png')
          )
        case 'mail-logo':
          return (
            this.selectedImageMailLogoURL ||
            require('@/assets/client-app-logos/mail-logo.png')
          )
        case 'tour-logo':
          return (
            this.selectedImageTourLogoURL ||
            require('@/assets/client-app-logos/tour-logo.png')
          )
      }
    },
    onAppLogoFileSelected(file) {
      this.selectedImageAppLogo = file
      this.selectedImageAppLogoURL = URL.createObjectURL(file)
    },
    onLoginLogoFileSelected(file) {
      this.selectedImageLoginLogo = file
      this.selectedImageLoginLogoURL = URL.createObjectURL(file)
    },
    onMailLogoFileSelected(file) {
      this.selectedImageMailLogo = file
      this.selectedImageMailLogoURL = URL.createObjectURL(file)
    },
    onTourLogoFileSelected(file) {
      this.selectedImageTourLogo = file
      this.selectedImageTourLogoURL = URL.createObjectURL(file)
    },

    async getUploadLogoURL(type) {
      const uploadRef = (
        await uploadBrandLogo(
          this.$store.state.user.id,
          type,
          type === 'app-logo'
            ? this.selectedImageAppLogo
            : type === 'login-logo'
            ? this.selectedImageLoginLogo
            : type === 'mail-logo'
            ? this.selectedImageMailLogo
            : this.selectedImageTourLogo,
          true
        )
      ).metadata.fullPath

      return await getFileURL(uploadRef)
    },
    async performAction() {
      if (
        !this.selectedImageAppLogoURL ||
        !this.selectedImageLoginLogoURL ||
        !this.selectedImageMailLogoURL ||
        !this.selectedImageTourLogoURL
      )
        return

      try {
        this.loadingSave = true
        const customBrand = {
          primaryColor: this.colorPickerPrimary,
          secondaryColor: this.colorPickerSecondary,
          appLogo:
            this.customBrand?.appLogo !== this.selectedImageAppLogoURL
              ? await this.getUploadLogoURL('app-logo')
              : this.selectedImageAppLogoURL,
          loginLogo:
            this.customBrand?.loginLogo !== this.selectedImageLoginLogoURL
              ? await this.getUploadLogoURL('login-logo')
              : this.selectedImageLoginLogoURL,
          mailLogo:
            this.customBrand?.mailLogo !== this.selectedImageMailLogoURL
              ? await this.getUploadLogoURL('mail-logo')
              : this.selectedImageMailLogoURL,
          tourLogo:
            this.customBrand?.tourLogo !== this.selectedImageTourLogoURL
              ? await this.getUploadLogoURL('tour-logo')
              : this.selectedImageTourLogoURL,
        }
        await saveCustomBrand(this.$store.state.user.id, customBrand)

        const message = this.$t('customization.customBrand.saveSuccess')
        this.setSnackbar({
          position: 'top',
          type: 'success',
          message,
        })

        this.clickEditUserInfo()
        this.loadingSave = false
      } catch (err) {
        this.loadingSave = false
        const message = this.$t('customization.customBrand.saveError')
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
    async performDelete() {
      try {
        this.loadingDelete = true
        await saveCustomBrand(this.$store.state.user.id, null)
        await removeBrand(this.$store.state.user.id, true)

        const message = this.$t('customization.customBrand.deleteSuccess')
        this.setSnackbar({
          position: 'top',
          type: 'success',
          message,
        })

        this.clickEditUserInfo()
      } catch (err) {
        this.loadingDelete = false
        const message = this.$t('customization.customBrand.deleteError')
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      }
    },
  },
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}

.uploaded-image {
  background-color: #cfd8dcff;
}
</style>
