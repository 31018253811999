import routes from '@/router/routes'
import store from '@/store'

export default function () {
  var navPages = {
    dashboard: undefined,
    operators: undefined,
    filters: undefined,
    cifs: undefined,
    notifications: undefined,
    clients: undefined,
    signatures: {
      /* need to be declared in case it has childs*/
      title: 'signatures',
      icon: 'mdi-signature-freehand',
      color: 'secondary',
      disabled: true,
      available: false,
      subPages: [],
      component: 'AppBarItemMenu',
    },
    manageNews: undefined,
    documentation: undefined,
    automatedTasks: undefined,
    news:
      store.getters.isMobile ||
      store.getters.isDemoEnv ||
      store.getters.isAddaliaUser
        ? undefined
        : {
            icon: 'mdi-bell',
            disabled: false,
            available: true,
            includeBadge: true,
            component: 'NewsList',
          },
    profile: {
      title: 'profile',
      icon: 'mdi-account-cog-outline',
      disabled: true,
      available: false,
      subPages: [],
      includeBadge: true,
      component: 'AppBarItemMenu',
    },
    configuration: {
      title: 'profile',
      icon: 'mdi-account-cog-outline',
      disabled: true,
      available: false,
      subPages: [],
      includeBadge: true,
      // component: 'Configuration',
      component: 'NewsList',
    },
  }

  routes.forEach((routeInfo) => {
    const userCanAccess = routeInfo.meta.userCanAccess
    var navigationConfig = []
    navigationConfig = routeInfo.meta.navigationConfig()
    navigationConfig.forEach((navItem) => {
      const valueToPush = {
        title: navItem.title,
        component: navItem.component,
        icon: navItem.icon,
        path: navItem.path || routeInfo.path,
        routerData: navItem.routerData || { name: routeInfo.name },
        disabled: false,
        includeBadge: !!navItem.includeBadge,
        includeIcon: !!navItem.includeIcon,
        includeTitle: !!navItem.includeTitle,
        color: navItem.color ? navItem.color : 'secondary',
      }
      valueToPush.available = userCanAccess()

      if (!navItem.parent) navPages[valueToPush.title] = valueToPush
      else {
        if (valueToPush.available) navPages[navItem.parent].available = true
        navPages[navItem.parent].subPages.push(valueToPush)
      }
    })
  })

  Object.keys(navPages).forEach(
    (k) => (!navPages[k] || k === 'undefined') && delete navPages[k]
  )

  return navPages
}
