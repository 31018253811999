<template>
  <v-container fill-height fluid class="text-center">
    <v-row justify="center" align="center" class="mb-n1">
      <v-col cols="12">
        <h1 class="mt-n5 mb-n5 text-center">
          {{ $t('priceConfiguration') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-n1">
      <v-col cols="6" md="4">
        <v-tabs
          v-model="userTab"
          background-color="transparent"
          color="primary"
          grow
        >
          <v-tab disabled> {{ $t('wholesaler') }} </v-tab>
          <v-tab> {{ $t('general') }} </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="6" md="4">
        <v-tabs
          v-model="companyTab"
          background-color="transparent"
          color="orange"
          grow
        >
          <v-tab> {{ $t('empresa') }} </v-tab>
          <v-tab> {{ $t('asesoria') }} </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" class="mt-n1">
      <v-col cols="8">
        <v-card>
          <v-tabs vertical>
            <v-tab>
              <v-icon left> mdi-account </v-icon>
              {{ $t('client.cuotaCard.cardTitle') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-email </v-icon>
              {{ $t('cifsNotifications') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-email </v-icon>
              {{ $t('packagesNotifications') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-draw </v-icon>
              {{ $t('certificatesSignatures') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-draw </v-icon>
              {{ $t('packagesSignatures') }}
            </v-tab>
            <v-tab>
              <v-icon left> mdi-security </v-icon>
              {{ $t('additionalServices') }}
            </v-tab>

            <v-tab-item class="pa-5">
              <CuotaCard
                v-show="companyTab == 0"
                :price="billing.empresaAppCuota"
                :isConfiguration="true"
                :companyType="companyTab == 0 ? 'EMPRESA' : 'ASESORIA'"
                :showTitle="false"
              />
              <CuotaCard
                v-show="companyTab == 1"
                :price="billing.despachoAppCuota"
                :isConfiguration="true"
                :companyType="companyTab == 0 ? 'EMPRESA' : 'ASESORIA'"
                :showTitle="false"
              />
            </v-tab-item>
            <v-tab-item class="pa-5">
              <v-card flat>
                <CuotaLicenses
                  v-show="companyTab == 0"
                  :label="$t('numberOfCifs')"
                  :cuotaServicio="billing.empresaNotificacionesCif"
                  :isConfiguration="true"
                  :companyType="companyTab == 0 ? 'EMPRESA' : 'ASESORIA'"
                />
                <CuotaLicenses
                  v-show="companyTab == 1"
                  :label="$t('numberOfCifs')"
                  :cuotaServicio="billing.despachoNotificacionesCif"
                  :isConfiguration="true"
                  :companyType="companyTab == 0 ? 'EMPRESA' : 'ASESORIA'"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <div v-show="companyTab == 0">
                <PackagesLicenses
                  :prices="billing.empresaNotificacionesPaquetesCuota"
                  :packageList="billing.empresaNotificacionesPaquetes"
                  :isConfiguration="true"
                  resourceName="empresaNotificacionesPaquetesCuota"
                />
              </div>
              <div v-show="companyTab == 1">
                <PackagesLicenses
                  :prices="billing.despachoNotificacionesPaquetesCuota"
                  :packageList="billing.despachoNotificacionesPaquetes"
                  :isConfiguration="true"
                  resourceName="despachoNotificacionesPaquetesCuota"
                />
              </div>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <div v-show="companyTab == 0">
                <PackagesLicenses
                  :prices="billing.empresaFirmasCertificadosCuota"
                  :packageList="billing.empresaFirmasCertificados"
                  :isConfiguration="true"
                  resourceName="empresaFirmasCertificadosCuota"
                />
              </div>
              <div v-show="companyTab == 1">
                <PackagesLicenses
                  :prices="billing.despachoFirmasCertificadosCuota"
                  :packageList="billing.despachoFirmasCertificados"
                  :isConfiguration="true"
                  resourceName="despachoFirmasCertificadosCuota"
                />
              </div>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <div v-show="companyTab == 0">
                <PackagesLicenses
                  :prices="billing.empresaFirmasPaquetesCuota"
                  :packageList="billing.empresaFirmasPaquetes"
                  :isConfiguration="true"
                  resourceName="empresaFirmasPaquetesCuota"
                />
              </div>
              <div v-show="companyTab == 1">
                <PackagesLicenses
                  :prices="billing.despachoFirmasPaquetesCuota"
                  :packageList="billing.despachoFirmasPaquetes"
                  :isConfiguration="true"
                  resourceName="despachoFirmasPaquetesCuota"
                />
              </div>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <div v-show="companyTab == 0">
                <AdditionalServices
                  :multiFactorPrice="billing.empresa2FACuota"
                  :customBrandPrice="billing.empresaBrandCuota"
                  :isConfiguration="true"
                  companyType="EMPRESA"
                />
              </div>
              <div v-show="companyTab == 1">
                <AdditionalServices
                  :multiFactorPrice="billing.despacho2FACuota"
                  :customBrandPrice="billing.despachoBrandCuota"
                  :isConfiguration="true"
                  companyType="ASESORIA"
                />
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CuotaCard from '@/components/client/createClient/CuotaCard.vue'
import CuotaLicenses from '@/components/client/createClient/CuotaLicenses.vue'
import PackagesLicenses from '@/components/client/createClient/PackagesLicenses.vue'
import AdditionalServices from '@/components/client/createClient/AdditionalServices.vue'

export default {
  props: {
    billing: Object,
  },
  components: {
    CuotaCard,
    CuotaLicenses,
    PackagesLicenses,
    AdditionalServices,
  },
  data() {
    return {
      selectedPlan: 0,
      CIFsCuotaPrice: 0,
      confirmLoading: false,
      packageType: undefined,
      addNotificationsPackage: false,
      addSignaturesPackage: false,
      addCertificatesPackage: false,
      NotificationsPackagePrice: 0,
      signaturesCertsPackagesPrice: 0,
      companyTab: 0,
      userTab: 1,
    }
  },

  methods: {
    toggleSelection() {
      this.$emit('selectProductNotificationsEvent')
    },
    selectPlan(data) {
      this.selectedPlan = data.id
    },
    notificationsPackagesEvent(newValue) {
      this.NotificationsPackagePrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('notificationsPackages', newValue)
    },
    notificationsCuotaEvent(newValue) {
      this.CIFsCuotaPrice = Number(newValue.price - newValue.discountAmount)
      newValue.packages = [parseInt(newValue.amount)]
      this.$emit('notificationsCuota', newValue)
    },
    signaturesCertsPackagesEvent(newValue) {
      this.signaturesCertsPackagesPrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('signaturesCertsPackages', newValue)
    },
    signaturesPackagesEvent(newValue) {
      this.signaturesPackagesPrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('signaturesPackages', newValue)
    },
    goodOkCuotaEvent(newValue) {
      this.goodOkCuota = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tabs-items.full-height-tab .v-window-item {
  height: calc(100vh - 270px); /* adjust 270px to suits your needs */
  overflow-y: auto;
}
</style>
