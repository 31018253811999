<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col
        cols="12"
        :md="isConfiguration ? 9 : 5"
        :sm="isConfiguration ? 9 : 5"
      >
        <v-card
          :elevation="elevation"
          min-height="294"
          class="pl-2 pt-5 pb-2 pr-2"
        >
          <h2 v-if="!isConfiguration" class="mt-4 mb-4">
            {{ label }}
          </h2>
          <h1 class="mt-4 mb-4">{{ cuotaServicio }}€</h1>
          <v-icon x-large>mdi-account</v-icon>

          <v-row v-if="isConfiguration" justify="center">
            <v-col cols="6">
              <v-text-field
                v-model="cuotaServicioModel"
                :rules="[notEmptyNumber]"
                :placeholder="$t('price') + '(€)'"
              >
                <!-- :placeholder="
                  $t('price') + ' ' + '(' + cuotaServicioChild + '€)'
                " -->
                €
              </v-text-field>
            </v-col>
          </v-row>
          <v-card-text>
            {{ $t('client.productNotificationsCard.timeSub') }}
          </v-card-text>
          <v-btn
            v-if="checkButtonRulesComputed && isConfiguration"
            @click.stop="changeCuotaPrice()"
            :disabled="confirmLoading"
            :loading="confirmLoading"
            class="ma-0 pa-0"
            plain
          >
            {{ $t('client.customBrand.save') }}
          </v-btn>
        </v-card>
      </v-col>
      <v-col v-if="!isConfiguration" cols="12" md="7" sm="7">
        <v-row justify="center" align="center">
          <v-col :cols="cifsAmount ? 4 : 12">
            <v-text-field
              v-model="cifsAmount"
              dense
              :rules="minimumAmountRule"
              :label="$t('numberCIFtoHire')"
              type="number"
              id="number-of-cifs"
            >
            </v-text-field>
          </v-col>
          <v-col v-if="cifsAmount" cols="4">
            <v-text-field
              :disabled="isChannelUser"
              @focus="selectedDiscountType = 'percentage'"
              v-model="cifsDiscount"
              dense
              :label="$t('client.cuotaCard.discount')"
              type="number"
              max="100"
              min="1"
              suffix="%"
            ></v-text-field>
          </v-col>
          <v-col v-if="cifsAmount" cols="4">
            <v-text-field
              :disabled="isChannelUser"
              @focus="selectedDiscountType = 'amount'"
              v-model="cifsDiscountAmount"
              dense
              type="number"
              suffix="€"
              :label="$t('client.cuotaCard.discount')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="cifsAmount" justify="center" align="center">
          <v-slider
            :disabled="isChannelUser"
            @change="selectedDiscountType = 'bar'"
            v-if="cifsAmount"
            :max="cifsAmount * cuotaServicio"
            min="0"
            v-model="cifsDiscountAmount"
            :label="$t('client.cuotaCard.discount')"
            :thumb-color="$vuetify.theme.dark ? 'white' : 'black'"
            :thumb-size="50"
          >
            <template v-slot:thumb-label="{}">
              {{ $n(cifsDiscount, 'i18nAmount') }}%
            </template>
          </v-slider>
        </v-row>
        <v-row v-if="cifsAmount" justify="center" align="center">
          <h2 class="mt-4 mb-4">
            <span class="cifs-label">
              {{ $t('client.cuotaLicenses.finalPrice') }}
            </span>
            <br />
            <span v-if="cifsDiscountAmount > 0">
              <span class="cifs-info">
                <span class="cifs-value">
                  {{ $n(cuotaServicio * cifsAmount, 'i18nAmount') }}
                </span>
                <span class="cifs-currency">€</span>
              </span>
              -
              <span class="cifs-discount">
                {{ $n(cifsDiscountAmount, 'i18nAmount') }}€ ({{
                  $n(cifsDiscount, 'i18nAmount')
                }}%)
              </span>
              =
            </span>

            <span class="cifs-total">
              {{
                $n(
                  finalPriceComputed.price - finalPriceComputed.discountAmount,
                  'i18nAmount'
                )
              }}
              €
            </span>
            <v-btn @click="cifsAmount = minimumAmount" v-if="!!cifsAmount" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </h2>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import { updateBilling } from '@/services/billing-service'

export default {
  props: {
    cuotaServicio: {
      type: Number,
    },
    resultEventName: {
      type: String,
    },
    label: {
      type: String,
    },
    initialAmount: {
      type: Number,
      default: 1, // establecer inicialmente a 1 CIF para ser coherente con la info posterior de consumo, que indicaba 1 / 0
    },
    minimumAmount: {
      type: Number,
      default: 1, // establecer el minimo a 1 CIF, el de la propia empresa
    },
    fixedDiscount: {
      type: Number,
      default: 0,
    },
    elevation: {
      type: Number,
      default: 1,
    },
    isConfiguration: {
      type: Boolean,
      default: false,
    },
    companyType: {
      type: String,
    },
  },
  async created() {
    this.cifsAmount = this.initialAmount
    this.cuotaServicioModel = this.cuotaServicio
  },
  data() {
    return {
      //cuota Notificaciones
      cuotaServicioModel: this.cuotaServicio,
      cifsAmount: 0, //numero de cifs contratados
      cifsDiscount: 0, //porcentaje de descuento
      cifsDiscountAmount: 0, //valor economico del descuento €€€
      selectedDiscountType: 'bar',
      minimumAmountRule: [
        () =>
          this.cifsAmount >= this.minimumAmount || this.$t('lowerRenewalError'),
      ],
      swapPrices: true,
      swapWholesaler: false,
      confirmLoading: false,
    }
  },
  methods: {
    ...rules,
    ...mapMutations(['setSnackbar']),
    triggerNotRenewalLogic() {
      this.$emit('doNotRenewNotifications')
    },
    async changeCuotaPrice() {
      try {
        this.confirmLoading = true
        let dataUpdate = {}
        if (this.companyType == 'EMPRESA') {
          dataUpdate = {
            empresaNotificacionesCif: parseInt(this.cuotaServicioModel),
          }
        } else if (this.companyType == 'ASESORIA') {
          dataUpdate = {
            despachoNotificacionesCif: parseInt(this.cuotaServicioModel),
          }
        } else {
          const message = this.$t('updatePriceError')
          this.setSnackbar({ position: 'top', type: 'error', message })
        }

        await updateBilling(dataUpdate)
        const message = this.$t('updatePriceSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = this.$t('updatePriceError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
      }
    },
  },
  watch: {
    cifsDiscountAmount: function (newAmount) {
      if (
        this.selectedDiscountType === 'amount' ||
        this.selectedDiscountType === 'bar'
      ) {
        // Verificar si el nuevo valor es un número válido
        if (!isNaN(newAmount) && newAmount !== null) {
          // Calcular el descuento como el porcentaje del monto sobre el valor total
          this.cifsDiscount =
            (newAmount / (this.cifsAmount * this.cuotaServicio)) * 100
        } else {
          // Si el valor introducido no es un número válido, establecer el descuento como null
          this.cifsDiscount = 0
        }
      }
    },
    cifsDiscount: function (newAmount) {
      if (this.selectedDiscountType === 'percentage') {
        if (!isNaN(newAmount) && newAmount !== null) {
          this.cifsDiscountAmount =
            (newAmount / 100) * (this.cifsAmount * this.cuotaServicio)
        } else {
          // Si el valor introducido no es un número válido, establecer el descuento como null
          this.cifsDiscountAmount = 0
        }
      }
    },
    finalPriceComputed(newValue) {
      // Emitir el evento con el nuevo valor de la computed property
      if (!this.isConfiguration) {
        this.$emit(this.resultEventName, newValue)
      }
    },
    cifsAmount() {
      this.cifsDiscount = this.fixedDiscount > 0 ? this.fixedDiscount : 0
      this.cifsDiscountAmount =
        (this.cuotaServicio * this.cifsAmount * this.cifsDiscount) / 100
    },
  },
  computed: {
    ...mapGetters(['isChannelUser']),
    checkButtonRulesComputed() {
      return (
        this.cuotaServicioModel !== '' &&
        this.cuotaServicioModel !== undefined &&
        this.cuotaServicioModel !== null &&
        this.cuotaServicioModel != this.cuotaServicio &&
        this.cuotaServicioModel > 0
      )
    },
    finalPriceComputed() {
      return {
        concept: this.resultEventName,
        amount: this.cifsAmount,
        price: this.cuotaServicio * this.cifsAmount,
        discountAmount: this.cifsDiscountAmount,
        discountPercentage: this.cifsDiscount,
        packages: [parseInt(this.cifsAmount)],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cifs-label {
  font-size: 1.2rem;
  font-weight: bold;
}

.cifs-info {
  font-size: 1.2rem;
}

.cifs-value {
  font-weight: bold;
}

.cifs-currency {
  font-size: 1rem;
}

.cifs-discount {
  font-size: 1.1rem;
  font-weight: bold;
}

.cifs-total {
  font-size: 1.3rem;
  font-weight: bold;
}
</style>
