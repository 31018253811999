<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        id="upgrade-cuota-collective-button"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card class="pa-6" v-if="dialog" id="upgrade-collective-card"
      ><v-container>
        <v-row justify="center">
          <v-col cols="4">
            <v-text-field
              v-model="notificationsNumber"
              :label="$t('numberNotificationstoHire')"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="additionalCifsNumber"
              :label="$t('numberCIFtoHire')"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              disabled
              :value="this.$store.state.user.collectiveData.addCifNotifNumber"
              :label="$t('addCifNotifNumber')"
              type="number"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="5">
            <h4>{{ $t('currentMotnhFacturation') }}</h4>
            {{ $t('numberNotificationstoHire') }}
            {{ notificationsCurrentAmount }} /
            <b>{{ notificationsCurrentBill }}€</b> <br />
            {{ $t('numberCIFtoHire') }}
            {{ cifsCurrentAmount }} / <b>{{ cifsCurrentBill }}€</b> <br />
            {{ $t('clientPurchases') }}

            {{ clientCreationsCurrentMonth }} /
            <b>
              {{
                clientCreationsCurrentMonth *
                $store.state.user.collectiveData.newClientRegistrationPrice
              }}€
            </b>
          </v-col>
          <v-col cols="auto"> <v-icon> mdi-arrow-right </v-icon></v-col>
          <v-col cols="5" class="primary--text">
            <h4>{{ $t('newMonthFacturation') }}</h4>
            <span>
              {{ $t('numberNotificationstoHire') }}

              {{
                parseInt(notificationsCurrentAmount) +
                parseInt(totalNotifications)
              }}
              /
              <b>{{ newNotificationsBill }} €</b>
            </span>
            <br />
            <span
              >{{ $t('numberCIFtoHire') }}
              {{ parseInt(cifsCurrentAmount) + parseInt(totalCifs) }}
              /
              <b>{{ newCifsBill }} €</b>
            </span>
            <br />
            {{ $t('clientPurchases') }}

            {{ parseInt(clientCreationsCurrentMonth) }} /
            <b>
              {{
                parseInt(clientCreationsCurrentMonth) *
                $store.state.user.collectiveData.newClientRegistrationPrice
              }}€
            </b>
          </v-col>
        </v-row>
        <v-row justify="center">
          <h3>
            {{ $t('finalPriceMonth') }} <br />
            <h2>
              <b
                >{{
                  parseInt(newNotificationsBill) +
                  parseInt(newCifsBill) +
                  parseInt(
                    parseInt(clientCreationsCurrentMonth) *
                      $store.state.user.collectiveData
                        .newClientRegistrationPrice
                  )
                }}
                €</b
              >
            </h2>
          </h3>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-btn
              v-if="!isLoading"
              text
              class="primary secondary--text"
              @click="upgradeClient"
            >
              {{ $t('client.createClient.upgradeClient') }}</v-btn
            >
            <v-progress-linear v-else indeterminate color="primary">
            </v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  calculateRangePrices,
  notificationsHistoryAmount,
  cifsCurrentAmount,
  getCollectivePrices,
} from '@/utils/prices-utils'
import getErrorText from '@/utils/get-error-text'
import { updateServices } from '@/services/clients-service'
import { mapMutations } from 'vuex'

export default {
  props: {
    clientProp: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.isLoading = false
  },
  data() {
    return {
      client: { ...this.clientProp },
      dialog: false,
      notificationsNumber: 0,
      additionalCifsNumber: 0,
      isLoading: false,
      collectivePrices: getCollectivePrices(),
    }
  },
  computed: {
    //cada cif incluye 20 notificaciones, se suman a las notif adicionales que quiera contratar
    totalNotifications() {
      return (
        parseInt(this.notificationsNumber) +
        parseInt(
          this.additionalCifsNumber *
            this.$store.state.user.collectiveData.addCifNotifNumber
        )
      )
    },
    totalCifs() {
      return parseInt(this.additionalCifsNumber)
    },
    clientCreationsCurrentMonth() {
      const currentMonth = new Date().getMonth()
      const currentYear = new Date().getFullYear()

      return this.$store.state.history.filter((item) => {
        const itemDate = item.createdAt.toDate()
        return (
          itemDate.getMonth() === currentMonth &&
          itemDate.getFullYear() === currentYear &&
          item.actionType == 'clientCreate'
        )
      }).length
    },
    notificationsCurrentAmount() {
      const currentMonth = new Date().getMonth()
      const currentYear = new Date().getFullYear()

      var totalNotifications = notificationsHistoryAmount(
        currentMonth,
        currentYear,
        this.$store.state.history
      )
      return parseInt(
        totalNotifications -
          parseInt(
            this.clientCreationsCurrentMonth *
              this.$store.state.user.collectiveData.newClientNotifNumber
          )
      )
    },
    notificationsCurrentBill() {
      this.notificationsCurrentAmount

      return calculateRangePrices(
        this.notificationsCurrentAmount,
        this.collectivePrices.notificationPrices,
        this.collectivePrices.notificationGaps
      )
    },
    newNotificationsBill() {
      let notificationsTotal =
        parseInt(this.notificationsCurrentAmount) +
        parseInt(this.totalNotifications)

      return calculateRangePrices(
        notificationsTotal,
        this.collectivePrices.notificationPrices,
        this.collectivePrices.notificationGaps
      )
    },
    cifsCurrentAmount() {
      const currentMonth = new Date().getMonth()
      const currentYear = new Date().getFullYear()

      const totalCifs = cifsCurrentAmount(
        currentMonth,
        currentYear,
        this.$store.state.history
      )
      return parseInt(
        totalCifs -
          parseInt(
            this.clientCreationsCurrentMonth *
              this.$store.state.user.collectiveData.newClientCifsNumber
          )
      )
    },
    cifsCurrentBill() {
      return calculateRangePrices(
        this.cifsCurrentAmount,
        this.collectivePrices.cifPrices,
        this.collectivePrices.cifGaps
      )
    },
    newCifsBill() {
      let totalNumberCifsCurrentMonth =
        parseInt(this.cifsCurrentAmount) + parseInt(this.totalCifs)

      return calculateRangePrices(
        totalNumberCifsCurrentMonth,
        this.collectivePrices.cifPrices,
        this.collectivePrices.cifGaps
      )
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),

    async upgradeClient() {
      this.isLoading = true
      var dataUpdate = {
        services: {
          newPackagesOfCifsToAdd: [
            {
              id: '',
              amount: parseInt(this.additionalCifsNumber),
              price: 0,
              concept: 'notificationsCuota',
              discountPercentage: 0,
              discountAmount: 0,
              status: 'pendingActivation',
              type: 'upgrade',
              packages: [parseInt(this.additionalCifsNumber)],
              date: new Date(),
              selected: true,
            },
          ],
          newPackagesOfNotificationsToAdd: [
            {
              id: '',
              amount:
                parseInt(this.notificationsNumber) +
                parseInt(
                  this.additionalCifsNumber *
                    this.$store.state.user.collectiveData.addCifNotifNumber
                ),
              price: 0,
              concept: 'notificationsPackages',
              discountPercentage: 0,
              discountAmount: 0,
              status: 'pendingActivation',
              type: 'upgrade',
              packages: [
                parseInt(this.notificationsNumber) +
                  parseInt(
                    this.additionalCifsNumber *
                      this.$store.state.user.collectiveData.addCifNotifNumber
                  ),
              ],
              date: new Date(),
              selected: true,
            },
          ],
          newPackagesOfSignaturesToAdd: [],
          newPackagesOfCertificatesToAdd: [],
        },
        upgradeServices: true,
        // Indicamos que es usuario de tipo COLECTIVO
        isCollectiveUser: true,
      }

      try {
        dataUpdate.managerId = this.$store.state.user.id

        // Llamamos a la funcion del utils a través del servicio
        await updateServices(this.client.id, dataUpdate)
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
        this.isLoading = false
        this.dialog = false
      }
      this.isLoading = false
      this.dialog = false
      return
    },
  },
  watch: {
    // Formateo de las variable notificationsNumber
    notificationsNumber: function (newValue) {
      if (newValue === '') this.notificationsNumber = 0
    },
    // Formateo de las variable additionalCifsNumber
    additionalCifsNumber: function (newValue) {
      if (newValue === '') this.additionalCifsNumber = 0
    },
  },
}
</script>

<style lang="scss" scoped></style>
