// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries //
import * as Firebase from 'firebase/app'
import * as Analytics from 'firebase/analytics'
import * as Auth from 'firebase/auth'
import * as Firestore from 'firebase/firestore'
import * as Storage from 'firebase/storage'
import * as Functions from 'firebase/functions'
import { initializeApp } from 'firebase/app'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_API_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const App = initializeApp(firebaseConfig)
const Database = Firestore.getFirestore(App)

// Initialize Functions References
const functions = Functions.getFunctions(App, 'europe-west3')
const HelpFormFunction = Functions.httpsCallable(functions, 'help-form')
const HelpLogFunction = Functions.httpsCallable(functions, 'help-log')

const UsersUpdateEmail = Functions.httpsCallable(
  functions,
  'users-update-email'
)
const UsersUpdatePassword = Functions.httpsCallable(
  functions,
  'users-update-password'
)

const UsersUpdateAdmin = Functions.httpsCallable(
  functions,
  'users-update-admin'
)

const UsersUpdateEmpresa = Functions.httpsCallable(
  functions,
  'users-update-empresa'
)

const CreateEmpresaFirestore = Functions.httpsCallable(
  functions,
  'users-create-firestore',
  { timeout: 180000 }
)

const CreateEmpresaDMS = Functions.httpsCallable(
  functions,
  'users-create-dms',
  { timeout: 180000 }
)

const UsersUpdateMultifactor = Functions.httpsCallable(
  functions,
  'users-update-multifactor'
)

const UsersUpdateServices = Functions.httpsCallable(
  functions,
  'users-update-services',
  { timeout: 900000 }
)

const UsersCompleteDeletion = Functions.httpsCallable(
  functions,
  'users-delete',
  { timeout: 3600000 }
)

const UsersIp = Functions.httpsCallable(functions, 'users-ip')

const UserDisable = Functions.httpsCallable(functions, 'users-disable')

// User management functions
const UsersManagementCreateUser = Functions.httpsCallable(
  functions,
  'usersManagement-create'
)
const UsersManagementDeleteUser = Functions.httpsCallable(
  functions,
  'usersManagement-delete'
)

// Initialize Firestore References
const UsersManagementCollection = Firestore.collection(
  Database,
  'usersManagement'
)
const UpdateUserManagementEmail = Functions.httpsCallable(
  functions,
  'usersManagement-update-email'
)

const UsersManagementDoc = (userId) =>
  Firestore.doc(Database, 'usersManagement', userId)
const UsersCollection = Firestore.collection(Database, 'users')
const UsersDoc = (userId) => Firestore.doc(Database, 'users', userId)
const InvoicesDoc = (userId, invoiceId) =>
  Firestore.doc(Database, 'users', userId, 'invoices', invoiceId)
const InvoicesCollection = (userId) => {
  return Firestore.collection(Database, 'users', userId, 'invoices')
}
const CIFsCollection = (userId) =>
  Firestore.collection(Database, 'users', userId, 'cifs')
const UserOperatorsQuery = (userId) =>
  Firestore.query(
    UsersCollection,
    Firestore.where('parentRef', '==', UsersDoc(userId)),
    Firestore.where('tipo', '==', 'operator')
  )

// Users's trace
const TraceManagementCollection = (userId) =>
  Firestore.collection(Database, 'usersManagement', userId, 'trace')
const TraceManagementDoc = (userId, traceId) =>
  Firestore.doc(Database, 'usersManagement', userId, 'trace', traceId)

// News
const NewsCollection = () => Firestore.collection(Database, 'news')
const NewsDoc = (id) => Firestore.doc(Database, 'news', id)

// Settings references
const SettingsCollection = () => Firestore.collection(Database, 'settings')
const BillingDoc = () => Firestore.doc(Database, 'settings', 'billing')
const LegalDoc = () => Firestore.doc(Database, 'settings', 'legal')

// Users's History
const HistoryManagementCollection = (userId) =>
  Firestore.collection(Database, 'usersManagement', userId, 'history')

const GetDocFromReference = async (reference) => {
  if (!reference) return
  const doc = await Firestore.getDoc(reference)
  return { id: doc.id, ...doc.data() }
}

export {
  Firebase,
  App,
  Analytics,
  Auth,
  Firestore,
  Database,
  Storage,
  Functions,
  //Users
  UsersManagementCollection,
  UsersCollection,
  UsersManagementDoc,
  UsersDoc,
  InvoicesDoc,
  InvoicesCollection,
  CIFsCollection,
  UserOperatorsQuery,
  UsersUpdateEmail,
  UsersUpdatePassword,
  UsersUpdateAdmin,
  UsersUpdateEmpresa,
  UsersUpdateMultifactor,
  UsersUpdateServices,
  UsersIp,
  UserDisable,
  UsersManagementCreateUser,
  UsersManagementDeleteUser,
  UsersCompleteDeletion,
  NewsCollection,
  NewsDoc,
  // User Management
  UpdateUserManagementEmail,
  // Empresa Management
  CreateEmpresaFirestore,
  CreateEmpresaDMS,
  //Trace
  TraceManagementCollection,
  HistoryManagementCollection,
  TraceManagementDoc,
  //Settings
  HelpFormFunction,
  HelpLogFunction,
  BillingDoc,
  LegalDoc,
  SettingsCollection,
  GetDocFromReference,
}
