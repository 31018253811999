<template>
  <v-container class="pa-0">
    <v-card-text v-if="showTitle">
      <h1>{{ $t('client.cuotaCard.title') }}</h1>
      <p>{{ $t('client.cuotaCard.description') }}</p>
      <!-- Agrega aquí cualquier otra información del producto -->
    </v-card-text>

    <v-row align="center" justify="center">
      <v-col cols="12" md="6" lg="4">
        <v-card elevation="1" class="ma-2 pa-2">
          <h2 v-if="!isConfiguration" class="mt-4 mb-4">
            {{ $t('client.cuotaCard.cardTitle') }}
          </h2>
          <h1 class="mt-4 mb-4">{{ price }} €</h1>
          <v-icon x-large>mdi-cog-play</v-icon>
          <v-row v-if="isConfiguration" justify="center">
            <v-col cols="6">
              <v-text-field
                v-model="cuotaServicioModel"
                :rules="[notEmptyNumber]"
                :placeholder="$t('price') + '(€)'"
              >
                <!-- :placeholder="
                  $t('price') + ' ' + '(' + cuotaServicioChild + '€)'
                " -->
                €
              </v-text-field>
            </v-col>
          </v-row>
          <v-card-text>{{ $t('client.cuotaCard.timeSub') }}</v-card-text>
          <v-btn
            v-if="checkButtonRulesComputed && isConfiguration"
            @click.stop="changeCuotaPrice()"
            :disabled="confirmLoading"
            :loading="confirmLoading"
            class="ma-0 pa-0"
            plain
          >
            {{ $t('client.customBrand.save') }}
          </v-btn>
        </v-card>
      </v-col>

      <v-col v-if="!isConfiguration" cols="12" md="6" lg="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="mr-2"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="$t('endDate')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            :locale="$t('javascriptLocale')"
            :min="new Date().toISOString().substring(0, 10)"
            no-title
            scrollable
          >
          </v-date-picker>
        </v-menu>
        <v-slider
          :disabled="isChannelUser"
          class="mt-5"
          v-model="discount"
          :label="$t('client.cuotaCard.discount')"
          :thumb-color="$vuetify.theme.dark ? 'white' : 'black'"
          thumb-label="always"
        >
          <template v-slot:thumb-label="{ value }"> {{ value }}% </template>
        </v-slider>
      </v-col>
      <v-col v-if="!isConfiguration">
        <h2 class="mt-4 mb-4">
          {{ $t('client.cuotaCard.finalPrice') }}<br />
          {{ $n(price, 'i18nAmount') }}€ - {{ discount }}% =
          {{ $n(price - (price * discount) / 100, 'i18nAmount') }} €
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import { updateBilling } from '@/services/billing-service'

export default {
  props: {
    price: {
      type: Number,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    fixedDiscount: {
      type: Number,
      default: 0,
    },
    isConfiguration: {
      type: Boolean,
      default: false,
    },
    companyType: {
      type: String,
    },
    expirationDate: {
      type: Date,
    },
  },
  async created() {
    this.emitEvent()
    this.cifsAmount = this.initialAmount
    this.cuotaServicioModel = this.price
  },
  data() {
    return {
      cuotaServicioModel: this.price,
      date: this.initialEndDate(),
      menu: false,
      discount: this.fixedDiscount,
      swapPrices: true,
      swapWholesaler: false,
      confirmLoading: false,
    }
  },
  watch: {
    price() {
      this.emitEvent()
    },
    discount() {
      this.emitEvent()
    },
    date() {
      this.emitEvent()
    },
  },
  computed: {
    ...mapGetters(['isChannelUser']),
    checkButtonRulesComputed() {
      return (
        this.cuotaServicioModel !== '' &&
        this.cuotaServicioModel !== undefined &&
        this.cuotaServicioModel !== null &&
        this.cuotaServicioModel != this.price &&
        this.cuotaServicioModel > 0
      )
    },
  },
  methods: {
    ...rules,
    ...mapMutations(['setSnackbar']),
    initialEndDate() {
      const fromDate = this.expirationDate ? this.expirationDate : new Date()

      return new Date(fromDate.setFullYear(fromDate.getFullYear() + 1))
        .toISOString()
        .substring(0, 10)
    },
    async changeCuotaPrice() {
      try {
        this.confirmLoading = true
        let dataUpdate = {}
        if (this.companyType == 'EMPRESA') {
          dataUpdate = { empresaAppCuota: parseInt(this.cuotaServicioModel) }
        } else if (this.companyType == 'ASESORIA') {
          dataUpdate = { despachoAppCuota: parseInt(this.cuotaServicioModel) }
        } else {
          const message = this.$t('updatePriceError')
          this.setSnackbar({ position: 'top', type: 'error', message })
        }

        await updateBilling(dataUpdate)
        const message = this.$t('updatePriceSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = this.$t('updatePriceError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
      }
    },
    emitEvent() {
      if (!this.isConfiguration) {
        this.$emit('goodOkCuota', {
          concept: 'goodOkCuota',
          endDate: this.date,
          price: this.price,
          discountAmount: (this.price * this.discount) / 100,
          discountPercentage: this.discount,
        })
      }
    },
    saveChanges() {},
  },
}
</script>

<style lang="scss" scoped></style>
