<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" md="12" lg="12">
        <ClientDetail
          :client="client"
          :invoices="invoices"
          :cifs="cifs"
          :loading="loading"
        >
        </ClientDetail>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// @ is an alias to /src
/*eslint-disable*/
import ClientDetail from '@/components/client/detail/ClientDetail.vue'

import {
  getUserInfoSubscription,
  getInvoicesSubscription,
  getCifsSubscription,
} from '@/services/clients-service'

export default {
  name: 'ClientDetailView',
  components: {
    ClientDetail,
  },
  async created() {
    // llamamos a Firestore para obtener los datos
    this.unsubClient = await getUserInfoSubscription(
      this.$route.params.id,
      this
    )
    // Si no existe, marcamos Not Found
    if (!this.unsubClient) this.setNotFound(true)

    this.unsubInvoices = getInvoicesSubscription(this.$route.params.id, this)
    this.unsubCifs = getCifsSubscription(this.$route.params.id, this)

    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  unmounted() {
    if (this.unsubClient) this.unsubClient()
    if (this.unsubInvoices) this.unsubInvoices()
    if (this.unsubCifs) this.unsubCifs()
  },
  data() {
    return {
      loading: true,
      client: undefined,
      unsubClient: () => {},
      invoices: [],
      unsubInvoices: () => {},
      cifs: [],
      unsubCifs: () => {},
    }
  },
}
</script>
