<template>
  <v-card class="fill-height" v-if="isAddaliaUser && client" outlined>
    <v-card-title>
      <v-icon left large>mdi-file-document-edit-outline</v-icon>
      {{ $t('legal.termsToAcceptTitle') }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="termsToAcceptDataForm"
        v-model="validForm"
        lazy-validation
        @submit.prevent="onSubmit"
      >
        <v-row justify="space-between">
          <v-col cols="12">
            <div class="text-start pl-4">
              <template v-if="editTermsFlag">
                <v-row class="ml-1 mt-1 ps-0">
                  <v-col cols="6" md="4" lg="4" class="pl-1">
                    <v-checkbox
                      v-model="client.legal.haveAccept"
                      @change="onCheckboxChange"
                      :label="$t('legal.termsToAcceptUpdate')"
                      class="ma-0 pa-0 large-checkbox"
                      dense
                      :disabled="confirmLoading"
                      id="terms-checkbox"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row
                  v-if="client.legal.haveAccept"
                  class="ml-1 mt-1 ps-0"
                  justify="center"
                  align="center"
                >
                  <v-col cols="12" md="6" lg="6" class="pl-1">
                    <v-select
                      v-model="selectedContractId"
                      :items="contractModelList"
                      item-text="name"
                      item-value="id"
                      @change="contractModelEvent"
                      :label="$t('legal.termsOfServiceModel')"
                      prepend-icon="mdi-file-document-edit-outline"
                      :rules="[notEmpty]"
                      id="select-contractModel"
                    ></v-select>
                  </v-col>

                  <v-col cols="6" md="6" lg="6" class="pl-1">
                    <v-btn
                      :disabled="!selectedContract"
                      @click="showPdf()"
                      color="primary"
                      class="ma-4"
                    >
                      {{ $t('legal.termsPdfView') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <!-- esta es la parte NO editable hasta no pulsar el boton de edicion -->
                <v-row class="ml-1 mt-1 ps-0">
                  <v-col cols="6" md="4" lg="4" class="pl-1">
                    <v-checkbox
                      v-model="client.legal.haveAccept"
                      :label="$t('legal.termsToAcceptUpdate')"
                      class="ma-0 pa-0 large-checkbox"
                      dense
                      :disabled="true"
                      id="terms-checkbox"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="ml-1 mt-1 ps-0" justify="center" align="center">
                  <v-col cols="12" md="6" lg="6" class="pl-1">
                    <v-select
                      v-model="selectedContractId"
                      :items="contractModelList"
                      item-text="name"
                      item-value="id"
                      :disabled="true"
                      :label="$t('legal.termsOfServiceModel')"
                      prepend-icon="mdi-file-document-edit-outline"
                      :rules="[notEmpty]"
                      id="select-contractModel"
                    ></v-select>
                  </v-col>

                  <v-col cols="6" md="6" lg="6" class="pl-1">
                    <v-btn
                      :disabled="true"
                      @click="showPdf()"
                      color="primary"
                      class="ma-4"
                    >
                      {{ $t('legal.termsPdfView') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="allowToPerformAction('changeClientInformation')">
      <v-btn
        v-if="!editTermsFlag"
        @click="editTermsFlag = true"
        text
        :disabled="confirmLoading"
        color="primary"
        id="edit-terms-button"
      >
        {{ $t('client.clientDetail.edit') }}
      </v-btn>
      <v-btn
        v-else
        @click="editTermsData()"
        text
        color="primary"
        :loading="confirmLoading"
        :disabled="confirmLoading || !userChanges"
        id="save-company-data"
      >
        {{ $t('client.clientDetail.save') }}
      </v-btn>
      <v-btn
        v-if="editTermsFlag && !confirmLoading"
        @click="cancelEdit"
        text
        color="red"
        :disabled="confirmLoading"
      >
        {{ $t('cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { allowToPerformAction } from '@/services/users-service'
import { Firestore } from '@/firebase-exports'
import { updateClient } from '@/services/clients-service'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import rules from '@/utils/rules'
const lodash = require('lodash')

export default {
  props: {
    clientProp: {
      type: Object,
      required: true,
    },
    contractModelMap: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validForm: false,
      confirmLoading: false,
      editTermsFlag: false,
      client: lodash.cloneDeep(this.clientProp), // copia profunda
      selectedContractId: '',
      selectedContract: '',
      contractModelList: [],
    }
  },
  mounted() {
    // Convertir el mapa a una lista de objetos y presentarla ordenada por nombre
    this.contractModelList = Object.keys(this.contractModelMap)
      .map((key) => {
        return {
          id: key,
          name: this.$t('legal.' + key),
          ...this.contractModelMap[key],
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))

    this.showOriginalData()
  },
  computed: {
    ...mapGetters(['isAddaliaUser', 'isAddaliaAdminUser', 'isSupportUser']),
    userChanges() {
      const editableVariables = ['haveAccept', 'contractModel']

      for (const variable of editableVariables) {
        if (this.client.legal[variable] !== this.clientProp.legal[variable]) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    allowToPerformAction(action) {
      // a este componente le he asignado el mismo valor 'changeClientInformation' de los formularios AdminData y CompanyData
      // y asi se comporta de igual manera, en este momento pueden modificar datos los roles: admin, comercial y soporte
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
    cancelEdit() {
      this.client = lodash.cloneDeep(this.clientProp)
      this.editTermsFlag = false
      this.showOriginalData()
    },
    async editTermsData() {
      if (!this.$refs.termsToAcceptDataForm?.validate()) return

      this.confirmLoading = true

      try {
        // Comprobamos si han cambiado los datos y si hay que actualizarlos o eliminar el objeto .legal completo
        // y cambiamos el estado en funcion de si debe o no debe aceptar los terminos
        if (
          this.client.legal.haveAccept != this.clientProp.legal.haveAccept ||
          this.client.legal.contractModel != this.clientProp.legal.contractModel
        ) {
          let dataUpdate
          if (this.client.legal.haveAccept) {
            dataUpdate = {
              legal: {
                haveAccept: this.client.legal.haveAccept,
                contractModel: this.client.legal.contractModel,
              },
              estado: this.client.channelId
                ? 'pendienteComercial'
                : this.client.legal.haveAccept
                ? 'pendienteFacturacion'
                : 'pendienteFirma',
            }
          } else {
            // si es false borramos el objeto .legal
            dataUpdate = {
              legal: Firestore.deleteField(),
              estado: this.client.channelId
                ? 'pendienteComercial'
                : this.client.legal.haveAccept
                ? 'pendienteFacturacion'
                : 'pendienteFirma',
            }
          }
          //console.log('dataUpdate=', dataUpdate)
          await updateClient(this.client.id, dataUpdate)
        }

        this.editTermsFlag = !this.editTermsFlag
        this.confirmLoading = false

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('editSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.confirmLoading = false
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    onCheckboxChange() {
      if (!this.client.legal.haveAccept) {
        this.selectedContractId = ''
        this.selectedContract = ''
        this.client.legal.contractModel = ''
      }
    },
    showOriginalData() {
      this.selectedContractId = this.client.legal
        ? this.client.legal.contractModel
        : ''
      this.contractModelEvent()
    },
    contractModelEvent() {
      this.selectedContract = this.contractModelList.find(
        (item) => item.id === this.selectedContractId
      )
      this.client.legal.contractModel = this.selectedContractId
    },
    async showPdf() {
      if (this.selectedContract?.contractPdfURL) {
        window.open(this.selectedContract?.contractPdfURL, '_blank')
      } else {
        const message = this.$t('legal.termsPdfNotFound')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
    updateContractNamei18n() {
      // Actualiza los elementos internacionalizados del select de modelos de contratos
      this.contractModelList.forEach((item) => {
        item.name = this.$t('legal.' + item.id)
      })
    },
  },
  watch: {
    '$i18n.locale'() {
      this.updateContractNamei18n()
    },
  },
}
</script>

<style lang="scss" scoped></style>
