<template>
  <v-snackbar
    v-model="snackbarModel"
    :color="snackbarStyle.newColor"
    :timeout="snackbar.timeout"
    :top="snackbar.position === 'top'"
    :bottom="snackbar.position === 'bottom'"
    rounded
    :id="'snackbar-' + snackbar.type"
  >
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <span class="titleSnackbar">
            <v-icon> {{ snackbarStyle.icon }} </v-icon> {{ $t(snackbar.type) }}
          </span>
        </v-col>
        <v-col cols="2">
          <v-btn icon top @click="closeSnackbar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="textSnackbar">{{ snackbar.message }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    snackbar: Object,
  },

  methods: {
    ...mapMutations(['setSnackbar']),
    closeSnackbar() {
      this.setSnackbar({ ...this.snackbar, isDisplayed: false })
    },
  },
  computed: {
    snackbarModel: {
      get: function () {
        return this.snackbar.isDisplayed
      },
      set: function () {
        this.closeSnackbar()
      },
    },
    snackbarStyle() {
      var newColor
      var icon
      switch (this.snackbar.type) {
        case 'warning':
          // Código para manejar el caso de warning
          newColor = this.$vuetify.theme.themes.light.warning
          icon = 'mdi-alert-outline'
          break
        case 'success':
          // Código para manejar el caso de success
          newColor = this.$vuetify.theme.themes.light.success
          icon = 'mdi-check-circle-outline'
          break
        case 'info':
          // Código para manejar el caso de info
          newColor = this.$vuetify.theme.themes.light.info
          icon = 'mdi-information-outline'
          break
        case 'error':
          // Código para manejar el caso de error
          newColor = this.$vuetify.theme.themes.light.errorLigth
          icon = 'mdi-alert-circle-outline'
          break
        default: //grey lighten-3
          // Código para manejar cualquier otro caso
          newColor = this.$vuetify.theme.themes.light.primary
          icon = 'mdi-information-outline'
      }
      return { newColor: newColor, icon: icon }
    },
  },
}
</script>

<style lang="scss">
.titleSnackbar {
  font-size: 25px;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 700;
}
.textSnackbar {
  font-size: 17px;
  white-space: pre-wrap; /* Permite saltos de línea en el texto */
}
</style>
