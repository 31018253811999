<template>
  <v-col style="max-width: 900px">
    <br />
    <h1 class="left-align">
      {{ $t('documentation.titles.home.subsections.create') }}
    </h1>

    <p class="left-align">
      {{ $t('documentation.texts.createEmpresa.paragraph1') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic1Name" />
    <p class="left-align">
      {{ $t('documentation.texts.createEmpresa.paragraph2') }}
    </p>
    <v-img style="width: 100%; max-width: 300px" contain :src="pic2Name" />
    <p class="left-align">
      {{ $t('documentation.texts.createEmpresa.paragraph3') }}
    </p>

    <h2>
      <b>{{ $t('documentation.texts.createEmpresa.subTitle1') }}</b>
    </h2>
    <p class="left-align">
      {{ $t('documentation.texts.createEmpresa.paragraph4') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic3Name" />
    <br />
    <br />

    <h2>
      <b>{{ $t('documentation.texts.createEmpresa.subTitle2') }}</b>
    </h2>
    <p class="left-align">
      {{ $t('documentation.texts.createEmpresa.paragraph5') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic4Name" />
    <br />
    <br />

    <h2>
      <b>{{ $t('documentation.texts.createEmpresa.subTitle3') }}</b>
    </h2>
    <p class="left-align">
      {{ $t('documentation.texts.createEmpresa.paragraph6') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic5Name" />
    <br />
    <br />

    <h2>
      <b>{{ $t('documentation.texts.createEmpresa.subTitle4') }}</b>
    </h2>
    <p class="left-align">
      {{ $t('documentation.texts.createEmpresa.paragraph7') }}
    </p>
    <!-- <v-img style="width: 100%; max-width: 900px" contain :src="pic6Name" /> -->
    <br />
    <br />

    <h2>
      <b>{{ $t('documentation.texts.createEmpresa.subTitle5') }}</b>
    </h2>
    <p class="left-align">
      {{ $t('documentation.texts.createEmpresa.paragraph8') }}
    </p>
    <v-img style="width: 100%; max-width: 900px" contain :src="pic7Name" />

    <br />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAddaliaAdminUser']),
    pic1Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.createEmpresa.picAppBar') +
        '.png')
    },
    pic2Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.createEmpresa.picAppBarDetail') +
        '.png')
    },
    pic3Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.createEmpresa.picMainSubscription') +
        '.png')
    },
    pic4Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.createEmpresa.picNotifSubscription') +
        '.png')
    },
    pic5Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.createEmpresa.picSignatureSubscription') +
        '.png')
    },
    pic6Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.createEmpresa.picAdditionalServices') +
        '.png')
    },
    pic7Name() {
      return require('@/assets/documentation/' +
        this.$t('documentation.texts.createEmpresa.picSummary') +
        '.png')
    },
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '') {
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section },
        })
      } else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section, subsection },
        })
    },
  },
}
</script>

<style>
.custom-link {
  transition: 0.3s;
  cursor: pointer;
}

.custom-link:hover {
  text-decoration: underline;
}

.left-align {
  text-align: left;
}
</style>
