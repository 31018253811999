<template>
  <v-dialog :value="true" persistent :max-width="isDelete ? 570 : 1000">
    <v-card>
      <v-card-title class="justify-center">
        <v-row justify="center">
          <v-col cols="1"> </v-col>
          <v-col class="d-flex justify-center" cols="10">
            {{ isDisplay ? user.name : $t(`user.actions.title.${action}`) }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-tooltip bottom v-if="isMainUser">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isDisplay"
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="clickEditUserInfo"
                  id="edit-user"
                >
                  <v-icon v-if="userIsEditing"> mdi-close </v-icon>
                  <v-icon v-else> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <div>
                {{
                  userIsEditing ? $t('cancel') : $t('user.actions.title.update')
                }}
              </div>
            </v-tooltip>
            <v-btn
              v-else
              icon
              small
              @click="closeDialog"
              id="close-user-task-dialog"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if="isDelete" class="body-1">
        <v-col align="center">
          <span>
            <b>{{ user.name }}</b>
          </span>
          <br />
          <span>
            <b>{{ user.email }}</b>
          </span>
          <br />
          <br />
          <span>{{ $t('user.deleteUser.areYouSure') }}</span>
        </v-col>
      </v-card-text>

      <v-card-text v-else-if="isDisplay || isUpdate">
        <v-form
          ref="userForm"
          v-model="validUserForm"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-row class="mt-4" align="baseline">
            <!-- Name input -->
            <v-col cols="12" lg="6" md="6">
              <v-text-field
                :disabled="loading || isDisplayAndUserIsNotEditing"
                v-model="name"
                :rules="[notEmpty]"
                :label="$t('user.createUser.labels.name')"
                required
                prepend-icon="mdi-account"
              />
            </v-col>

            <!-- Email input -->
            <v-col cols="12" lg="6" md="6">
              <v-text-field
                :disabled="
                  !isAddaliaUser || loading || isDisplayAndUserIsNotEditing
                "
                v-model="emailInput"
                :rules="[notEmpty, email]"
                :label="$t('user.createUser.labels.email')"
                :autocomplete="false"
                required
                prepend-icon="mdi-mail"
              />
            </v-col>

            <!-- Types select -->
            <v-col
              cols="12"
              :lg="
                isAddaliaUser &&
                !user.parentRef &&
                (type.type == 'agent' || type.type == 'distributor' ? 5 : 6)
              "
              md="6"
            >
              <v-select
                v-model="type"
                :items="types"
                item-text="type"
                item-value="type"
                :rules="[notEmpty]"
                :label="$t('user.createUser.labels.types')"
                :disabled="
                  loading || isDisplayAndUserIsNotEditing || !isAddaliaUser
                "
                prepend-icon="mdi-account-details"
                required
                return-object
              >
                <template v-slot:item="{ item }">
                  <span
                    :id="'user-type-option-' + item.type"
                    small
                    class="ma-1"
                  >
                    {{ $t('user.createUser.types.' + item.type) }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span
                    :id="'user-type-selected-' + item.type"
                    small
                    class="ma-1"
                    :disabled="loading"
                  >
                    {{ $t('user.createUser.types.' + item.type) }}
                  </span>
                </template>
              </v-select>
            </v-col>

            <!-- Roles select -->
            <v-col
              cols="12"
              :lg="
                isAddaliaUser &&
                !user.parentRef &&
                (type.type == 'agent' || type.type == 'distributor' ? 4 : 6)
              "
              md="6"
            >
              <v-select
                v-model="rol"
                :items="type.roles"
                :rules="[notEmpty]"
                :label="$t('user.createUser.labels.roles')"
                :disabled="loading || isDisplayAndUserIsNotEditing"
                prepend-icon="mdi-account-cog"
                required
              >
                <template v-slot:item="{ item }">
                  <span :id="'user-rol-option-' + item">
                    {{ $t('user.createUser.roles.' + item) }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span
                    :id="'user-rol-selected-' + item"
                    small
                    class="ma-1"
                    :disabled="loading"
                  >
                    {{ $t('user.createUser.roles.' + item) }}
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="
                isAddaliaUser &&
                !user.parentRef &&
                (type.type == 'agent' || type.type == 'distributor')
              "
              cols="12"
              lg="2"
              md="6"
            >
              <v-text-field
                v-model="percent"
                :label="
                  type.type == 'agent'
                    ? $t('user.createUser.commission')
                    : $t('user.createUser.disscount')
                "
                type="number"
                max="100"
                min="0"
                suffix="%"
              >
              </v-text-field>
            </v-col>
            <v-col
              v-if="isAddaliaUser && user.type == 'collective'"
              cols="12"
              lg="6"
              md="6"
            >
              <v-text-field
                :disabled="loading || isDisplayAndUserIsNotEditing"
                v-model="redmineProject"
                :rules="[notEmpty]"
                :label="$t('client.clientDetail.redmineProject')"
                required
                prepend-icon="mdi-bell-cog"
              />
            </v-col>
            <v-col
              v-if="isAddaliaUser && user.type == 'collective'"
              cols="12"
              lg="6"
              md="6"
            >
              <v-text-field
                :disabled="loading || isDisplayAndUserIsNotEditing"
                v-model="redmineKey"
                :rules="[notEmpty]"
                :label="$t('client.clientDetail.redmineKey')"
                required
                prepend-icon="mdi-file-cog"
              />
            </v-col>
            <v-row
              class="pl-5 pr-5 mr-4 ml-4"
              justify="space-around"
              v-if="isAddaliaUser && type.type == 'collective'"
            >
              <v-col class="mb-0 pb-0" cols="12">
                <span class="subtitle-2">{{
                  $t('user.createUser.collectiveParams')
                }}</span>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  :disabled="loading || isDisplayAndUserIsNotEditing"
                  dense
                  v-model="collectiveData.newClientRegistrationPrice"
                  :label="$t('user.createUser.newClientRegistrationPrice')"
                  type="number"
                  min="0"
                  suffix="€"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  :disabled="loading || isDisplayAndUserIsNotEditing"
                  dense
                  v-model="collectiveData.newClientNotifNumber"
                  :label="$t('user.createUser.newClientNotifNumber')"
                  type="number"
                  min="0"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  :disabled="loading || isDisplayAndUserIsNotEditing"
                  dense
                  v-model="collectiveData.newClientCifsNumber"
                  :label="$t('user.createUser.newClientCifsNumber')"
                  type="number"
                  min="0"
                >
                </v-text-field>
              </v-col>
              <v-col class="mb-0 pb-0" cols="12">
                <span class="subtitle-2">
                  {{ $t('user.createUser.newClientParams') }}
                </span>
              </v-col>

              <v-col cols="12" lg="3" md="6">
                <v-text-field
                  :disabled="loading || isDisplayAndUserIsNotEditing"
                  dense
                  v-model="collectiveData.maxCifsPerClient"
                  :label="$t('user.createUser.maxCifsPerClient')"
                  type="number"
                  min="0"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="3" md="6">
                <v-text-field
                  :disabled="loading || isDisplayAndUserIsNotEditing"
                  dense
                  v-model="collectiveData.addCifNotifNumber"
                  :label="$t('user.createUser.addCifNotifNumber')"
                  type="number"
                  min="0"
                >
                </v-text-field>
              </v-col>
              <v-col cols="5" lg="2" md="6">
                <v-checkbox
                  dense
                  value="false"
                  disabled
                  :label="$t('user.createUser.prepaid')"
                ></v-checkbox>
              </v-col>
              <v-col cols="7" lg="2" md="6">
                <v-checkbox
                  :disabled="loading || isDisplayAndUserIsNotEditing"
                  dense
                  v-model="collectiveData.autoRenewal"
                  :label="$t('user.createUser.autoRenewal')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="headline justify-center" v-if="isMainUser">
        <v-btn
          :disabled="loading"
          color="error"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          @click="closeDialog"
          id="close-user-task-dialog"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          color="primary"
          class="white--text"
          :width="!isMobile ? '150px' : '100px'"
          :loading="loading"
          :disabled="loading || isDisplayAndUserIsNotEditing"
          @click="performAction"
          id="confirm-user-task"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import { deleteUser, updateUser, updateEmail } from '@/services/users-service'

export default {
  components: {},
  props: {
    types: Array,
    user: Object,
    action: String,
  },
  mounted() {
    this.name = this.user.name
    this.emailInput = this.user.email
    this.type = this.types.find((type) => type.type == this.user.type)
    this.rol = this.user.rol
    this.percent = this.user.percent
    this.redmineProject = this.user.redmineProject
    this.redmineKey = this.user.redmineKey
    this.collectiveData = this.user.collectiveData || {}
  },
  data() {
    return {
      validUserForm: false,
      maxChars: 100,
      loading: false,
      userIsEditing: this.action === 'update',
      name: undefined,
      emailInput: undefined,
      rol: undefined,
      percent: 0,
      type: [],
      collectiveData: {},
      redmineProject: '',
      redmineKey: '',
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    /**
     * Closes the dialog
     */
    closeDialog() {
      this.$emit('closeDialog')
    },
    /**
     * When the pencil button is clicked, the user is allowed to edit.
     */
    clickEditUserInfo() {
      this.userIsEditing = !this.userIsEditing
    },
    /**
     * Performs the action of the dialog (create, update or delete).
     */
    async performAction() {
      if (!this.isDelete && !this.$refs.userForm?.validate()) return

      this.loading = true

      try {
        if (this.isUpdate || this.isDisplay) {
          if (this.emailInput !== this.user.email) {
            await updateEmail(this.emailInput, this.user.id)
          }

          await updateUser(
            this.user.id,
            this.isAddaliaUser
              ? {
                  name: this.name,
                  rol: this.rol,
                  type: this.type.type,
                  redmineProject: this.redmineProject,
                  redmineKey: this.redmineKey,
                  percent: parseInt(this.percent),
                  collectiveData: this.collectiveData,
                }
              : {
                  name: this.name,
                  rol: this.rol,
                  collectiveData: this.collectiveData,
                }
          )
        } else if (this.isDelete) await deleteUser(this.user.id)

        const message = this.$t('user.actions.success.' + this.action)
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        const message = getErrorText(error.message)
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isAddaliaUser', 'isMainUser']),
    isUpdate() {
      return this.action === 'update'
    },
    isDelete() {
      return this.action === 'delete'
    },
    isDisplay() {
      return this.action === 'display'
    },
    isDisplayAndUserIsNotEditing() {
      return this.action === 'display' && !this.userIsEditing
    },
  },
  watch: {},
}
</script>

<style>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear-anim {
  animation-name: fade;
  animation-duration: 0.4s;
}
</style>
