<template>
  <v-menu transition="slide-y-transition" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="appBarMenu"
        :color="$vuetify.theme.dark ? 'white' : 'black'"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          v-if="unreadNews.length"
          color="primary"
          overlap
          :content="unreadNews.length"
        >
          <v-icon large> mdi-menu </v-icon>
        </v-badge>
        <v-icon v-else> mdi-menu </v-icon>
      </v-btn>
    </template>

    <v-list class="pt-0">
      <NewsList color="warningLow" icon="mdi-bell"> </NewsList>
      <div @click="goTo('ProfileView')" class="pa-3 grey lighten-4">
        <v-avatar class="mb-2" color="primary" size="40">
          <span class="white--text text-h6">
            {{
              $store.state.user.name
                .split(' ')
                .map((word) => word[0])
                .join('')
                .toUpperCase()
            }}
          </span>
        </v-avatar>
        <v-list-item-title>
          <b> {{ $store.state.user.name }} </b>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('user.createUser.types.' + $store.state.user.type) }} -
          {{ $t('user.createUser.roles.' + $store.state.user.rol) }}
        </v-list-item-subtitle>
      </div>
      <v-list-item
        @click="item.method()"
        link
        v-for="(item, i) in items"
        :key="i"
      >
        <v-spacer></v-spacer>
        {{ item.title }}
        <v-icon :id="item.icon === 'mdi-exit-run' ? 'logOut' : ''" class="ml-1">
          {{ item.icon }}
        </v-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { allowToPerformAction } from '@/services/users-service'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import i18n from '@/i18n'
import NewsList from '@/components/news/visualization/NewsList'

export default {
  components: {
    NewsList,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['isMobile']),
    items() {
      return [
        {
          title: this.$t('appBar.appBarMenu.changeTheme'),
          method: this.changeTheme,
          icon: 'mdi-compare',
        },
        {
          title: this.$t('navigationPage.profile'),
          method: () => this.goTo('ProfileView'),
          icon: 'mdi-account',
        },
        {
          title: this.$t('navigationPage.customization'),
          method: () => this.goTo('CustomizationView'),
          icon: 'mdi-palette',
          hidden:
            !this.$store.getters.isAdminUser ||
            !this.$store.getters.isChannelUser,
        },
        {
          title: this.$t('navigationPage.dashboard'),
          method: () => this.goTo('DashboardView'),
          icon: 'mdi-view-dashboard',
        },
        {
          title: this.$t('navigationPage.clients'),
          method: () => this.goTo('ClientsView'),
          icon: 'mdi-account-group',
        },
        {
          title: this.$t('navigationPage.clientCreate'),
          method: () => this.goTo('CreateClientView'),
          icon: 'mdi-account-plus',
          hidden: !allowToPerformAction(
            this.$store.state.user.rol,
            'createClient'
          ),
        },
        {
          title: this.$t('navigationPage.collectiveBilling'),
          method: () => this.goTo('CollectiveBillingView'),
          icon: 'mdi-account-credit-card',
          hidden: !this.$store.getters.isCollectiveUser,
        },
        {
          title: this.$t('navigationPage.createUser'),
          method: () => this.goTo('CreateUserView'),
          icon: 'mdi-account-plus',
          hidden:
            !this.$store.getters.isMainUser ||
            (this.$store.getters.isMainUser &&
              !this.$store.getters.isAdminUser &&
              !this.$store.getters.isCommercialUser),
        },
        {
          title: this.$t('navigationPage.users'),
          method: () => this.goTo('UsersView'),
          icon: 'mdi-account-group',
          hidden:
            !this.$store.getters.isMainUser ||
            (this.$store.getters.isMainUser &&
              !this.$store.getters.isAdminUser &&
              !this.$store.getters.isCommercialUser),
        },
        {
          title: this.$t('navigationPage.news'),
          method: () => this.goTo('NewsView'),
          icon: 'mdi-newspaper-variant-multiple-outline',
          hidden:
            !this.$store.getters.isMarketingUser &&
            !this.$store.getters.isAddaliaAdminUser,
        },
        {
          title: this.$t('navigationPage.balance'),
          method: () => this.goTo('BalanceView'),
          icon: 'mdi-cash-register',
          hidden:
            !this.$store.getters.isWholesalerUser ||
            (this.$store.getters.isWholesalerUser &&
              !this.$store.getters.isMainUser),
        },
        {
          title: this.$t('navigationPage.documentation'),
          method: () => this.goTo('DocumentationView'),
          icon: 'mdi-account-question',
        },
        {
          title: this.$t('navigationPage.appConfiguration'),
          method: () => this.goTo('ConfigurationView'),
          icon: 'mdi-cog',
          hidden:
            !this.$store.getters.isMarketingUser &&
            !this.$store.getters.isAddaliaAdminUser,
        },
        {
          title: i18n.locale === 'es' ? 'EN' : 'ES',
          method: () => (i18n.locale = i18n.locale === 'es' ? 'en' : 'es'),
          icon: 'mdi-translate-variant',
        },
        {
          title: this.$t('appBar.appBarMenu.logOut'),
          method: this.logOut,
          icon: 'mdi-exit-run',
        },
      ].filter((item) => !item.hidden)
    },
    unreadNews() {
      var context = this
      return this.$store.state.news.filter(function (el) {
        return el.usersRead.indexOf(context.$store.state.user.id) == -1
      })
    },
  },
  methods: {
    ...mapActions(['logout']),
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    logOut() {
      this.logout()
    },
    goTo(pageName) {
      this.$router.push({ name: pageName })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-list__tile {
  padding: 0;
}
</style>
