<template>
  <v-card class="mt-4">
    <v-card-title> {{ $t('charts.upcomingRenewals') }} </v-card-title>
    <v-data-table
      :height="height"
      :headers="headers"
      :items="sortedClients"
      @click:row="openClientDetail"
      :items-per-page="5"
      :footer-props="{
        'items-per-page-options': [5, 25, 50 /*-1*/],
      }"
      class="elevation-1"
    >
      <template v-slot:[`item.plan.expirationLicense`]="{ item }">
        {{ $d(item.plan.expirationLicense.toDate(), 'i18nDate') }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
    },
    clients: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('charts.company'),
          value: 'empresa',
          sortable: false,
        },
        { text: this.$t('charts.type'), value: 'tipoEmpresa', sortable: false },
        {
          text: this.$t('charts.renovationDate'),
          value: 'plan.expirationLicense',
          sortable: false,
        },
        {
          text: this.$t('charts.commercial'),
          value: 'commercial.name',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    sortedClients() {
      const clientsWithLicencia = this.clients.filter(
        (client) => client.plan && client.plan.expirationLicense
      )
      return clientsWithLicencia.sort((a, b) => {
        const dateA = new Date(a.plan.expirationLicense.toDate())
        const dateB = new Date(b.plan.expirationLicense.toDate())
        return dateA - dateB
      })
    },
  },
  methods: {
    openClientDetail(client) {
      this.$router.push({
        name: 'ClientDetailView',
        params: { id: client.id, client: client },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
