export function calculateRangePrices(amount, prices, gaps) {
  amount = parseInt(amount, 10)
  let totalCost = 0
  let i = 0
  while (amount > 0) {
    if (amount > gaps[i]) {
      totalCost += gaps[i] * prices[i]
      amount -= gaps[i]
      if (i < prices.length - 1) i++
    } else {
      totalCost += amount * prices[i]
      amount = 0
    }
  }
  return totalCost.toFixed(2)
}

export function notificationsHistoryAmount(currentMonth, currentYear, history) {
  if (
    typeof currentMonth !== 'number' ||
    currentMonth < 0 ||
    currentMonth > 11
  ) {
    throw new Error(
      'El mes proporcionado no es válido. Debe estar entre 0 y 11.'
    )
  }
  if (typeof currentYear !== 'number' || currentYear < 1971) {
    throw new Error(
      'El año proporcionado no es válido. Debe ser un número positivo y no mayor al año actual.'
    )
  }

  try {
    var totalNotifications = history
      .filter((item) => {
        const itemDate = item.createdAt.toDate()
        return (
          itemDate.getMonth() === currentMonth &&
          itemDate.getFullYear() === currentYear
        )
      })
      .reduce(
        (total, item) => total + parseInt(item.notificationsNumber, 10),
        0
      )

    return totalNotifications
  } catch (error) {
    console.error('Error al calcular el total de notificaciones:', error)
    throw error
  }
}

export function cifsCurrentAmount(currentMonth, currentYear, history) {
  if (
    typeof currentMonth !== 'number' ||
    currentMonth < 0 ||
    currentMonth > 11
  ) {
    throw new Error(
      'El mes proporcionado no es válido. Debe estar entre 0 y 11.'
    )
  }
  if (typeof currentYear !== 'number' || currentYear < 1971) {
    throw new Error(
      'El año proporcionado no es válido. Debe ser un número positivo y no mayor al año actual.'
    )
  }
  try {
    const totalCifs = history
      .filter((item) => {
        const itemDate = item.createdAt.toDate()

        return (
          itemDate.getMonth() === currentMonth &&
          itemDate.getFullYear() === currentYear
        )
      })
      .reduce(
        (total, item) => total + parseInt(item.additionalCifsNumber, 10),
        0
      )
    return totalCifs
  } catch (error) {
    console.error('Error al calcular el total de cifs:', error)
    throw error
  }
}

export function getCollectivePrices() {
  return {
    notificationPrices: [2, 1.75, 1.54, 1.35, 1.18, 1.04, 0.91, 0.91],
    notificationGaps: [100, 400, 500, 1000, 3000, 5000, 5000],
    cifPrices: [50.4, 48.8, 47.3, 45.8, 44.4, 43],
    cifGaps: [100, 150, 250, 250, 250],
  }
}
