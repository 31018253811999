<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" md="8" lg="8">
        <CreateUserForm :types="types" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateUserForm from '@/components/users/CreateUserForm'
import { getTypes } from '@/services/users-service'

export default {
  name: 'CreateUserView',
  components: {
    CreateUserForm,
  },
  data() {
    return {
      types: getTypes(),
    }
  },
}
</script>
