<template>
  <v-card class="mx-auto" :min-height="height">
    <v-container>
      <v-card-text>
        <v-row class="mb-3" align="center">
          <v-col cols="12">
            <v-avatar class="mt-2" color="primary" size="56">
              <span class="white--text text-h5">
                {{
                  user.name
                    .split(' ')
                    .map((word) => word[0])
                    .join('')
                    .toUpperCase()
                }}
              </span>
            </v-avatar>
            <div class="title">{{ user.name }}</div>
            <v-chip class="ma-2" color="black white--text darken-4">
              {{ $t('user.createUser.types.' + user.type) }}
            </v-chip>
            <v-row class="mt-2" align="center" justify="space-around">
              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="text-h5">
                      <v-icon large color="black darken-2">
                        mdi-account
                      </v-icon>
                      {{ $store.state.clients.length }}
                    </span>
                  </template>
                  <span>{{ $t('tabTitle.clients') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="auto"
                v-if="user.type === 'distributor' || user.type === 'agent'"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="text-h5">
                      <v-icon large color="black darken-2">
                        mdi-cash-multiple
                      </v-icon>
                      {{ user.percent }}%
                    </span>
                  </template>
                  <span v-if="user.type === 'distributor'">
                    {{ $t('user.createUser.disscount') }}
                  </span>
                  <span v-if="user.type === 'agent'">
                    {{ $t('user.createUser.commission') }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mb-5"></v-divider>
        <span class="title font-weight-black">
          {{ $t('user.userList.headers.name') }}
        </span>
        <br />
        <span class="subtitle-1"> {{ user.name }} </span>
        <br />
        <br />
        <span class="title font-weight-black">
          {{ $t('user.userList.headers.type') }}
        </span>
        <br />
        <span class="subtitle-1">
          {{ $t('user.createUser.types.' + user.type) }}
        </span>
        <br />
        <br />
        <span class="title font-weight-black">
          {{ $t('user.userList.headers.role') }}
        </span>
        <br />
        <span class="subtitle-1">
          {{ $t('user.createUser.roles.' + user.rol) }}
        </span>
        <br />
        <br />
        <span class="title font-weight-black">
          {{ $t('user.userList.headers.addalia') }}
        </span>
        <br />
        <span class="subtitle-1">
          {{ user.addalia?.name || '-' }}
        </span>
        <br />
        <br />
        <span class="title font-weight-black"
          >{{ $t('user.userList.headers.email') }}
        </span>
        <br />
        <span class="subtitle-1">{{ user.email }} </span>
      </v-card-text>

      <v-card-actions>
        <EditUserDialog
          :id="user.id"
          :nameProp="user.name"
          :emailProp="user.email"
        >
        </EditUserDialog>
        <v-spacer></v-spacer>
        <ChangePasswordDialog :id="user.id"></ChangePasswordDialog>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import ChangePasswordDialog from '@/components/profile/ChangePasswordDialog'
import EditUserDialog from '@/components/profile/EditUserDialog'

export default {
  props: {
    user: {
      type: Object,
    },
    height: {
      type: String,
    },
  },
  components: {
    ChangePasswordDialog,
    EditUserDialog,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
