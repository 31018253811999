<template>
  <v-card class="mx-auto sticky-card">
    <v-row class="d-flex justify-start pa-0 ma-0" sticky>
      <v-list>
        <v-list-group
          :disabled="section.disabled"
          v-for="(section, i) in sectionTitles"
          :key="i"
          v-model="section.active"
          @click.stop="
            () => {
              if (section.disabled) {
                return
              }
              if (section.subSections.length == 0) goDoc(section)
            }
          "
          :append-icon="
            section.disabled
              ? 'mdi-lock'
              : section.subSections.length > 0
              ? 'mdi-menu-down'
              : ''
          "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                @click.stop="
                  () => {
                    if (section.disabled) return
                    goDoc(section)
                  }
                "
                style="font-size: large"
                :id="'documentation-' + section.title"
              >
                <b>{{
                  $t('documentation.titles.' + section.title + '.title')
                }}</b>
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-if="!section.disabled">
            <v-list-item
              v-for="(subsection, n) in section.subSections"
              :key="n"
              @click.stop="goDoc(section, subsection)"
            >
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    'pl-2 ' +
                    (subsection === $route.query?.subsection
                      ? 'active-sub-section'
                      : '')
                  "
                  style="font-size: large"
                  :id="'documentation-' + section.title + '-' + subsection"
                >
                  {{
                    $t(
                      'documentation.titles.' +
                        section.title +
                        '.subsections.' +
                        subsection
                    )
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      sectionTitles: [
        {
          title: 'home',
          subSections: ['create', 'update', 'delete'],
          active: false,
          disabled: false,
        },
        {
          title: 'services',
          subSections: ['activation', 'renewal', 'upgrade'],
          active: false,
          disabled: false,
        },
      ],
    }
  },
  created() {
    // Cogemos la página actual y la ponemos como active
    const key = this.$route.query?.section
    if (key) {
      const index = this.sectionTitles.findIndex(({ title }) => title === key)
      this.sectionTitles[index].active = true
    }
  },
  methods: {
    goDoc(section, subsection = '') {
      if (subsection === '')
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section.title },
        })
      else
        this.$router.push({
          name: 'DocumentationView',
          query: { section: section.title, subsection },
        })
    },
  },
  computed: {
    ...mapGetters(['isAddaliaAdminUser']),
  },
}
</script>

<style>
.active-sub-section {
  color: #1d9ea4;
}
.sticky-card {
  width: 250px;
  position: fixed;
  z-index: 1;
}
</style>
