<template>
  <v-dialog v-model="dialog" width="750" id="upgrade-dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :id="
          assetName === 'signaturesCertsPackages'
            ? 'upgrade-certificates-packages-button'
            : assetName === 'notificationsPackages'
            ? 'upgrade-notifications-packages-button'
            : 'upgrade-signatures-packages-button'
        "
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialog" id="upgrade-card">
      <v-card-title class="text-h5 grey lighten-2" id="upgrade-title">
        {{ $t('client.cuotaCard.upgradePackages') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false" id="close-button"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <PackagesLicenses
            :label="label"
            :prices="prices"
            :packagesList="packagesList"
            :fixedDiscount="isDistributorUser ? $store.state.user.percent : 0"
            resultEventName="upgradePackages"
            @upgradePackages="upgradePackagesEvent"
          />
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="confirmPurchase"
          id="confirm-purchase-button"
          :disabled="!eventObject?.packages?.length"
        >
          {{ $t('confirmPurchase') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PackagesLicenses from '@/components/client/createClient/PackagesLicenses.vue'
import { createInvoice } from '@/services/clients-service'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    disabled: {
      type: Boolean,
    },
    prices: {
      type: Array,
    },
    packagesList: {
      type: Array,
    },
    assetName: {
      type: String,
    },
    label: {
      type: String,
    },
    eventName: {
      type: String,
    },
    client: {
      type: Object,
    },
    typeProp: {
      type: String,
    },
  },
  components: {
    PackagesLicenses,
  },
  data() {
    return {
      dialog: false,
      eventObject: {},
    }
  },
  computed: {
    ...mapGetters(['isDistributorUser']),
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setLoading']),
    upgradePackagesEvent(event) {
      this.eventObject = event
      this.eventObject.concept = this.assetName
    },
    async confirmPurchase() {
      try {
        this.loading = true

        const newData = {
          ...this.eventObject,
          type: this.typeProp,
          status: 'pendingBilling',
          date: new Date(),
        }
        await createInvoice(this.client.id, newData)

        this.dialog = false
        this.loading = false
        const message = this.$t('invoiceCreateSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        this.loading = false
        const message = this.$t('invoiceCreateError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
