import { Firestore, SettingsCollection, LegalDoc } from '@/firebase-exports'

/**
 * Obtiene la información del documento legal: contratos / terminos del servicio.
 *
 * @returns {Promise} Una promesa que se resuelve con la información del documento si existe.
 */
export async function getLegal() {
  const docSnap = await Firestore.getDoc(LegalDoc())
  if (!docSnap.exists()) return

  const document = docSnap.data()

  return { ...document }
}

/**
 * Actualiza el documento legal: contratos / terminos del servicio.
 *
 * @param {Object} data - Los datos a actualizar en el documento legal.
 * @returns {Promise} Una promesa que se resuelve cuando se actualiza el documento legal.
 */
export async function updateLegal(data) {
  await Firestore.updateDoc(
    Firestore.doc(SettingsCollection(), 'legal'),
    data,
    { merge: true }
  )
}

/**
 * Obtiene la suscripción del documento legal: contratos / terminos del servicio.
 *
 * @param {Object} data - Los datos para la suscripción del documento legal.
 * @returns {function} La función de cancelación de la suscripción.
 */
export async function legalSubscription(context) {
  const unsub = Firestore.onSnapshot(
    Firestore.doc(SettingsCollection(), 'legal'),
    async (docSnapshot) => {
      const document = docSnapshot.data()
      context.legal = { ...document }
    }
  )
  return unsub
}
