<template>
  <AppSkeleton :condition="loading" type="clientDetail">
    <template v-slot:skeleton-content>
      <v-container>
        <v-row v-if="client">
          <v-col cols="12" md="3">
            <AdminData min-height="200" :clientProp="client"></AdminData>
          </v-col>
          <v-col cols="12" md="3">
            <CompanyData :clientProp="client"></CompanyData>
          </v-col>
          <v-col cols="12" md="6">
            <ServicesData
              :clientProp="client"
              :cifs="cifs"
              :invoices="invoices"
            >
            </ServicesData>
          </v-col>
          <v-expand-transition> </v-expand-transition>

          <v-col cols="12" md="12">
            <TermsHaveAcceptData
              :clientProp="addLegalInfo()"
              :contractModelMap="contractModelMap"
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
            v-if="
              client.planBillingArray &&
              client.tipoEmpresa != 'ASESORIACOLECTIVO'
            "
          >
            <BillingResume
              :includeDates="false"
              :showSum="true"
              :client="client"
              title="subscriptions"
              :concepts="client.planBillingArray"
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
            v-if="invoices.length && client.tipoEmpresa != 'ASESORIACOLECTIVO'"
          >
            <ShoppingHistory :client="client" :concepts="invoices" />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </AppSkeleton>
</template>

<script>
import ServicesData from '@/components/client/detail/ServicesData.vue'
import CompanyData from '@/components/client/detail/CompanyData.vue'
import AdminData from '@/components/client/detail/AdminData.vue'
import TermsHaveAcceptData from '@/components/client/detail/TermsHaveAcceptData.vue'
import BillingResume from '@/components/client/BillingResume.vue'
import ShoppingHistory from '@/components/client/detail/ShoppingHistory.vue'
import { mapMutations } from 'vuex'

export default {
  props: {
    loading: Boolean,
    client: Object,
    invoices: {
      type: Array,
      default() {
        return []
      },
    },
    cifs: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    ServicesData,
    CompanyData,
    AdminData,
    TermsHaveAcceptData,
    BillingResume,
    ShoppingHistory,
  },
  data() {
    return {
      // Modelos de contratos para los Terminos del servicio
      contractModelMap: this.$store.state.appLegal,
    }
  },
  methods: {
    ...mapMutations(['setNotFound']),
    addLegalInfo() {
      if (!this.client.legal) {
        return {
          legal: { haveAccept: false, contractModel: '' },
          ...this.client,
        }
      }
      return this.client
    },
  },
}
</script>

<style></style>
