<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" md="12" sm="12">
        <v-card elevation="0">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center body-1">
                    <v-btn
                      @click="packagesAmount = minimumAmount"
                      v-if="!!packagesAmount"
                      icon
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                    <span class="text-center body-1" v-else>
                      {{ $t('notifications') }}</span
                    >
                  </th>
                  <th class="text-center body-1">{{ $t('price') }}</th>
                  <th class="text-center body-1">{{ $t('tokens') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in prices" :key="item.amount">
                  <td @click="addPackages(item)">
                    {{ $n(item.amount, 'i18nCount') }}
                    <span
                      v-if="
                        countOccurrences(totalPackagesComputed, item.amount)
                      "
                      >(x{{
                        countOccurrences(totalPackagesComputed, item.amount)
                      }})</span
                    >
                  </td>
                  <td @click="addPackages(item)">
                    {{ $n(item.totalPrice, 'i18nAmount') }}€
                  </td>
                  <td @click="addPackages(item)">
                    {{ $n(item.tokens, 'i18nAmount') }}¤
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <v-row>
          <v-col
            v-if="totalPackagesComputed.length"
            :cols="totalPackagesComputed.length > 0 ? 4 : 12"
          >
            <v-text-field
              disabled
              v-model="packagesAmount"
              dense
              :rules="[notEmptyNumber]"
              :label="label"
              type="number"
              :id="
                label === $t('numberOfNotifications')
                  ? 'number-of-notifications'
                  : label === $t('numberOfSignatures')
                  ? 'number-of-signatures'
                  : 'number-of-certificates'
              "
            ></v-text-field>
          </v-col>
          <v-col v-if="totalPackagesComputed.length" cols="4">
            <v-text-field
              disabled
              :value="totalPackagesPriceComputed - moneyDiscount"
              dense
              :rules="[notEmptyNumber]"
              :label="$t('price')"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col v-if="totalPackagesComputed.length" cols="4">
            <v-text-field
              disabled
              :value="tokensAmountComputed"
              dense
              :rules="[notEmptyNumber]"
              :label="$t('tokens')"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="totalPackagesComputed.length > 0 && enableDisccount"
            cols="4"
          >
            <v-text-field
              :disabled="fixedDiscount > 0"
              @focus="selectedDiscountType = 'percentage'"
              v-model="percentDiscount"
              dense
              :label="'% ' + $t('client.cuotaCard.discount')"
              type="number"
              suffix="%"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="totalPackagesComputed.length > 0 && enableDisccount"
            cols="4"
          >
            <v-text-field
              :disabled="fixedDiscount > 0"
              @focus="selectedDiscountType = 'amount'"
              v-model="moneyDiscount"
              dense
              type="number"
              suffix="€"
              :label="$t('client.cuotaCard.discount')"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="totalPackagesComputed.length > 0 && enableDisccount"
            cols="12"
          >
            <v-slider
              :disabled="fixedDiscount > 0"
              @change="selectedDiscountType = 'bar'"
              :max="totalPackagesPriceComputed"
              min="0"
              v-model="moneyDiscount"
              :label="$t('client.cuotaCard.discount')"
              :thumb-color="$vuetify.theme.dark ? 'white' : 'black'"
              :thumb-size="50"
            >
              <template v-slot:thumb-label="{ value }">
                {{
                  $n((value * 100) / totalPackagesPriceComputed, 'i18nAmount')
                }}%</template
              >
            </v-slider>
          </v-col>
        </v-row>

        <h3 v-if="totalPackagesPriceComputed > 0" class="mt-4 mb-4">
          <span class="notification-label">
            {{ $t('client.packagesLicenses.finalPrice') }}
          </span>
          <!--
          <br />
         
          <span class="notification-info">
            <span class="notification-value">
              Paquetes seleccionados: {{ totalPackagesComputed }}
            </span>
          </span>
          <br />
          -->
          <span v-if="percentDiscount">
            <span class="notification-currency">
              {{ $n(totalPackagesPriceComputed, 'i18nAmount') }}
              €
            </span>
            -
            <span class="notification-discount">
              {{ $n(moneyDiscount, 'i18nAmount') }}€ ({{
                $n(percentDiscount, 'i18nAmount')
              }}%)
            </span>
            =
          </span>
          <span class="notification-total">
            {{ $n(totalPackagesPriceComputed - moneyDiscount, 'i18nAmount') }}
            €
          </span>
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@/utils/rules'

export default {
  props: {
    label: String,
    prices: {
      type: Array,
    },
    packagesList: {
      type: Array,
    },
    name: {
      type: String,
    },
    resultEventName: {
      type: String,
    },
    initialAmount: {
      type: Number,
      default: 0,
    },
    minimumAmount: {
      type: Number,
      default: 0,
    },
    fixedDiscount: {
      type: Number,
      default: 0,
    },
    enableDisccount: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.packagesAmount = this.initialAmount
  },
  data() {
    return {
      packagesAmount: 0,
      percentDiscount: 0,
      moneyDiscount: 0,
      selectedDiscountType: 'bar',
      tokensAmount: 0,
    }
  },
  computed: {
    tokensAmountComputed() {
      return this.tokensAmount
    },
    totalPackagesComputed() {
      //listado de pauetes de firmas a contratar
      return this.restarNumerosHastaMenor(
        this.packagesList,
        this.packagesAmount
      )
    },

    totalPackagesPriceComputed() {
      //Calcula el precio de los paquetes contratados
      let precioTotal = 0
      for (let i = 0; i < this.totalPackagesComputed.length; i++) {
        const paqueteSeleccionado = this.totalPackagesComputed[i]
        const precioPaquete = this.prices.find(
          (price) => price.amount === paqueteSeleccionado
        )?.totalPrice
        if (precioPaquete) {
          precioTotal += precioPaquete
        }
      }

      return precioTotal
    },
    finalPriceComputed() {
      return {
        concept: this.resultEventName,
        packages: this.totalPackagesComputed,
        price: this.totalPackagesPriceComputed,
        discountAmount: this.moneyDiscount,
        discountPercentage: this.percentDiscount,
      } /*
      return (
        this.totalPackagesPriceComputed -
        (this.totalPackagesPriceComputed * this.percentDiscount) / 100
      )*/
    },
  },
  watch: {
    moneyDiscount: function (newAmount) {
      if (
        this.selectedDiscountType === 'amount' ||
        this.selectedDiscountType === 'bar'
      ) {
        // Verificar si el nuevo valor es un número válido
        if (!isNaN(newAmount) && newAmount !== null) {
          // Calcular el descuento como el porcentaje del monto sobre el valor total
          this.percentDiscount = (
            (newAmount * 100) /
            this.totalPackagesPriceComputed
          ).toFixed(2)
        } else {
          // Si el valor introducido no es un número válido, establecer el descuento como null
          this.percentDiscount = 0
        }
      }
    },
    percentDiscount: function (newAmount) {
      if (this.selectedDiscountType === 'percentage') {
        // Verificar si el nuevo valor es un número válido
        if (!isNaN(newAmount) && newAmount !== null) {
          // Calcular el descuento como el porcentaje del monto sobre el valor total
          this.moneyDiscount = (
            (newAmount / 100) *
            this.totalPackagesPriceComputed
          ).toFixed(2)
        } else {
          // Si el valor introducido no es un número válido, establecer el descuento como null
          this.moneyDiscount = 0
        }
      }
    },
    finalPriceComputed(newValue) {
      // Emitir el evento con el nuevo valor de la computed property
      this.$emit(this.resultEventName, newValue)
    },
    packagesAmount() {
      this.percentDiscount = this.fixedDiscount > 0 ? this.fixedDiscount : 0
      this.moneyDiscount =
        (this.totalPackagesPriceComputed * this.percentDiscount) / 100
    },
  },
  methods: {
    ...rules,
    addPackages(item) {
      this.packagesAmount = Number(this.packagesAmount) + Number(item.amount)
      this.tokensAmount = Number(this.tokensAmount) + Number(item.tokens)
    },
    triggerNotRenewalLogic() {
      this.$emit('doNotRenewSignatures')
    },
    obtenerNumeroMasGrande(listaNumeros, numeroDado) {
      let numeroMasGrande = null

      for (let i = 0; i < listaNumeros.length; i++) {
        const numeroActual = listaNumeros[i]

        if (
          numeroActual <= numeroDado &&
          (numeroMasGrande === null || numeroActual > numeroMasGrande)
        ) {
          numeroMasGrande = numeroActual
        }
      }

      return numeroMasGrande
    },
    restarNumerosHastaMenor(listaNumeros, numeroDado) {
      let resultado = []
      let numeroActual = numeroDado

      while (numeroActual >= Math.min(...listaNumeros)) {
        const numeroMasGrande = this.obtenerNumeroMasGrande(
          listaNumeros,
          numeroActual
        )

        if (numeroMasGrande !== null) {
          resultado.push(numeroMasGrande)
          numeroActual -= numeroMasGrande
        } else {
          break
        }
      }

      return resultado
    },
    countOccurrences(array, element) {
      return array.reduce(function (count, currentElement) {
        if (currentElement === element) {
          count++
        }
        return count
      }, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-label {
  font-size: 1.2rem;
  font-weight: bold;
}

.notification-info {
  font-size: 1.2rem;
}

.notification-value {
  font-weight: bold;
}

.notification-currency {
  font-size: 1.1rem;
}

.notification-discount {
  font-size: 1.1rem;
  font-weight: bold;
}

.notification-total {
  font-size: 1.3rem;
  font-weight: bold;
}
.final-price-label {
  font-size: 1.8rem;
  font-weight: bold;
}

.final-price-value {
  font-size: 2.4rem;
  font-weight: bold;
}
</style>
