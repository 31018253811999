<template>
  <div>
    <apexChart
      type="bar"
      height="350"
      :options="chartOptionsComputed"
      :series="seriesComputed"
    ></apexChart>
  </div>
</template>

<script>
export default {
  props: {
    clients: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    asesoriasConFirmasActivas() {
      return this.clients.filter(
        (client) => client.firmasActivas && client.tipoEmpresa === 'ASESORIA'
      ).length
    },
    empresasConFirmasActivas() {
      return this.clients.filter(
        (client) => client.firmasActivas && client.tipoEmpresa === 'EMPRESA'
      ).length
    },
    asesoriasConNotificacionesActivas() {
      return this.clients.filter(
        (client) =>
          client.notificacionesActivas && client.tipoEmpresa === 'ASESORIA'
      ).length
    },
    empresasConNotificacionesActivas() {
      return this.clients.filter(
        (client) =>
          client.notificacionesActivas && client.tipoEmpresa === 'EMPRESA'
      ).length
    },
    seriesComputed() {
      return [
        {
          name: 'Empresas',
          data: [
            this.empresasConNotificacionesActivas,
            this.empresasConFirmasActivas,
          ],
        },
        {
          name: 'Asesorías',
          data: [
            this.asesoriasConNotificacionesActivas,
            this.asesoriasConFirmasActivas,
          ],
        },
      ]
    },
    chartOptionsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 350,
        },
        colors: ['#77B6EA', '#545454'],

        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        title: {
          text: this.$t('charts.clientsNumber'),
          align: 'left',
        },
        xaxis: {
          categories: [
            this.$t('charts.notifications'),
            this.$t('charts.signatures'),
          ],
        },
        yaxis: {
          title: {
            text: this.$t('charts.clientsNumber'),
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' Clientes'
            },
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
