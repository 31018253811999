<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="10">
        <UserList :users="users" :loading="loading" :types="types" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UserList from '@/components/users/UserList.vue'
import { getTypes, getUsersSubscription } from '@/services/users-service'

export default {
  name: 'UsersView',
  components: {
    UserList,
  },
  unmounted() {
    if (this.usersUnsubscribe) this.usersUnsubscribe()
  },

  async created() {
    this.usersUnsubscribe = await getUsersSubscription(
      this.$store.state.user.id,
      this
    )
  },
  data() {
    return {
      users: [],
      types: getTypes(),
      loading: true,
      usersUnsubscribe: () => {},
    }
  },
}
</script>
