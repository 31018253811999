<template>
  <v-card>
    <v-card-title :class="isMobile ? 'ma-0 pa-0' : ''">
      <v-container fluid>
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          v-if="!isMobile"
        >
          <v-col cols="4" no-gutters>
            <v-row align="baseline">
              <span class="my-0 mx-2">
                {{ $t('tabTitle.users') }}
              </span>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="1"
                  fab
                  small
                  class="ma-1"
                  v-bind="attrs"
                  v-on="on"
                  id="create-user-button"
                  @click.stop="goToCreateUser"
                >
                  <v-icon> mdi-account-plus-outline </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('tabTitle.createUser') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-col v-else>
          <v-row class="mb-1" align="baseline">
            <span class="my-0 mx-2">
              {{ $t('tabTitle.createUser') }}
            </span>
            <v-spacer />
            <v-btn
              elevation="1"
              fab
              small
              class="primary secondary--text ma-1"
              @click.stop="openUserDetail"
            >
              <v-icon> mdi-account-plus-outline </v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-container>
      <v-spacer></v-spacer>
    </v-card-title>
    <AppSkeleton data-v-step="201" :condition="loading" type="clientList">
      <template v-slot:skeleton-content>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="users"
          :items-per-page="5"
          class="elevation-1 custom-table"
          @click:row="(item) => openUserDetailDialog(item, 'display')"
          :footer-props="{ 'items-per-page-options': [5, 25, 50 /*-1*/] }"
          :options="options"
          @update:options="optionsChange"
          :search="search"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              :label="$t('user.userList.searchUser')"
              @change="searchChange"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <span> {{ item.id.substring(0, 6) }}... </span>
          </template>
          <template v-slot:[`item.rol`]="{ item }">
            <span> {{ $t('user.createUser.roles.' + item.rol) }} </span>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <span> {{ $t('user.createUser.types.' + item.type) }} </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="openUserDetailDialog(item, 'update')"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <div style="white-space: pre-line; max-width: 400px">
                {{ $t('user.editUser.title') }}
              </div>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="openUserDetailDialog(item, 'delete')"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <div style="white-space: pre-line; max-width: 400px">
                {{ $t('user.deleteUser.title') }}
              </div>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </AppSkeleton>
    <UserDialog
      v-if="userDialogShown"
      @closeDialog="closeDialog"
      :action="selectedAction"
      :user="selectedUser"
      :types="types"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import UserDialog from '@/components/users/UserDialog'

export default {
  props: {
    loading: Boolean,
    users: Array,
    types: Array,
  },
  components: {
    UserDialog,
  },
  data() {
    return {
      search: '',
      loadingData: false,
      options: {},
      selectedUser: undefined,
      selectedAction: undefined,
      userDialogShown: false,
    }
  },

  created() {
    this.options = this.$store.state.usersListOptions
    this.search = this.$store.state.usersSearch
  },
  computed: {
    ...mapGetters(['isMobile']),
    headers() {
      return [
        { text: this.$t('user.userList.headers.name'), value: 'name' },
        { text: this.$t('user.userList.headers.type'), value: 'type' },
        { text: this.$t('user.userList.headers.role'), value: 'rol' },
        { text: this.$t('user.userList.headers.email'), value: 'email' },
        { value: 'actions', sortable: false },
      ]
    },
  },
  methods: {
    ...mapMutations(['setUsersListOptions', 'setUsersSearch']),
    openUserDetailDialog(selectedItem, action = 'display') {
      this.selectedUser = selectedItem
      this.selectedAction = action
      this.userDialogShown = true
    },
    async goToCreateUser() {
      this.$router.push({
        name: 'CreateUserView',
      })
    },
    optionsChange(options) {
      this.setUsersListOptions(options)
    },
    searchChange(search) {
      this.setUsersSearch(search)
    },
    /**
     * Closes the dialog.
     */
    closeDialog() {
      this.userDialogShown = false
      this.selectedUser = undefined
      this.selectedAction = undefined
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global/global-styles.css';
</style>
