<template>
  <v-card>
    <v-card-title>
      <v-row class="d-flex justify-center" no-gutters>
        <h2>{{ $t(title) }}</h2>
      </v-row>
    </v-card-title>
    <v-container>
      <v-row>
        <v-col cols="12">
          <table class="invoice">
            <thead>
              <tr>
                <th>{{ $t('invoices.concept') }}</th>
                <th v-if="includeDates">
                  {{ $t('invoices.date') }}
                </th>
                <th>{{ $t('invoices.price') }}</th>
                <th>{{ $t('invoices.amount') }}</th>
                <th>{{ $t('invoices.discount') }}</th>
                <th>{{ $t('invoices.discountAmount') }}</th>
                <th>{{ $t('invoices.finalPrice') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in concepts" :key="index">
                <template v-if="item.price > 0">
                  <td>
                    {{ $t('invoices.' + item.concept) }}
                  </td>
                  <td v-if="includeDates">
                    <span v-if="item.date">{{ $d(item.date) }}</span>
                    <span v-else>-</span>
                  </td>
                  <td>{{ $n(item.price, 'i18nAmount') }} €</td>
                  <td>{{ formattedPackages(item) }}</td>
                  <td class="align-center">
                    <span v-if="client.estado !== 'pendienteComercial'">
                      {{
                        ((100 / item.price) * item.discountAmount).toFixed(2)
                      }}
                      %
                    </span>
                    <v-text-field
                      v-else
                      :disabled="!isAddaliaUser"
                      @input="
                        item.discountAmount =
                          (item.price * item.discountPercentage) / 100
                      "
                      single-line
                      v-model="item.discountPercentage"
                      dense
                      :label="'% ' + $t('client.cuotaCard.discount')"
                      type="number"
                      suffix="%"
                      :style="{ width: '50px' }"
                    ></v-text-field>
                  </td>
                  <td>{{ $n(item.discountAmount, 'i18nAmount') }} €</td>
                  <td>
                    {{ $n(item.price - item.discountAmount, 'i18nAmount') }} €
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
          <v-row justify="center" align="center">
            <v-col cols="8">
              <h1 v-if="showSum" class="mt-3">
                {{ $t('finalPrice') }}
                {{
                  $n(
                    concepts.reduce(
                      (acumulador, objeto) =>
                        acumulador +
                        Number(objeto.price) -
                        Number(objeto.discountAmount),
                      0
                    ),
                    'i18nAmount'
                  )
                }}
                €
              </h1>
            </v-col>
            <v-col
              cols="2"
              v-if="
                client.estado !== 'creating' &&
                isAddaliaUser &&
                allowToPerformAction('changeInvoiceStatus') &&
                conceptsChanged
              "
            >
              <v-btn
                dense
                class="mr-5"
                color="primary"
                plain
                @click="saveChanges"
                :disabled="confirmLoading"
                :loading="confirmLoading"
              >
                {{ $t('client.clientDetail.save') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { allowToPerformAction } from '@/services/users-service'
import { mapMutations, mapGetters } from 'vuex'
import { updateClient } from '@/services/clients-service'
const lodash = require('lodash')

export default {
  props: {
    concepts: {
      type: Array,
      required: true,
    },
    includeActions: {
      type: Boolean,
      default: false,
    },
    includeDates: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    client: {
      type: Object,
    },
    showSum: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmLoading: false,
      originalConcepts: lodash.cloneDeep(this.concepts),
    }
  },
  computed: {
    ...mapGetters(['isAddaliaUser']),
    itemsToActivate() {
      return this.concepts.filter((item) => item.selected === true)
    },
    conceptsChanged() {
      return !lodash.isEqual(this.concepts, this.originalConcepts)
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setLoading']),
    allowToPerformAction(action) {
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
    formattedPackages(item) {
      if (item.packages)
        return this.$n(
          item.packages.reduce((acumulador, amount) => acumulador + amount, 0),
          'i18nCount'
        )
      else return '-'
    },
    saveChanges() {
      this.confirmLoading = true
      const updates = {}
      this.concepts.forEach((item) => {
        Object.keys(item).forEach((key) => {
          updates[`planBilling.${item.concept}.${key}`] = item[key]
        })
      })
      updateClient(this.client.id, updates)
      //TODO SNACKBAR AVISANDO QUE SE HA GUARDADO BIEN
      this.confirmLoading = false
    },
  },
}
</script>

<style>
/* Estilos para la tabla invoice */
.invoice {
  width: 100%;
  border-collapse: collapse;
}

.invoice th,
.invoice td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.align-center {
  align-items: center;
}
</style>
