<template>
  <v-card class="fill-height" v-if="client" outlined>
    <v-card-title>
      <v-icon left>mdi-domain</v-icon>
      {{ $t('client.clientDetail.contactData') }}
      <v-tooltip
        bottom
        v-if="isAddaliaUser && allowToPerformAction('deleteClient')"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="error"
            class="ml-auto"
            @click.stop="deleteDialog = true"
            id="delete-1"
            small
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <div>
          {{ $t('completeDeletion') }}
        </div>
      </v-tooltip>
    </v-card-title>
    <v-dialog v-model="deleteDialog" persistent width="600">
      <v-card>
        <v-card-title class="justify-center">
          <h2>{{ $t('completeDeletion') }}</h2>
        </v-card-title>
        <v-card-text class="justify-center">
          <v-row justify="center">
            <v-card-text class="body-1">
              <v-col align="center" v-if="deleteStep === 1">
                <br />
                <h2 class="mt-1">
                  {{ clientProp.empresa }}
                </h2>
                <h3 class="mt-3 mb-5" id="get-client-numero-doc-identidad">
                  {{ clientProp.numeroDocIdentidad }}
                </h3>
                <h3>
                  <b>{{ $t('deleteWarning1') }}</b>
                </h3>
                <br />
                <span>{{ $t('deleteWarning2') }}</span>
                <br />
                <br />

                <v-btn rounded @click.stop="handleCloseDialog()" class="mr-7">
                  <b>
                    {{ $t('cancel') }}
                  </b>
                </v-btn>
                <v-btn
                  color="error"
                  rounded
                  id="delete-2"
                  @click.stop="deleteStep = 2"
                >
                  <b>
                    {{ $t('delete') }}
                  </b>
                </v-btn>
              </v-col>
              <v-col align="center" v-else-if="deleteStep === 2">
                <h2 class="mt-3 mb-3">{{ clientProp.numeroDocIdentidad }}</h2>
                <span>{{ $t('typeDocNumberId') }}</span>
                <v-col cols="8">
                  <v-text-field
                    class="mt-5"
                    :label="$t('documentNumber')"
                    :value="deleteNumberField"
                    @input="deleteNumberField = $event"
                    dense
                    autocomplete="false"
                    id="company-document-number"
                    :disabled="confirmLoading"
                    :rules="[
                      maxCharacters(maxChars),
                      notEmpty,
                      equal(clientProp.numeroDocIdentidad, 'cifNotEqual'),
                    ]"
                  />
                </v-col>
                <v-btn
                  rounded
                  :disabled="confirmLoading"
                  @click.stop="handleCloseDialog()"
                  class="mr-7"
                >
                  <b>
                    {{ $t('cancel') }}
                  </b>
                </v-btn>

                <v-btn
                  rounded
                  :loading="confirmLoading"
                  :disabled="
                    confirmLoading ||
                    deleteNumberField !== clientProp.numeroDocIdentidad
                  "
                  id="delete-3"
                  color="error"
                  @click.stop="completeDeletion()"
                >
                  <b>
                    {{ $t('delete') }}
                  </b>
                </v-btn>
              </v-col>
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-form
        ref="adminDataForm"
        v-model="validForm"
        lazy-validation
        @submit.prevent="onSubmit"
      >
        <v-row justify="space-between">
          <v-col cols="12">
            <div class="text-start">
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <template v-if="editAdminFlag">
                    <v-text-field
                      v-model="client.name"
                      :label="$t('client.clientDetail.name')"
                      :placeholder="clientProp.name"
                      :rules="[notEmpty]"
                      class="ma-0 pa-0"
                      dense
                      id="admin-name-input"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b>{{ $t('client.clientDetail.name') }}: </b>
                    </span>
                    <span class="body-1 text-end"> {{ clientProp.name }} </span>
                  </template>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <template v-if="editAdminFlag">
                    <v-text-field
                      v-model="client.surname"
                      :label="$t('client.clientDetail.surname')"
                      :placeholder="clientProp.surname"
                      :rules="[notEmpty]"
                      class="ma-0 pa-0"
                      dense
                      id="admin-surname-input"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b> {{ $t('client.clientDetail.surname') }}: </b>
                    </span>
                    <span class="body-1 text-end">
                      {{ clientProp.surname }}
                    </span>
                  </template>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <template v-if="editAdminFlag">
                    <v-text-field
                      v-model="client.email"
                      :label="$t('client.clientDetail.email')"
                      :rules="[notEmpty, email]"
                      :placeholder="clientProp.email"
                      class="ma-0 pa-0"
                      dense
                      id="email-admin-input"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b> {{ $t('client.clientDetail.email') }}: </b>
                    </span>
                    <span class="body-1 text-end">
                      {{ clientProp.email }}
                    </span>
                  </template>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <template v-if="editAdminFlag">
                    <v-text-field
                      v-model="client.telefono"
                      :label="$t('client.clientDetail.phone')"
                      :rules="[phoneNumber]"
                      :placeholder="clientProp.telefono"
                      class="ma-0 pa-0"
                      dense
                      id="telefono-admin-input"
                    ></v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b> {{ $t('client.clientDetail.phone') }}: </b>
                    </span>
                    <span class="body-1 text-end">
                      {{ clientProp.telefono }}
                    </span>
                  </template>
                </v-row>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions v-if="allowToPerformAction('changeClientInformation')">
      <v-btn
        v-if="!editAdminFlag"
        @click="editAdminFlag = true"
        text
        :disabled="confirmLoading"
        color="primary"
        id="edit-admin-button"
      >
        {{ $t('client.clientDetail.edit') }}
      </v-btn>
      <v-btn
        v-else
        @click="editAdminData()"
        text
        color="primary"
        :loading="confirmLoading"
        :disabled="confirmLoading || !userChanges"
        id="save-admin-data"
      >
        {{ $t('client.clientDetail.save') }}
      </v-btn>
      <v-btn
        v-if="editAdminFlag && !confirmLoading"
        @click="cancelEdit"
        text
        color="red"
        :disabled="confirmLoading"
        >{{ $t('cancel') }}
      </v-btn>
    </v-card-actions>
    <v-col>
      <AppSkeleton
        :condition="loadingCommercials"
        type="assignCommercial"
        v-if="isMainUser"
      >
        <template v-slot:skeleton-content>
          <v-col class="ma-3" cols="auto">
            <h3>{{ $t('client.clientDetail.assignedCommercial') }}:</h3>

            <v-select
              return-object
              dense
              clearable
              @change="updateCommercial()"
              :items="possibleCommercials"
              v-model="selectedCommercial"
              item-text="name"
              item-value="id"
              :disabled="!allowToPerformAction('assignCommercial')"
            >
              <template v-slot:selection="{ item }">
                {{ `${item.name}` }}
              </template>
              <template v-slot:item="{ item }">
                {{ `${item.name}` }}
              </template>
            </v-select>
          </v-col>
        </template>
      </AppSkeleton>
      <AppSkeleton
        :condition="loadingChannels"
        type="assignChannel"
        v-if="isAddaliaUser"
      >
        <template v-slot:skeleton-content>
          <v-col class="ma-3" cols="auto">
            <h3>{{ $t('client.clientDetail.assignedChannel') }}:</h3>

            <v-select
              return-object
              dense
              clearable
              @change="updateChannel()"
              :items="possibleChannels"
              v-model="selectedChannel"
              item-text="name"
              item-value="id"
              :disabled="!allowToPerformAction('assignChannel')"
            >
              <template v-slot:selection="{ item }">
                {{ `${item.name}` }}
              </template>
              <template v-slot:item="{ item }">
                {{ `${item.name}` }}
              </template>
            </v-select>
          </v-col>
        </template>
      </AppSkeleton>
    </v-col>
  </v-card>
</template>

<script>
import {
  getOperators,
  userCompleteDeletion,
  updateAdmin,
  getPossibleCommercials,
  updateCommercial,
  getPossibleChannels,
  updateChannel,
} from '@/services/clients-service'
import { allowToPerformAction } from '@/services/users-service'
import { removeFolder } from '@/services/storage-service'
import getErrorText from '@/utils/get-error-text'
import { mapGetters, mapMutations } from 'vuex'
import rules from '@/utils/rules'

export default {
  props: {
    clientProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      client: { ...this.clientProp },
      loadingCommercials: true,
      possibleCommercials: [],
      selectedCommercial: {},
      loadingChannels: true,
      possibleChannels: [],
      selectedChannel: {},
      validForm: false,
      editAdminFlag: false,
      confirmLoading: false,
      deleteStep: 1,
      deleteDialog: false,
      maxChars: 100,
      deleteNumberField: '',
    }
  },
  async mounted() {
    this.getCommercial()
    this.possibleCommercials = await getPossibleCommercials(this.clientProp)
    this.loadingCommercials = false

    if (this.isAddaliaUser) {
      this.getChannel()
      this.possibleChannels = await getPossibleChannels(this.clientProp)
      this.loadingChannels = false
    }
  },
  computed: {
    ...mapGetters(['isAddaliaUser', 'isMainUser']),
    userChanges() {
      const editableVariables = ['name', 'surname', 'telefono', 'email']

      for (const variable of editableVariables) {
        if (this.client[variable] !== this.clientProp[variable]) {
          return true
        }
      }

      return false
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    allowToPerformAction(action) {
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
    cancelEdit() {
      this.client = { ...this.clientProp }
      this.editAdminFlag = false
    },
    handleCloseDialog() {
      this.deleteDialog = false
      this.deleteStep = 1
    },
    getCommercial() {
      this.selectedCommercial = this.clientProp.commercial
        ? this.clientProp.commercial
        : {}
    },
    async updateCommercial() {
      await updateCommercial(this.clientProp.id, this.selectedCommercial?.id)
    },
    getChannel() {
      this.selectedChannel = this.clientProp.channel
        ? this.clientProp.channel
        : {}
    },
    async updateChannel() {
      await updateChannel(this.clientProp.id, this.selectedChannel?.id)
    },
    async completeDeletion() {
      if (!allowToPerformAction(this.$store.state.user.rol, 'deleteClient')) {
        const message = this.$t('insuficientPermissions')
        this.setSnackbar({ position: 'top', type: 'error', message })
        return
      }
      try {
        this.confirmLoading = true

        const operators = await getOperators(this.client.id)

        // Complete DMS deletion function
        await userCompleteDeletion(this.client.id)

        // Eliminar Storage para operadores y usuario principal
        await Promise.all([
          await removeFolder('user/' + this.client.id),
          ...operators.map(
            async (operator) => await removeFolder('user/' + operator.id)
          ),
        ])

        // Redirigimos al listado de clientes
        this.$router.replace('/clients')

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('completeDeletionSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.confirmLoading = false
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }

      this.confirmLoading = false
    },
    async editAdminData() {
      if (!this.$refs.adminDataForm?.validate()) return

      this.confirmLoading = true

      try {
        // Añadimos los campos
        const dataUpdate = {
          ...(this.client.name != this.clientProp.name
            ? { newName: this.client.name }
            : {}),
          ...(this.client.surname != this.clientProp.surname
            ? { newSurname: this.client.surname }
            : {}),
          ...(this.client.email != this.clientProp.email
            ? { newEmail: this.client.email }
            : {}),
          ...(this.client.telefono != this.clientProp.telefono
            ? { newTelefono: this.client.telefono }
            : {}),
        }

        // Comprobamos que haya cambios antes de llamar a la función
        if (Object.keys(dataUpdate).length)
          await updateAdmin(this.client.id, dataUpdate)

        this.editAdminFlag = !this.editAdminFlag
        this.confirmLoading = false

        // Actualizamos variable local con los nuevos valores
        this.client = { ...this.clientProp }

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('editSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.confirmLoading = false
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
