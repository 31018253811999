<template>
  <v-card class="mx-auto" :min-height="height">
    <v-container>
      <v-card-text>
        <v-row class="mb-3" align="center" justify="center">
          <v-col cols="12">
            <div class="title">{{ $t('wholesalerBalance.sellsBalance') }}</div>

            <v-row class="mt-2" align="center" justify="space-around">
              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="text-h5">
                      <v-icon large color="black darken-2">
                        mdi-black-mesa
                      </v-icon>
                      {{ $t('wholesalerBalance.tokens') }}

                      <br />
                      {{ user.tokens }}
                    </span>
                  </template>
                  <span>{{ $t('wholesalerBalance.tokens') }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="text-h5">
                      <v-icon large color="black darken-2">
                        mdi-mailbox
                      </v-icon>
                      {{ $t('notifications') }}<br />

                      {{ user.notifications }}
                    </span>
                  </template>

                  <span>{{ $t('wholesalerBalance.notifications') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <TimeLine :trace="trace"></TimeLine>
          </v-col>
          <v-col cols="8">
            <WholesalerDateSells
              v-if="trace.length > 0"
              :trace="trace"
              :clientsCreationDates="clientsCreationDates"
              :clientsCreationCounts="clientsCreationCounts"
            >
            </WholesalerDateSells>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import WholesalerDateSells from '@/components/profile/charts/WholesalerDateSells'
import TimeLine from '@/components/wholesaler/TimeLine'
import { getUserTrace } from '@/services/trace-service'

export default {
  props: {
    user: {
      type: Object,
    },
    height: {
      type: String,
    },
  },
  async created() {
    this.trace = await getUserTrace(this.user.id)
    this.trace
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Ordenar por fecha
      .reverse()
  },
  components: {
    WholesalerDateSells,
    TimeLine,
  },
  data() {
    return {
      trace: [],
    }
  },
  computed: {
    clientsCreationHistory() {
      const clientsByDate = this.$store.state.clients.reduce(
        (result, client) => {
          const createdAt = new Date(client.createdAt).toLocaleDateString(
            'en-US',
            {
              month: 'numeric',
              year: 'numeric',
            }
          ) // Obtener la fecha de creación sin la hora

          if (result[createdAt]) {
            result[createdAt]++
          } else {
            result[createdAt] = 1
          }
          return result
        },
        {}
      )

      const sortedClientsByDate = Object.entries(clientsByDate)
        .sort((a, b) => new Date(a[0]) - new Date(b[0]))
        .map(([date, count]) => ({ date, count }))

      return sortedClientsByDate
    },
    usersCreatedPerMonth() {
      const currentDate = new Date()
      const twelveMonthsAgo = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 11,
        1
      )
      const months = []

      for (
        let date = twelveMonthsAgo;
        date <= currentDate;
        date.setMonth(date.getMonth() + 1)
      ) {
        const month = date.toLocaleDateString('en-US', {
          month: 'numeric',
          year: 'numeric',
        })
        const usersCount = this.clientsCreationHistory.reduce((count, obj) => {
          const objMonth = obj.date
          if (objMonth === month) {
            count += obj.count
          }
          return count
        }, 0)
        months.push({ month, usersCount })
      }

      return months
    },
    clientsCreationDates() {
      return this.usersCreatedPerMonth.map((obj) => obj.month)
    },
    clientsCreationCounts() {
      return this.usersCreatedPerMonth.map((obj) => obj.usersCount)
    },
  },
}
</script>

<style lang="scss" scoped></style>
