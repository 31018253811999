import store from '@/store'

import LoginView from '@/views/LoginView'
import ProfileView from '@/views/ProfileView'
import CustomizationView from '@/views/CustomizationView'
import DashboardView from '@/views/DashboardView'
import ClientsView from '@/views/ClientsView'
import CreateClientView from '@/views/CreateClientView'
import ClientDetailView from '@/views/ClientDetailView'
import DocumentationView from '@/views/DocumentationView'
import ConfigurationView from '@/views/ConfigurationView'
import CollectiveBillingView from '@/views/CollectiveBillingView'
import NewsView from '@/views/NewsView'
import CreateUserView from '@/views/CreateUserView'
import UsersView from '@/views/UsersView'
import BalanceView from '@/views/BalanceView'
import NotFound from '@/views/NotFoundView'

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: {
      tabTitle: 'login',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: ProfileView,
    meta: {
      tabTitle: 'profile',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/customization',
    name: 'CustomizationView',
    component: CustomizationView,
    meta: {
      order: 2,
      tabTitle: 'customization',
      userCanAccess: () =>
        store.getters.isAdminUser && store.getters.isChannelUser,
      navigationConfig: () => [],
    },
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: DashboardView,
    meta: {
      order: 0,
      tabTitle: 'dashboard',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/clients',
    name: 'ClientsView',
    component: ClientsView,
    meta: {
      order: 1,
      tabTitle: 'clients',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/createClient',
    name: 'CreateClientView',
    component: CreateClientView,
    meta: {
      order: 2,
      tabTitle: 'clientCreate',
      userCanAccess: () =>
        store.getters.isAdminUser || store.getters.isCommercialUser,
      navigationConfig: () => [],
    },
  },
  {
    path: '/CollectiveBilling',
    name: 'CollectiveBillingView',
    component: CollectiveBillingView,
    meta: {
      order: 2,
      tabTitle: 'collectiveBilling',
      userCanAccess: () =>
        store.getters.isAdminUser ||
        store.getters.isCommisCollectiveUserercialUser,
      navigationConfig: () => [],
    },
  },
  {
    path: '/documentation',
    name: 'DocumentationView',
    component: DocumentationView,
    meta: {
      order: 3,
      tabTitle: 'documentation',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/news',
    name: 'NewsView',
    component: NewsView,
    meta: {
      order: 4,
      tabTitle: 'news',
      userCanAccess: () =>
        store.getters.isAddaliaAdminUser || store.getters.isMarketingUser,
      navigationConfig: () => [],
    },
  },
  {
    path: '/configuration',
    name: 'ConfigurationView',
    component: ConfigurationView,
    meta: {
      order: 5,
      tabTitle: 'appConfiguration',
      userCanAccess: () => store.getters.isAddaliaUser,
      navigationConfig: () => [],
    },
  },
  {
    path: '/clients/:id',
    name: 'ClientDetailView',
    component: ClientDetailView,
    meta: {
      order: 6,
      tabTitle: 'clientDetail',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/createUser',
    name: 'CreateUserView',
    component: CreateUserView,
    meta: {
      order: 7,
      tabTitle: 'createUser',
      userCanAccess: () =>
        store.getters.isMainUser &&
        (store.getters.isAdminUser || store.getters.isCommercialUser),
      navigationConfig: () => [],
    },
  },
  {
    path: '/users',
    name: 'UsersView',
    component: UsersView,
    meta: {
      order: 8,
      tabTitle: 'clients',
      userCanAccess: () =>
        store.getters.isMainUser &&
        (store.getters.isAdminUser || store.getters.isCommercialUser),
      navigationConfig: () => [],
    },
  },
  {
    path: '/balance',
    name: 'BalanceView',
    component: BalanceView,
    meta: {
      tabTitle: 'profile',
      userCanAccess: () =>
        store.getters.isMainUser && store.getters.isWholesalerUser,
      navigationConfig: () => [],
    },
  },
  {
    path: '*',
    name: 'NotFoundView',
    component: NotFound,
    meta: {
      tabTitle: 'notFound',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
]

export default routes
