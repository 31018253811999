import i18n from '@/i18n'
import validIdNumber from '@/utils/validate-id-number'
import { phone } from 'phone'

export default {
  // COMMON
  required: (value) => !!value || i18n.t('requiredField'),
  fileRequired: (value) => value.length !== 0 || i18n.t('requiredField'),
  notEmpty: (value) =>
    (value !== '' && value !== undefined && value !== null) ||
    i18n.t('notEmpty'),
  notEmptyNumber: (value) =>
    (value !== '' && value !== undefined && value !== null && value > 0) ||
    i18n.t('notEmpty'),
  listNotEmpty: (value) =>
    (value !== '' &&
      value !== undefined &&
      value !== null &&
      value.length > 0) ||
    i18n.t('notEmpty'),
  maxCharacters: (maxLength) => {
    return (value) =>
      value?.length <= maxLength || !value || i18n.t('overCharacters')
  },
  maxNumberValue: (maxValue) => {
    return (value) =>
      parseInt(value) <= maxValue ||
      !value ||
      i18n.t('overNumberValue') + ' ' + maxValue
  },
  numCharacters: (num) => {
    return (value) =>
      value?.length === num || !value || i18n.t('incorrectCharacters')
  },
  startByCharacters: (startAllowed) => {
    return (value) =>
      startAllowed.some((s) => value?.startsWith(s)) ||
      !value ||
      i18n.t('incorrectStart') + startAllowed.join(', ')
  },
  unique: (values, str) => {
    return (value) => !values.some((v) => value === v) || i18n.t(str)
  },
  equal: (value, str) => {
    return (v) => v === value || i18n.t(str)
  },
  onlyNumbers: (value) => !/\D/.test(value) || !value || i18n.t('onlyNumbers'),
  // USER
  idNumber: (type, value) =>
    (value && validIdNumber(type, value)) || i18n.t('notValidTaxNumberError'),
  ssNumber: (value) =>
    !value ||
    (value && validIdNumber('NSS', value)) ||
    i18n.t('notValidNumberError'),
  email: (value) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !value || regex.test(value) || i18n.t('invalidEmail')
  },
  cifEmailNotExists: (emails, str) => {
    return (value) => !emails.some((email) => value === email) || i18n.t(str)
  },
  phonePrefix: (value) =>
    (value &&
      !!value.prefix &&
      !!value.state &&
      value.prefix.length > 0 &&
      value.state.length > 0) ||
    i18n.t('requiredField'),
  phoneNumber: (value) =>
    phone(value, {
      validateMobilePrefix: false,
    }).isValid || i18n.t('invalidPhoneNumber'),
  mobilePhoneNumber: (value) =>
    phone(value, {
      validateMobilePrefix: true,
    }).isValid || i18n.t('invalidPhoneNumber'),
  phoneNumberOrEmpty: (value) =>
    phone(value, {
      validateMobilePrefix: false,
    }).isValid ||
    !value ||
    i18n.t('invalidPhoneNumber'),
  mobilePhoneNumberOrEmpty: (value) =>
    phone(value, {
      validateMobilePrefix: true,
    }).isValid ||
    !value ||
    i18n.t('invalidPhoneNumber'),
  // SETTINGS
  passwordLength: (value) =>
    (value && value === undefined) ||
    (value && value.length >= 8) ||
    i18n.t('passRequirements'),
  passwordNotRepeated: (newPassword) => {
    return (value) =>
      (value && value === newPassword) || i18n.t('incorrectRepeatPassword')
  },
  passwordStrong: (value) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*'"-+-~_,.|/:;<=>(){}]).{8,}$/.test(
      value
    ) || i18n.t('passwordHint'),
}
