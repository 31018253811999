<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" md="12" :lg="isCollectiveUser ? 10 : 10">
        <component
          :is="isCollectiveUser ? 'CreateClientCollective' : 'CreateClient'"
        ></component>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// @ is an alias to /src
/*eslint-disable*/
import CreateClient from '@/components/client/createClient/CreateClient.vue'
import CreateClientCollective from '@/components/client/createClientCollective/CreateClientCollective.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateClientView',
  components: {
    CreateClient,
    CreateClientCollective,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isCollectiveUser']),
  },
}
</script>
