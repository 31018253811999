<template>
  <v-card class="fill-height" v-if="client" outlined>
    <v-card-title>
      <v-icon left>mdi-domain</v-icon>
      {{ $t('client.clientDetail.companyData') }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="companyDataForm"
        v-model="validForm"
        lazy-validation
        @submit.prevent="onSubmit"
      >
        <v-row justify="space-between">
          <v-col cols="12">
            <div class="text-start">
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <template v-if="editCompanyFlag">
                    <v-text-field
                      v-model="client.empresa"
                      :placeholder="clientProp.empresa"
                      :label="$t('client.clientDetail.company')"
                      class="ma-0 pa-0"
                      dense
                      :disabled="confirmLoading"
                      :rules="[notEmpty]"
                      id="empresa-input"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b>{{ $t('client.clientDetail.company') }}: </b>
                    </span>
                    <span class="body-1 text-end">
                      {{ clientProp.empresa }}
                    </span>
                  </template>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <span class="body-1">
                    <b>{{ $t('client.clientDetail.companyType') }}: </b>
                  </span>
                  <span class="body-1 text-end">
                    {{ clientProp.tipoEmpresa }}
                  </span>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <span class="body-1">
                    <b> {{ $t('client.clientDetail.idNumberType') }}: </b>
                  </span>
                  <span class="body-1 text-end">
                    {{ clientProp.tipoDocIdentidadEmpresa }}
                  </span>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <span class="body-1">
                    <b>{{ $t('client.clientDetail.idNumber') }}: </b>
                  </span>
                  <span class="body-1 text-end">
                    {{ clientProp.numeroDocIdentidadEmpresa }}
                  </span>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ml-1 mt-2 ps-0">
                  <template v-if="editCompanyFlag">
                    <v-text-field
                      v-model="client.emailEmpresa"
                      :placeholder="clientProp.emailEmpresa"
                      :label="$t('client.clientDetail.email')"
                      :disabled="confirmLoading"
                      :rules="[notEmpty, email]"
                      class="ma-0 pa-0"
                      dense
                      id="email-empresa-input"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b>{{ $t('client.clientDetail.email') }}: </b>
                    </span>
                    <span class="body-1 text-end">
                      {{ clientProp.emailEmpresa }}
                    </span>
                  </template>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ml-1 mt-1 ps-0">
                  <template v-if="editCompanyFlag">
                    <v-text-field
                      v-model="client.telefonoEmpresa"
                      :placeholder="clientProp.telefonoEmpresa"
                      :label="$t('client.clientDetail.phone')"
                      :disabled="confirmLoading"
                      :rules="[phoneNumber]"
                      class="ma-0 pa-0"
                      dense
                      id="telefono-empresa-input"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b>{{ $t('client.clientDetail.phone') }}: </b>
                    </span>
                    <span class="body-1 text-end">
                      {{ clientProp.telefonoEmpresa }}
                    </span>
                  </template>
                </v-row>
              </v-col>
              <v-col v-if="isAddaliaAdminUser || isSupportUser">
                <v-row class="ml-1 mt-1 ps-0">
                  <template v-if="editCompanyFlag">
                    <v-text-field
                      v-model="client.redmineKey"
                      :placeholder="clientProp.redmineKey"
                      :label="$t('client.clientDetail.redmineKey')"
                      :disabled="confirmLoading"
                      class="ma-0 pa-0"
                      dense
                      id="redminekey-empresa-input"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b>{{ $t('client.clientDetail.redmineKey') }}: </b>
                    </span>
                    <span class="body-1 text-end">
                      {{ clientProp.redmineKey }}
                    </span>
                  </template>
                </v-row>
              </v-col>
              <v-col v-if="isAddaliaAdminUser || isSupportUser">
                <v-row class="ml-1 mt-1 ps-0">
                  <template v-if="editCompanyFlag">
                    <v-text-field
                      type="number"
                      v-model.number="client.redmineProject"
                      :placeholder="clientProp.redmineProject?.toString()"
                      :label="$t('client.clientDetail.redmineProject')"
                      :disabled="confirmLoading"
                      class="ma-0 pa-0"
                      dense
                      id="redmineproject-empresa-input"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <span class="body-1">
                      <b>{{ $t('client.clientDetail.redmineProject') }}: </b>
                    </span>
                    <span class="body-1 text-end">
                      {{ clientProp.redmineProject }}
                    </span>
                  </template>
                </v-row>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="allowToPerformAction('changeClientInformation')">
      <v-btn
        v-if="!editCompanyFlag"
        @click="editCompanyFlag = true"
        text
        :disabled="confirmLoading"
        color="primary"
        id="edit-company-button"
      >
        {{ $t('client.clientDetail.edit') }}
      </v-btn>
      <v-btn
        v-else
        @click="editCompanyData()"
        text
        color="primary"
        :loading="confirmLoading"
        :disabled="confirmLoading || !userChanges"
        id="save-company-data"
      >
        {{ $t('client.clientDetail.save') }}
      </v-btn>
      <v-btn
        v-if="editCompanyFlag && !confirmLoading"
        @click="cancelEdit"
        text
        color="red"
        :disabled="confirmLoading"
      >
        {{ $t('cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { allowToPerformAction } from '@/services/users-service'
import { Firestore } from '@/firebase-exports'
import { updateEmpresa, updateClient } from '@/services/clients-service'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import rules from '@/utils/rules'

export default {
  props: {
    clientProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validForm: false,
      client: { ...this.clientProp },
      confirmLoading: false,
      editCompanyFlag: false,
      clientTypes: ['ASESORIA', 'EMPRESA'],
      docTypes: ['DNI', 'NIE', 'CIF', 'NIF-M'],
    }
  },
  computed: {
    ...mapGetters(['isAddaliaUser', 'isAddaliaAdminUser', 'isSupportUser']),
    userChanges() {
      const editableVariables = [
        'empresa',
        'emailEmpresa',
        'telefonoEmpresa',
        'redmineKey',
        'redmineProject',
      ]

      for (const variable of editableVariables) {
        if (this.client[variable] !== this.clientProp[variable]) {
          return true
        }
      }

      return false
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    allowToPerformAction(action) {
      return allowToPerformAction(this.$store.state.user.rol, action)
    },
    cancelEdit() {
      this.client = { ...this.clientProp }
      this.editCompanyFlag = false
    },
    async editCompanyData() {
      if (!this.$refs.companyDataForm?.validate()) return

      this.confirmLoading = true

      try {
        // Añadimos los campos

        const dataUpdate = {
          ...(this.client.empresa != this.clientProp.empresa
            ? { newEmpresa: this.client.empresa }
            : {}),
          ...(this.client.emailEmpresa != this.clientProp.emailEmpresa
            ? { newEmailEmpresa: this.client.emailEmpresa }
            : {}),
          ...(this.client.telefonoEmpresa != this.clientProp.telefonoEmpresa
            ? { newTelefonoEmpresa: this.client.telefonoEmpresa }
            : {}),
        }

        // Comprobamos que haya cambios antes de llamar a la función
        if (Object.keys(dataUpdate).length)
          await updateEmpresa(this.$route.params.id, dataUpdate)

        // Comprobamos si han cambiado los datos de Redmine
        if (
          this.client.redmineKey != this.clientProp.redmineKey ||
          this.client.redmineProject != this.clientProp.redmineProject
        ) {
          await updateClient(this.client.id, {
            redmineKey: this.client.redmineKey || Firestore.deleteField(),
            redmineProject:
              this.client.redmineProject || Firestore.deleteField(),
          })
        }

        this.editCompanyFlag = !this.editCompanyFlag
        this.confirmLoading = false

        // Actualizamos variable local con los nuevos valores
        this.client = { ...this.clientProp }

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('editSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.confirmLoading = false
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
