<template>
  <div>
    <apexChart
      type="line"
      height="350"
      :options="chartOptionsBalance"
      :series="balanceData"
    >
    </apexChart>
    <apexChart
      type="line"
      height="200"
      :options="chartOptionsClients"
      :series="clientsData"
    >
    </apexChart>
  </div>
</template>

<script>
export default {
  props: {
    trace: {
      type: Array,
      mandatory: true,
    },
    clientsCreationDates: {
      type: Array,
      mandatory: true,
    },
    clientsCreationCounts: {
      type: Array,
      mandatory: true,
    },
  },
  data() {
    return {
      auxVar: {},
    }
  },
  computed: {
    chartOptionsBalance() {
      return {
        chart: {
          type: 'line',
          height: 350,
        },
        stroke: {
          curve: 'stepline',
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: this.$t('charts.wholesalerBalance'),
          align: 'left',
        },
        markers: {
          hover: {
            sizeOffset: 4,
          },
        },
        xaxis: {
          categories: this.datesBalanceHistory,
        },
        tooltip: {
          shared: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            this.customSharedTooltip({
              series,
              seriesIndex,
              dataPointIndex,
              w,
            })
            return (
              '<ul align="left">' +
              '<li><b>createdAt</b>' +
              this.$d(this.auxVar.createdAt) +
              '</li>' +
              '<li><b>notifications</b>: ' +
              this.auxVar.notifications +
              '</li>' +
              '<li><b>notificationsBalanceResult</b>: ' +
              this.auxVar.notificationsBalanceResult +
              '</li>' +
              '<li><b>tokens</b>: ' +
              this.auxVar.tokens +
              '</li>' +
              '<li><b>tokensBalanceResult</b>: ' +
              this.auxVar.tokensBalanceResult +
              '</li>' +
              '<li><b>type</b>: ' +
              this.auxVar.type +
              '</li>' +
              '</ul>'
            )
          },
        },
      }
    },
    balanceData() {
      return [
        {
          name: this.$t('charts.notificationsBalance'),
          data: this.notificationsBalanceHistory,
        },
        {
          name: this.$t('charts.tokensBalance'),
          data: this.tokensBalanceHistory,
        },
      ]
    },
    chartOptionsClients() {
      return {
        chart: {
          height: 200,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: this.$t('charts.wholesalerClientCreation'),
          align: 'left',
        },
        stroke: {
          curve: 'straight',
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: this.clientsCreationDates,
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
            },
          },
        ],
      }
    },
    clientsData() {
      return [
        {
          name: this.$t('charts.clientCreation'),
          data: this.clientsCreationCounts,
        },
      ]
    },

    notificationsBalanceHistory() {
      return this.trace
        .map((obj) => obj.notificationsBalanceResult) // Extraer los balances
        .filter((balance) => typeof balance === 'number')
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Ordenar por fecha
        .reverse()
    },
    tokensBalanceHistory() {
      return this.trace
        .map((obj) => obj.tokensBalanceResult) // Extraer los balances
        .filter((balance) => typeof balance === 'number')
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Ordenar por fecha
        .reverse()
    },
    datesBalanceHistory() {
      return this.trace
        .map((obj) => this.$d(obj.createdAt)) // Extraer los balances
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Ordenar por fecha
        .reverse()
    },
  },
  methods: {
    customSharedTooltip({ dataPointIndex }) {
      this.auxVar = this.trace[dataPointIndex]
    },
  },
}
</script>

<style lang="scss" scoped></style>
