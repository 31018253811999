<template>
  <v-card elevation="1" :class="{ selected: isSelected }">
    <v-container>
      <v-row @click="toggleSelection" align="center" justify="space-between">
        <v-col cols="1">
          <v-icon color="primary" v-if="isSelected"> mdi-check-circle </v-icon>
          <v-icon id="signatures-service" v-else>mdi-circle-outline</v-icon>

          <v-icon x-large> mdi-draw </v-icon>
        </v-col>
        <v-col cols="11">
          <h2>{{ $t('signatures') }}</h2>
          <p>{{ $t('signaturesDescription') }}</p>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="isSelected" justify="center" align="center">
          <v-col cols="12" md="5" lg="5">
            <PackagesLicenses
              :fixedDiscount="fixedDiscount"
              :name="$t('numberCertificates')"
              :label="$t('numberCertificates')"
              :prices="pricesCerts"
              :packagesList="packagesListCerts"
              resultEventName="signaturesCertsPackages"
              @signaturesCertsPackages="signaturesCertsPackagesEvent"
            />
          </v-col>

          <v-col cols="auto"> <v-icon x-large>mdi-plus</v-icon> </v-col>
          <v-col cols="12" md="5" lg="5">
            <PackagesLicenses
              :fixedDiscount="fixedDiscount"
              :name="$t('signaturePackages')"
              :label="$t('numberOfSignatures')"
              :prices="pricesSignatures"
              :packagesList="packagesListSignatures"
              resultEventName="signaturesPackages"
              @signaturesPackages="signaturesPackagesEvent"
            />
          </v-col>
          <v-col cols="12">
            <h2 class="mt-4 mb-4">
              <span class="final-price-label">
                {{ $t('client.productSignaturesCard.finalPrice') }}
              </span>
              <span class="final-price-value">
                {{
                  $n(
                    signaturesCertsPackagesPrice + signaturesPackagesPrice,
                    'i18nAmount'
                  )
                }}
                €
              </span>
            </h2>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>
  </v-card>
</template>

<script>
import PackagesLicenses from '@/components/client/createClient/PackagesLicenses.vue'

export default {
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
    pricesCerts: {
      type: Array,
    },
    packagesListCerts: {
      type: Array,
    },
    pricesSignatures: {
      type: Array,
    },
    packagesListSignatures: {
      type: Array,
    },
    fixedDiscount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PackagesLicenses,
  },
  data() {
    return {
      selectedPlan: 0,
      signaturesCertsPackagesPrice: 0,
      signaturesPackagesPrice: 0,
    }
  },
  methods: {
    toggleSelection() {
      this.$emit('selectProductSignaturesEvent')
    },
    selectPlan(data) {
      this.selectedPlan = data.id
    },
    signaturesCertsPackagesEvent(newValue) {
      this.signaturesCertsPackagesPrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('signaturesCertsPackages', newValue)
    },
    signaturesPackagesEvent(newValue) {
      this.signaturesPackagesPrice = Number(
        newValue.price - newValue.discountAmount
      )
      this.$emit('signaturesPackages', newValue)
    },
  },
}
</script>

<style scoped>
.selected {
  border: 1px solid var(--v-primary-base);
}
.v-expand-transition {
  transition: height 0.3s ease;
  overflow: hidden;
}
</style>
