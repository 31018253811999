<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="ma-1 orange--text" plain>
          {{ $t('user.profile.changePassword') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('user.profile.changePassword') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="changePasswordForm" v-model="valid">
            <v-text-field
              v-model="currentPassword"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('user.profile.currentPassword')"
              @click:append="show2 = !show2"
            >
            </v-text-field>
            <v-text-field
              v-model="newPassword"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('user.profile.newPassword')"
              :hint="$t('passwordHint')"
              @click:append="show1 = !show1"
            >
            </v-text-field>
            <v-text-field
              v-model="newPasswordRepeat"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRepeatRules"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('user.profile.repeatNewPassword')"
              @click:append="show1 = !show1"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            color="primary"
            text
            @click="updatePassword()"
          >
            {{ $t('user.profile.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import rules from '@/utils/rules'
import { Auth } from '@/firebase-exports'
import getErrorText from '@/utils/get-error-text'
import { updateUserPassword } from '@/services/users-service'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      dialog: false,
      show1: false,
      show2: false,
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      passwordRules: [
        rules.required,
        (v) => rules.passwordStrong(v) || this.$t('passwordHint'),
        (v) => !!v || this.$t('invalidPassword'),
      ],
      passwordRepeatRules: [
        rules.required,
        (v) => v == this.newPassword || this.$t('incorrectRepeatPassword'),
        (v) => !!v || this.$t('invalidPassword'),
      ],
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),

    async updatePassword() {
      if (!this.$refs.changePasswordForm?.validate()) return

      this.loading = true
      try {
        const user = Auth.getAuth().currentUser

        try {
          await Auth.reauthenticateWithCredential(
            user,
            Auth.EmailAuthProvider.credential(
              this.$store.state.user.email,
              this.currentPassword
            )
          )

          await Auth.updatePassword(user, this.newPassword)
        } catch (err) {
          if (err.message.includes('auth/multi-factor-auth-required')) {
            try {
              await updateUserPassword({
                newPassword: this.newPassword,
              })

              setTimeout(async () => {
                await this.logout()
                this.$router.push({ name: 'LoginView' })
              }, 2000)
            } catch (err) {
              const message = getErrorText(err.message)
              this.setSnackbar({ position: 'top', type: 'error', message })
            }
          } else {
            throw new Error(err.message)
          }
        }

        const message = this.$t('user.profile.updatingPasswordSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })

        this.dialog = false
        this.$refs.changePasswordForm.reset()
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
