<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <ConfigurationContent v-if="!loading" :billing="billing" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfigurationContent from '@/components/configuration/ConfigurationContent.vue'
import { billingSubscription } from '@/services/billing-service'

export default {
  components: {
    ConfigurationContent,
  },

  data() {
    return {
      loading: true,
      billing: {},
      billingUnsub: () => {},
    }
  },
  async created() {
    this.billingUnsub = await billingSubscription(this)
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  async unmounted() {
    if (this.billingUnsub) this.billingUnsub()
  },
}
</script>

<style lang="scss" scoped></style>
