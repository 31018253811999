var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(!_vm.$route.query.section)?_c('CreateClientDoc'):_vm._e(),(
      _vm.$route.query.section === 'home' && _vm.$route.query.subsection === 'create'
    )?_c('CreateClientDoc'):_vm._e(),(
      _vm.$route.query.section === 'home' && _vm.$route.query.subsection === 'update'
    )?_c('UpdateClientDoc'):_vm._e(),(
      _vm.$route.query.section === 'home' && _vm.$route.query.subsection === 'delete'
    )?_c('DeleteClientDoc'):_vm._e(),(_vm.$route.query.section === 'services' && !_vm.$route.query.subsection)?_c('ActivateServicesDoc'):_vm._e(),(
      _vm.$route.query.section === 'services' &&
      _vm.$route.query.subsection === 'activation'
    )?_c('ActivateServicesDoc'):_vm._e(),(
      _vm.$route.query.section === 'services' &&
      _vm.$route.query.subsection === 'renewal'
    )?_c('RenewServicesDoc'):_vm._e(),(
      _vm.$route.query.section === 'services' &&
      _vm.$route.query.subsection === 'upgrade'
    )?_c('UpgradeServicesDoc'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }