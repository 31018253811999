import { Firestore, NewsCollection, NewsDoc } from '@/firebase-exports'
import i18n from '@/i18n'
import store from '@/store/index'

/**
 * Crea una nueva noticia en la base de datos.
 *
 * @param {Object} newData - Los datos de la nueva noticia.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de creación.
 */
export async function createNew(newData) {
  return await Firestore.setDoc(
    Firestore.doc(NewsCollection()),
    {
      title: newData.title,
      body: newData.body,
      url: newData.url,
      releaseDate: new Date(newData.releaseDate),
      endDate: newData.endDate
        ? new Date(newData.endDate)
        : Firestore.deleteField(),
      targetUser: newData.targetUser,
      targetProduct: newData.targetProduct,
      mandatory: newData.mandatory,
      push: newData.push,
      usersRead: [],
    },
    { merge: true }
  )
}

/**
 * Actualiza una noticia existente.
 *
 * @param {string} newId - El ID de la noticia a actualizar.
 * @param {Object} newData - Los nuevos datos de la noticia.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de actualización.
 */
export async function updateNew(newId, newData) {
  return await Firestore.updateDoc(NewsDoc(newId), {
    title: newData.title,
    body: newData.body,
    url: newData.url,
    releaseDate: new Date(newData.releaseDate),
    endDate: newData.endDate
      ? new Date(newData.endDate)
      : Firestore.deleteField(),
    targetUser: newData.targetUser,
    targetProduct: newData.targetProduct,
    mandatory: newData.mandatory,
    push: newData.push,
  })
}

/**
 * Elimina una noticia específica.
 *
 * @param {string} newId - El ID de la noticia a eliminar.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la noticia ha sido eliminada.
 */
export async function deleteNew(newId) {
  return await Firestore.deleteDoc(NewsDoc(newId))
}

export function getTargetUsers() {
  return [
    {
      id: 'admin',
      text: i18n.t('news.targetUsers.admin'),
    },
    {
      id: 'operador',
      text: i18n.t('news.targetUsers.operador'),
    },
    {
      id: 'cliente',
      text: i18n.t('news.targetUsers.cliente'),
    },
    {
      id: 'managementAdmin',
      text: i18n.t('news.targetUsers.managementAdmin'),
    },
    {
      id: 'comercial',
      text: i18n.t('news.targetUsers.comercial'),
    },
    {
      id: 'agent',
      text: i18n.t('news.targetUsers.agent'),
    },
    {
      id: 'distributor',
      text: i18n.t('news.targetUsers.distributor'),
    },
    {
      id: 'wholesaler',
      text: i18n.t('news.targetUsers.wholesaler'),
    },
  ]
}

export function getTargetProducts() {
  return [
    {
      id: 'notifications',
      text: i18n.t('news.targetProducts.notifications'),
    },
    {
      id: 'signatures',
      text: i18n.t('news.targetProducts.signatures'),
    },
  ]
}

/**
 * Crea una subscripción para el administrador de noticias, actualizando el contexto con los cambios en tiempo real.
 *
 * @param {Object} context - Contexto del componente donde se realiza la subscripción.
 * @returns {Function} Función para cancelar la subscripción.
 */
export async function newsManagerSubscription(context) {
  const unsub = Firestore.onSnapshot(
    Firestore.query(NewsCollection(), Firestore.orderBy('releaseDate', 'desc')),
    async (querySnapshot) => {
      context.news = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()

          if (document.releaseDate)
            document.releaseDate = document.releaseDate.toDate()

          if (document.endDate) document.endDate = document.endDate.toDate()

          context['menu_releaseDate_' + document.id] = false
          context['menu_endDate_' + document.id] = false

          return { id: doc.id, ...document }
        })
      )
      context.panel = undefined
    }
  )

  return unsub
}

/**
 * Crea una subscripción a las noticias para un usuario, filtrando por roles y fecha.
 *
 * @param {number} [days=180] - Número de días hacia atrás desde hoy para incluir en la subscripción.
 * @returns {Function} Función para cancelar la subscripción, o undefined si el usuario no coincide con ningún rol.
 */
export async function newsSubscription(days = 180) {
  var userRol = []
  if (store.getters.isAdminUser) userRol.push('managementAdmin')
  if (store.getters.isCommercialUser) userRol.push('comercial')
  if (store.getters.isAgentUser) userRol.push('agent')
  if (store.getters.isDistributorUser) userRol.push('distributor')
  if (store.getters.isWholesalerUser) userRol.push('wholesaler')

  // User not matches any rol
  if (!userRol.length) return

  const todaysDate = new Date()

  const from = new Date().setDate(todaysDate.getDate() - days)
  const fromDate = new Date(from)

  const to = new Date().setDate(todaysDate.getDate())
  const toDate = new Date(to)

  const unsub = Firestore.onSnapshot(
    days != 0
      ? Firestore.query(
          NewsCollection(),
          Firestore.where('targetUser', 'array-contains-any', userRol),
          Firestore.orderBy('releaseDate', 'desc'),
          Firestore.startAt(toDate),
          Firestore.endAt(fromDate)
        )
      : Firestore.query(
          NewsCollection(),
          Firestore.where('targetUser', 'array-contains-any', userRol),
          Firestore.orderBy('releaseDate', 'desc')
        ),
    async (querySnapshot) => {
      let news = (
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const document = doc.data()

            if (document.releaseDate)
              document.releaseDate = document.releaseDate.toDate()

            if (document.endDate) document.endDate = document.endDate.toDate()

            return { id: doc.id, ...document }
          })
        )
      )
        .filter((element) => {
          // To skip undefined elements in the promise map than not target the product
          return element !== undefined
        })
        .filter((element) => {
          // To skip elements with end date elapsed
          return element.endDate
            ? new Date().setHours(0, 0, 0, 0) <= element.endDate
            : true
        })

      // Guardamos en el store
      store.commit('setNews', news)
    }
  )

  store.commit('setNewsUnsubscribe', unsub)
  return unsub
}

/**
 * Marca una noticia como leída por el usuario actual.
 *
 * @param {string} newId - El ID de la noticia a marcar como leída.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la noticia ha sido marcada como leída.
 */
export async function markAsRead(newId) {
  return await Firestore.updateDoc(NewsDoc(newId), {
    usersRead: Firestore.arrayUnion(store.state.user.id),
  })
}
