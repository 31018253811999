<template>
  <v-fade-transition mode="out-in">
    <v-skeleton-loader v-if="condition" :type="type" :types="customTypes" />
    <slot name="skeleton-content" v-else />
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    condition: Boolean,
    type: String,
  },
  data() {
    return {
      customTypes: {
        assignChannel: 'list-item',
        assignComercial: 'list-item',
        clientList: 'table',
        clientDetail: 'list-item-two-line@8, actions',
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
