import { Firestore, HistoryManagementCollection } from '@/firebase-exports'
import store from '@/store/index'

export async function listHistorySubscription(userId, context, order = 'asc') {
  var historyUnsubscribe = Firestore.onSnapshot(
    Firestore.query(
      HistoryManagementCollection(userId),
      Firestore.orderBy('createdAt', order)
    ),
    async (querySnapshot) => {
      let history = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data()
          return { id: doc.id, ...data }
        })
      )
      // Guardamos en el store
      store.commit('setHistory', history)
    },
    (error) => {
      throw error
    }
  )
  return historyUnsubscribe
}
