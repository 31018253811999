<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="12" lg="3">
        <ProfileInfo height="740" :user="user"></ProfileInfo>
      </v-col>
      <v-col cols="12" lg="9">
        <ProfileCharts height="400"></ProfileCharts>
        <v-row justify="center">
          <v-col cols="12" lg="7">
            <UpcomingRenewals :clients="clients" height="210">
            </UpcomingRenewals>
          </v-col>
          <v-col cols="12" lg="5">
            <CommercialsChart
              :clients="clients"
              height="334"
            ></CommercialsChart>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ProfileInfo from '@/components/profile/ProfileInfo.vue'
import ProfileCharts from '@/components/profile/ProfileCharts.vue'
import CommercialsChart from '@/components/profile/charts/CommercialsChart.vue'
import UpcomingRenewals from '@/components/profile/UpcomingRenewals.vue'

export default {
  name: 'ProfileView',
  components: {
    ProfileInfo,
    ProfileCharts,
    UpcomingRenewals,
    CommercialsChart,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['user', 'clients']),
  },
}
</script>
