var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","no-glutters":""}},[_c('v-row',{attrs:{"justify":"space-around"}},_vm._l((_vm.services),function(item){return _c('v-col',{key:item.concept,attrs:{"cols":"12","md":"6","lg":item.selected ? 6 : _vm.isConfiguration ? 4 : 6}},[_c('v-card',{staticClass:"pa-2"},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"},on:{"click":function($event){item.selected = !item.selected}}},[_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.isConfiguration && item.selected)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check-circle ")]):(!_vm.isConfiguration)?_c('v-icon',{attrs:{"id":item.concept == 'multiFactor'
                    ? 'select-2FA'
                    : 'select-custom-brand'}},[_vm._v(" mdi-circle-outline ")]):_vm._e(),_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" "+_vm._s(item.concept == 'multiFactor' ? 'mdi-two-factor-authentication' : 'mdi-palette')+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('client.additionalServices.' + item.concept)))])])],1),(_vm.isConfiguration)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[(item.concept === 'multiFactor')?_c('v-text-field',{attrs:{"rules":[_vm.notEmptyNumber],"placeholder":_vm.$t('price') + '(€)'},model:{value:(_vm.multiFactorPriceModel),callback:function ($$v) {_vm.multiFactorPriceModel=$$v},expression:"multiFactorPriceModel"}},[_vm._v(" € ")]):_c('v-text-field',{attrs:{"rules":[_vm.notEmptyNumber],"placeholder":_vm.$t('price') + '(€)'},model:{value:(_vm.customBrandPriceModel),callback:function ($$v) {_vm.customBrandPriceModel=$$v},expression:"customBrandPriceModel"}},[_vm._v(" € ")]),(
                  item.concept === 'multiFactor'
                    ? _vm.checkButton2FARulesComputed
                    : _vm.checkButtonCustomBrandRulesComputed
                )?_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeCuotaPrice(item.concept)}}},[_vm._v(" "+_vm._s(_vm.$t('client.customBrand.save'))+" ")]):_vm._e()],1)],1):_c('v-expand-transition',[(item.selected)?_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-slider',{staticClass:"mt-5",attrs:{"disabled":_vm.isChannelUser,"label":_vm.$t('client.cuotaCard.discount'),"thumb-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},scopedSlots:_vm._u([{key:"thumb-label",fn:function({ value }){return [_vm._v(" "+_vm._s(value)+"% ")]}}],null,true),model:{value:(item.discountPercentage),callback:function ($$v) {_vm.$set(item, "discountPercentage", $$v)},expression:"item.discountPercentage"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$n(item.price, 'i18nAmount'))+"€ - "+_vm._s(item.discountPercentage)+"% = "+_vm._s(_vm.$n( item.price - (item.price * item.discountPercentage) / 100, 'i18nAmount' ))+" € ")])])],1):_vm._e()],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }