import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from '@/i18n'
import { App as FirebaseApp, Analytics } from '@/firebase-exports'
import AppSkeleton from '@/components/commons/AppSkeleton'
import './registerServiceWorker'
import VueApexCharts from 'vue-apexcharts'

Analytics.getAnalytics(FirebaseApp)

Vue.component('AppSkeleton', AppSkeleton)
Vue.use(VueApexCharts)
Vue.component('apexChart', VueApexCharts)

Vue.config.productionTip = false
new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
