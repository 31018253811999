var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto sticky-card"},[_c('v-row',{staticClass:"d-flex justify-start pa-0 ma-0",attrs:{"sticky":""}},[_c('v-list',_vm._l((_vm.sectionTitles),function(section,i){return _c('v-list-group',{key:i,attrs:{"disabled":section.disabled,"append-icon":section.disabled
            ? 'mdi-lock'
            : section.subSections.length > 0
            ? 'mdi-menu-down'
            : ''},on:{"click":function($event){$event.stopPropagation();return (() => {
            if (section.disabled) {
              return
            }
            if (section.subSections.length == 0) _vm.goDoc(section)
          }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"large"},attrs:{"id":'documentation-' + section.title},on:{"click":function($event){$event.stopPropagation();return (() => {
                  if (section.disabled) return
                  _vm.goDoc(section)
                }).apply(null, arguments)}}},[_c('b',[_vm._v(_vm._s(_vm.$t('documentation.titles.' + section.title + '.title')))])])],1)]},proxy:true}],null,true),model:{value:(section.active),callback:function ($$v) {_vm.$set(section, "active", $$v)},expression:"section.active"}},[(!section.disabled)?_vm._l((section.subSections),function(subsection,n){return _c('v-list-item',{key:n,on:{"click":function($event){$event.stopPropagation();return _vm.goDoc(section, subsection)}}},[_c('v-list-item-content',[_c('v-list-item-title',{class:'pl-2 ' +
                  (subsection === _vm.$route.query?.subsection
                    ? 'active-sub-section'
                    : ''),staticStyle:{"font-size":"large"},attrs:{"id":'documentation-' + section.title + '-' + subsection}},[_vm._v(" "+_vm._s(_vm.$t( 'documentation.titles.' + section.title + '.subsections.' + subsection ))+" ")])],1)],1)}):_vm._e()],2)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }