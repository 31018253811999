<template>
  <v-card class="mx-auto">
    <v-container>
      <div class="title">{{ $t('wholesalerBalance.buyPackages') }}</div>

      <v-card-text>
        <v-row class="mb-3" align="center" justify="center">
          <v-col cols="12">
            <PackagesTokens
              :enableDisccount="false"
              :fixedDiscount="0"
              :name="$t('numberOfTokens')"
              :label="$t('numberOfTokens')"
              :packagesList="
                $store.state.appBilling.mayoristaTokenPaquetesCuota
              "
              :prices="$store.state.appBilling.mayoristaTokenPaquetesCuota"
              resultEventName="upgradePackagesTokens"
              @upgradePackagesTokens="upgradePackagesTokens"
            >
            </PackagesTokens>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import PackagesTokens from '@/components/wholesaler/PackagesTokens.vue'

export default {
  components: {
    PackagesTokens,
  },
  methods: {
    upgradePackagesTokens() {
      console.log('upgradePackagesTokens')
    },
  },
}
</script>

<style lang="scss" scoped></style>
