<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col class="scrollable-col" cols="6">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Picker in menu"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" type="month" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-timeline v-if="filteredHistory.length" dense>
          <v-timeline-item v-for="item in filteredHistory" v-bind:key="item.id">
            <v-card class="elevation-2">
              <v-card-title class="text-h5">
                {{ $d(item.createdAt.toDate()) }}
              </v-card-title>
              <v-card-text justify="left">
                <div v-for="(value, key) in item" :key="key">
                  <b>{{ key }}:</b> {{ value }}
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <div v-else>
          <span>{{ $t('noElementsToShow') }}</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-card>
          <h4>
            {{ $t('currentMotnhFacturation') }} {{ date.split('-')[1] }}-{{
              date.split('-')[0]
            }}
          </h4>
          {{ $t('numberNotificationstoHire') }}
          {{ notificationsCurrentAmount }} /
          <b>{{ notificationsCurrentBill }}€</b> <br />
          {{ $t('numberCIFtoHire') }}
          {{ cifsCurrentAmount }} / <b>{{ cifsCurrentBill }}€</b> <br />
          {{ $t('clientPurchases') }}

          {{ clientCreationsCurrentMonth }} /
          <b>
            {{
              clientCreationsCurrentMonth *
              $store.state.user.collectiveData.newClientRegistrationPrice
            }}€
          </b>
          <h3>
            {{ $t('finalPriceMonth') }} <br />
            <h2>
              <b
                >{{
                  parseInt(notificationsCurrentBill) +
                  parseInt(cifsCurrentBill) +
                  parseInt(
                    parseInt(clientCreationsCurrentMonth) *
                      $store.state.user.collectiveData
                        .newClientRegistrationPrice
                  )
                }}
                €</b
              >
            </h2>
          </h3>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  calculateRangePrices,
  notificationsHistoryAmount,
  cifsCurrentAmount,
  getCollectivePrices,
} from '@/utils/prices-utils' //import { getClientWithRef } from '@/services/clients-service'
export default {
  props: {
    history: {
      type: Array,
    },
  },
  created() {
    this.localHistory = [...this.history]
    /*
    this.history.forEach((item, index) => {
      getClientWithRef(item.userRef)
        .then((client) => {
          this.localHistory[index].client = client
        })
        .catch((error) => {
          console.error(
            `Error obteniendo el usuario con ref ${item.userRef}:`,
            error
          )
        })
    })
    */
  },
  data() {
    return {
      localHistory: [],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      collectivePrices: getCollectivePrices(),
    }
  },
  computed: {
    filteredHistory() {
      const [year, month] = this.date.split('-')
      const selectedYear = parseInt(year)
      const selectedMonth = parseInt(month) - 1 // Restamos 1 porque los meses en JavaScript van de 0 (enero) a 11 (diciembre)

      return this.localHistory.filter((item) => {
        const itemDate = item.createdAt.toDate()
        return (
          itemDate.getMonth() === selectedMonth &&
          itemDate.getFullYear() === selectedYear
        )
      })
    },
    clientCreationsCurrentMonth() {
      const [year, month] = this.date.split('-')
      const selectedYear = parseInt(year)
      const selectedMonth = parseInt(month) - 1

      return this.$store.state.history.filter((item) => {
        const itemDate = item.createdAt.toDate()
        return (
          itemDate.getMonth() === selectedMonth &&
          itemDate.getFullYear() === selectedYear &&
          item.actionType == 'clientCreate'
        )
      }).length
    },
    notificationsCurrentAmount() {
      const [year, month] = this.date.split('-')
      const selectedYear = parseInt(year)
      const selectedMonth = parseInt(month) - 1

      var totalNotifications = notificationsHistoryAmount(
        selectedMonth,
        selectedYear,
        this.$store.state.history
      )
      return parseInt(
        totalNotifications -
          parseInt(
            this.clientCreationsCurrentMonth *
              this.$store.state.user.collectiveData.newClientNotifNumber
          )
      )
    },
    notificationsCurrentBill() {
      this.notificationsCurrentAmount

      return calculateRangePrices(
        this.notificationsCurrentAmount,
        this.collectivePrices.notificationPrices,
        this.collectivePrices.notificationGaps
      )
    },
    cifsCurrentAmount() {
      const [year, month] = this.date.split('-')
      const selectedYear = parseInt(year)
      const selectedMonth = parseInt(month) - 1

      const totalCifs = cifsCurrentAmount(
        selectedMonth,
        selectedYear,
        this.$store.state.history
      )
      return parseInt(
        totalCifs -
          parseInt(
            this.clientCreationsCurrentMonth *
              this.$store.state.user.collectiveData.newClientCifsNumber
          )
      )
    },
    cifsCurrentBill() {
      return calculateRangePrices(
        this.cifsCurrentAmount,
        this.collectivePrices.cifPrices,
        this.collectivePrices.cifGaps
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.scrollable-col {
  height: 80vh; /* Altura igual a la altura de la pantalla */
  overflow-y: auto; /* Habilita el desplazamiento vertical si el contenido supera la altura */
}
</style>
