<template>
  <v-container>
    <v-row
      v-if="!isMobile"
      :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
      v-html="welcomeText"
      class="welcome-message"
    >
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-text-field
          class="welcome-message"
          :label="$t('login.user')"
          required
          v-model="username"
          :disabled="loading"
          id="input-username"
        />
      </v-col>
      <v-col cols="12" md="5">
        <v-text-field
          class="welcome-message"
          :label="$t('login.password')"
          :type="showPassword ? 'text' : 'password'"
          autocomplete="on"
          :append-icon="passwordIcons[showPassword]"
          @click:append="showPassword = !showPassword"
          required
          v-model="password"
          :disabled="loading"
          id="input-password"
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="d-flex flex-column justify-center align-center"
      >
        <v-checkbox
          v-if="isMobile"
          v-model="keepSession"
          :disabled="loading"
          :label="$t('login.keepSession')"
          id="input-session"
        />
        <v-btn
          :disabled="loading"
          id="login"
          color="dark"
          @click="login()"
          class="mt-5 terminal-button"
        >
          {{ $t('login.loginButton') }}
        </v-btn>
        <v-checkbox
          v-if="!isMobile"
          v-model="keepSession"
          :disabled="loading"
          :label="$t('login.keepSession')"
          id="input-session"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from '@/firebase-exports'
import getErrorText from '@/utils/get-error-text'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  mounted() {
    this.authData = Auth.getAuth()
  },
  data() {
    return {
      username: '',
      password: '',
      keepSession: false,
      loading: false,
      showPassword: false,
      passwordIcons: { true: 'mdi-eye', false: 'mdi-eye-off' },
      authData: null,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    welcomeText() {
      return `<b>${this.$i18n.t('welcomeTitle')}</b> ${this.$i18n.t(
        'welcomeSubtitle'
      )}`
    },
  },
  methods: {
    ...mapMutations(['setLoading', 'setSnackbar']),
    async login() {
      try {
        this.setLoading(true)
        this.loading = true

        if (!this.keepSession)
          await Auth.setPersistence(
            this.authData,
            Auth.browserSessionPersistence
          )

        await Auth.signInWithEmailAndPassword(
          this.authData,
          this.username,
          this.password
        )
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
        this.setLoading(false)
        this.loading = false
      }
    },
  },
}
</script>

<style>
.welcome-message {
  font-size: 25px;
  font-weight: 200;
  line-height: 1.5;
  padding: 20px;
  text-align: left;
}
.terminal-button {
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 14px;
}
.terminal-button:hover {
  background-color: #383838;
}
.terminal-button:focus {
  outline: none;
}
</style>
