import { Firestore, SettingsCollection, BillingDoc } from '@/firebase-exports'

/**
 * Obtiene la información de facturación.
 *
 * @returns {Promise} Una promesa que se resuelve con la información de facturación si existe.
 */
export async function getBilling() {
  const docSnap = await Firestore.getDoc(BillingDoc())
  if (!docSnap.exists()) return

  const document = docSnap.data()

  return { ...document }
}

/**
 * Actualiza el documento de facturación auxiliar.
 *
 * @param {Object} data - Los datos a actualizar en el documento de facturación auxiliar.
 * @returns {Promise} Una promesa que se resuelve cuando se actualiza el documento de facturación auxiliar.
 */
export async function updateBilling(data) {
  await Firestore.updateDoc(
    Firestore.doc(SettingsCollection(), 'billing'),
    data,
    { merge: true }
  )
}

/**
 * Obtiene la suscripción del documento de facturación auxiliar.
 *
 * @param {Object} data - Los datos para la suscripción del documento de facturación auxiliar.
 * @returns {function} La función de cancelación de la suscripción.
 */
export async function billingSubscription(context) {
  const unsub = Firestore.onSnapshot(
    Firestore.doc(SettingsCollection(), 'billing'),
    async (docSnapshot) => {
      const document = docSnapshot.data()
      context.billing = { ...document }
    }
  )
  return unsub
}
