<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-card class="mx-auto" max-width="700" min-width="400">
            <v-expansion-panels
              v-model="panel"
              style="max-height: 700px"
              class="overflow-y-auto"
              accordion
            >
              <v-form
                ref="newsUpdateForm"
                v-model="validNewUpdateForm"
                @submit.prevent="() => {}"
                lazy-validation
              >
                <v-expansion-panel v-for="(item, index) in news" :key="index">
                  <v-expansion-panel-header @click="editItem(item)">
                    <v-container>
                      <v-row justify="space-between">
                        <v-col cols="auto">
                          <strong> {{ item.title }} </strong>
                        </v-col>
                        <v-col cols="auto">
                          <span>{{ $d(item.releaseDate) }}</span>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12">
                          {{ item.body.slice(0, 100) }}
                          <v-icon>mdi-menu-down</v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-row justify="space-between">
                        <v-col class="ma-0 pa-2" cols="12">
                          <v-text-field
                            :label="$t('news.title')"
                            v-model="selectedNew.title"
                            :rules="[notEmpty]"
                            :disabled="loading"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col class="ma-0 pa-2" cols="12">
                          <v-text-field
                            :label="$t('news.url')"
                            v-model="selectedNew.url"
                            :disabled="loading"
                            :rules="[
                              /*startByCharacters(['http://', 'https://']),*/
                            ]"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col class="ma-0 pa-2" cols="6">
                          <v-checkbox
                            :label="$t('news.mandatory')"
                            v-model="selectedNew.mandatory"
                            :disabled="loading"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col class="ma-0 pa-2" cols="6">
                          <v-checkbox
                            :label="$t('news.push')"
                            v-model="selectedNew.push"
                            :disabled="loading"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col class="ma-0 pa-2" cols="6">
                          <v-menu
                            dense
                            :ref="'menuReleaseDate_' + item.id"
                            :v-model="'menu_releaseDate_' + item.id"
                            :close-on-content-click="false"
                            :v-model:propName="selectedNew.releaseDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            class="mr-2"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="selectedNew.releaseDate"
                                :label="$t('news.releaseDate')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[notEmpty]"
                                :disabled="loading"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              @click:date="
                                $refs['menuReleaseDate_' + item.id][
                                  $refs['menuReleaseDate_' + item.id].length - 1
                                ].save(selectedNew.releaseDate)
                              "
                              v-model="selectedNew.releaseDate"
                              :locale="$t('javascriptLocale')"
                              no-title
                              scrollable
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col class="ma-0 pa-2" cols="6">
                          <v-menu
                            dense
                            :ref="'menuEndDate_' + item.id"
                            :v-model="'menu_endDate_' + item.id"
                            :close-on-content-click="false"
                            :v-model:propName="selectedNew.endDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            class="mr-2"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="selectedNew.endDate"
                                :label="$t('news.endDate')"
                                prepend-icon="mdi-calendar"
                                readonly
                                clearable
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              @click:date="
                                $refs['menuEndDate_' + item.id][
                                  $refs['menuEndDate_' + item.id].length - 1
                                ].save(selectedNew.endDate)
                              "
                              v-model="selectedNew.endDate"
                              :locale="$t('javascriptLocale')"
                              :min="minEndDate"
                              no-title
                              scrollable
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col class="ma-0 pa-2" cols="6">
                          <v-select
                            v-model="selectedNew.targetUser"
                            :items="userTypes"
                            item-text="text"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            :label="$t('news.targetUser')"
                            multiple
                            :hint="$t('news.targetUserHint')"
                            persistent-hint
                            :rules="[listNotEmpty]"
                            :disabled="loading"
                          >
                          </v-select>
                        </v-col>

                        <v-col class="ma-0 pa-2" cols="6">
                          <v-select
                            v-model="selectedNew.targetProduct"
                            :items="products"
                            item-text="text"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            :label="$t('news.targetProduct')"
                            multiple
                            :hint="$t('news.targetProductHint')"
                            persistent-hint
                            :rules="[listNotEmpty]"
                            :disabled="loading"
                          >
                          </v-select>
                        </v-col>
                        <v-col class="ma-0 pa-2" cols="12">
                          <v-textarea
                            :label="$t('news.body')"
                            v-model="selectedNew.body"
                            :rules="[notEmpty]"
                            :disabled="loading"
                          >
                          </v-textarea>
                        </v-col>
                        <v-col class="ma-0 pa-2" cols="12">
                          <v-btn
                            class="ml-2"
                            @click="updateNew"
                            :disabled="loading"
                          >
                            {{ $t('news.update') }}
                          </v-btn>
                          <v-btn
                            class="ml-2 error"
                            @click="deleteNew"
                            :disabled="loading"
                          >
                            {{ $t('news.delete') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-form>
            </v-expansion-panels>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  updateNew,
  deleteNew,
  getTargetUsers,
  getTargetProducts,
  newsManagerSubscription,
} from '@/services/news-service'
import { mapMutations } from 'vuex'
import rules from '@/utils/rules'

export default {
  async unmounted() {
    if (this.operatorsUnsubscribe) this.operatorsUnsubscribe()
  },
  async mounted() {
    this.newsUnsubscribe = await newsManagerSubscription(this)
  },
  data() {
    return {
      news: [],
      selectedNew: {
        title: '',
        body: '',
        url: '',
        releaseDate: '',
        endDate: '',
        targetUser: [],
        targetProduct: [],
        mandatory: false,
        push: false,
      },
      newsUnsubscribe: () => {},
      userTypes: getTargetUsers(),
      products: getTargetProducts(),
      loading: false,
      validNewUpdateForm: undefined,
      panel: undefined,
    }
  },
  computed: {
    minEndDate() {
      return !this.selectedNew.releaseDate
        ? new Date().toISOString().substring(0, 10)
        : new Date(
            new Date(this.selectedNew.releaseDate).setDate(
              new Date(this.selectedNew.releaseDate).getDate() + 1
            )
          )
            .toISOString()
            .substring(0, 10)
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    editItem(item) {
      this.selectedNew.id = item.id
      this.selectedNew.title = item.title
      this.selectedNew.body = item.body
      this.selectedNew.url = item.url
      this.selectedNew.releaseDate = item.releaseDate
        .toISOString()
        .substring(0, 10)
      this.selectedNew.endDate = item.endDate
        ? item.endDate.toISOString().substring(0, 10)
        : ''
      this.selectedNew.targetUser = item.targetUser
      this.selectedNew.targetProduct = item.targetProduct
      this.selectedNew.mandatory = item.mandatory
      this.selectedNew.push = item.push
    },
    async updateNew() {
      if (!this.$refs.newsUpdateForm?.validate()) return
      try {
        this.loading = true

        await updateNew(this.selectedNew.id, this.selectedNew)
        this.panel = undefined

        const message = this.$t('news.actionsMessages.updateSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        const message = this.$t('news.actionsMessages.updateError')
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
    async deleteNew() {
      try {
        this.loading = true

        await deleteNew(this.selectedNew.id)
        this.panel = undefined

        const message = this.$t('news.actionsMessages.deleteSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch {
        const message = this.$t('news.actionsMessages.deleteError')
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
