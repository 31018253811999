<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="8">
        <UserBalance :user="$store.state.user" />
      </v-col>
      <v-col cols="4">
        <BuyTokens :user="$store.state.user" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UserBalance from '@/components/wholesaler/UserBalance.vue'
import BuyTokens from '@/components/wholesaler/BuyTokens.vue'

export default {
  name: 'BalanceView',
  components: {
    UserBalance,
    BuyTokens,
  },

  data() {
    return {}
  },
}
</script>
